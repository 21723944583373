import { useState } from "react";
import "./style.scss";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Select, message } from "antd";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../api/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { searchPeople, updatePerson } from "../../../api/pipedrive";
import { LoadingOutlined } from "@ant-design/icons";

export default function Onboarding({ callback }) {
    const [user] = useAuthState(auth);
    const [step, setStep] = useState(1);
    const [updating, setUpdating] = useState(false);
    const [formData, setFormData] = useState({
        type: "",
        phoneNumber: "",
        country: "",
        city: "",
        hear: "",
    });

    const showErrorMessage = (text) => {
        message.error({
            content: text,
            className: "error-message",
            duration: 5,
        });
    };

    const handleContinue = () => {
        if (!formData.country) showErrorMessage("Please select your country");
        else if (!formData.city) showErrorMessage("Please select your region");
        else if (!formData.phoneNumber)
            showErrorMessage("Please enter your phone number");
        else setStep(2);
    };

    const handleSubmit = async () => {
        if (!formData.type) showErrorMessage("Please select your account type");
        else if (!formData.hear)
            showErrorMessage("Please select how you hear about us");
        else {
            setUpdating(true);

            try {
                const userDocRef = doc(db, "user_datas", user?.uid);
                const updates = {};

                updates.country = formData.country;
                updates.city = formData.city;
                updates.phoneNumber = formData.phoneNumber;
                updates.type = formData.type;
                updates.hear = formData.hear;

                updateDoc(userDocRef, updates);

                const personId = (await searchPeople(user?.email)).id;
                await updatePerson({
                    id: personId,
                    country: formData.country,
                    city: formData.city,
                    phoneNumber: formData.phoneNumber,
                    type: formData.type,
                    hear: formData.hear,
                });

                callback();
            } catch (error) {
                showErrorMessage(
                    "Something went wrong, please try again later"
                );
            } finally {
                setUpdating(false);
            }
        }
    };

    return (
        <div className="onboarding">
            <div className="onboarding-content">
                <h3 className="title">Get started</h3>

                {step === 2 && (
                    <button className="btn-back" onClick={() => setStep(1)}>
                        <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M9.54922 11.9996L16.8992 19.3496C17.1492 19.5996 17.2701 19.8913 17.2617 20.2246C17.2534 20.558 17.1242 20.8496 16.8742 21.0996C16.6242 21.3496 16.3326 21.4746 15.9992 21.4746C15.6659 21.4746 15.3742 21.3496 15.1242 21.0996L7.42422 13.4246C7.22422 13.2246 7.07422 12.9996 6.97422 12.7496C6.87422 12.4996 6.82422 12.2496 6.82422 11.9996C6.82422 11.7496 6.87422 11.4996 6.97422 11.2496C7.07422 10.9996 7.22422 10.7746 7.42422 10.5746L15.1242 2.87462C15.3742 2.62462 15.6701 2.50379 16.0117 2.51212C16.3534 2.52046 16.6492 2.64962 16.8992 2.89962C17.1492 3.14962 17.2742 3.44129 17.2742 3.77462C17.2742 4.10796 17.1492 4.39962 16.8992 4.64962L9.54922 11.9996Z" />
                        </svg>
                    </button>
                )}

                {step === 1 ? (
                    <>
                        <div className="country-region">
                            <div className="dropdown-box">
                                <p>
                                    Country &nbsp;<span>(Required)</span>
                                </p>
                                <CountryDropdown
                                    value={formData.country}
                                    valueType="short"
                                    onChange={(value) =>
                                        setFormData({
                                            ...formData,
                                            country: value,
                                        })
                                    }
                                    classes="input-textBox"
                                    defaultOptionLabel=""
                                />
                            </div>
                            <div className="dropdown-box">
                                <p>
                                    Region &nbsp;<span>(Required)</span>
                                </p>
                                <RegionDropdown
                                    country={formData.country}
                                    value={formData.city}
                                    countryValueType="short"
                                    onChange={(value) =>
                                        setFormData({
                                            ...formData,
                                            city: value,
                                        })
                                    }
                                    classes="input-textBox"
                                    showDefaultOption={false}
                                />
                            </div>
                        </div>
                        <div className="input-box">
                            <p>
                                Phone Number &nbsp;<span>(Required)</span>
                            </p>
                            <PhoneInput
                                className="phone-input"
                                international
                                defaultCountry={formData.country}
                                value={formData.phoneNumber}
                                onChange={(value) =>
                                    setFormData({
                                        ...formData,
                                        phoneNumber: value,
                                    })
                                }
                                error={
                                    formData.phoneNumber
                                        ? isValidPhoneNumber(
                                              formData.phoneNumber
                                          )
                                            ? undefined
                                            : "Invalid phone number"
                                        : "Phone number required"
                                }
                            />
                        </div>
                        <button className="btn-main" onClick={handleContinue}>
                            Continue
                        </button>
                    </>
                ) : (
                    <>
                        <div className="input-box">
                            <p>
                                Account Type &nbsp;<span>(Required)</span>
                            </p>
                            <Select
                                className="input-textBox"
                                value={formData.type}
                                onChange={(value) =>
                                    setFormData({ ...formData, type: value })
                                }
                                options={[
                                    {
                                        value: "Dentist",
                                        label: "Dentist",
                                    },
                                    {
                                        value: "Dental Technician",
                                        label: "Dental Technician",
                                    },
                                    {
                                        value: "Patient",
                                        label: "Patient",
                                    },
                                    {
                                        value: "Work in dental industry",
                                        label: "Work in dental industry",
                                    },
                                ]}
                                autoFocus
                            />
                        </div>
                        <div className="input-box">
                            <p>
                                How did you hear about us? &nbsp;
                                <span>(Required)</span>
                            </p>
                            <Select
                                className="input-textBox"
                                value={formData.hear}
                                onChange={(value) =>
                                    setFormData({ ...formData, hear: value })
                                }
                                options={[
                                    { value: "Instagram", label: "Instagram" },
                                    { value: "LinkedIn", label: "LinkedIn" },
                                    { value: "Facebook", label: "Facebook" },
                                    {
                                        value: "Google Search",
                                        label: "Google Search",
                                    },
                                    { value: "App Store", label: "App Store" },
                                    {
                                        value: "Google Play",
                                        label: "Google Play",
                                    },
                                    {
                                        value: "Conference",
                                        label: "Conference",
                                    },
                                    { value: "Referral", label: "Referral" },
                                    {
                                        value: "Phone Call",
                                        label: "Phone Call",
                                    },
                                ]}
                                autoFocus
                            />
                        </div>
                        <button className="btn-main" onClick={handleSubmit}>
                            {updating ? <LoadingOutlined /> : "Get Started"}
                        </button>
                    </>
                )}

                <div className="step">
                    <div
                        className="progress-bar"
                        style={{ transform: `translateX(${step - 1}00%)` }}
                    />
                </div>
            </div>
        </div>
    );
}
