import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth, registerWithEmailAndPassword, signUpWithGoogle } from "../../../api/firebase";
import "./style.scss";
import { Checkbox, Input, Modal, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "react-phone-number-input/style.css";
import { formatISO } from "date-fns";
import PrivacyPage from "../../../pages/PrivacyPage";
import TermsPage from "../../../pages/TermsPage";

export default function Register() {
    const [user, loading, error] = useAuthState(auth);
    const [formPage, setFormPage] = useState(1);
    const [formData, setFormData] = useState({
        agree: false,
        accountMail: true,
        marketingMail: false,
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        type: "",
        phoneNumber: "",
        country: "",
        city: "",
        hear: "",
        agreeTermsDate: "",
    });
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [lengthcheck, setLengthcheck] = useState(true);
    const [registering, setRegistering] = useState(false);
    const [isRegisted, setIsRegisted] = useState(false);
    const [handlePrivacy, setHandlePrivacy] = useState(false);
    const [handleTerms, setHandleTerms] = useState(false);
    const navigate = useNavigate();
    const registerDate = formatISO(new Date());

    const showErrorMessage = (text) => {
        message.error({
            content: text,
            className: "error-message",
            duration: 5,
        });
    };

    async function register(e) {
        if (formData.agree) {
            if (!formData.firstName || !formData.lastName) showErrorMessage("Please enter your name.");
            else if (!formData.email) showErrorMessage("Please enter your email address.");
            else if (!isEmailValid) showErrorMessage("Please enter a valid email address.");
            else if (!formData.password) showErrorMessage("Please enter your password.");
            else {
                setRegistering(true);
                const registerStatus = await registerWithEmailAndPassword({
                    e,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    password: formData.password,
                    registerDate,
                    marketingMail: formData.marketingMail,
                    agreeTermsDate: formData.agreeTermsDate,
                });
                if (registerStatus.message !== "Account created successfully.") {
                    showErrorMessage(registerStatus.message);
                    setRegistering(false);
                } else {
                    setIsRegisted(true);
                    setRegistering(false);
                }
            }
        } else if (!formData.agree) {
            showErrorMessage("To continue, please read and agree to our “Privacy Policy and Terms of Use”.");
            setRegistering(false);
        }
    }

    useEffect(() => {
        if (loading) return;
        if (user && user?.emailVerified) navigate("/dashboard");
        if (user && !user?.emailVerified && isRegisted) navigate("/emailVerification");
        if (error) navigate("/");
    }, [user, loading, error, navigate, isRegisted]);

    useEffect(() => {
        if (formData.password.length < 6) setLengthcheck(false);
        else setLengthcheck(true);
    }, [formData.password]);

    const checkEmail = (email) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return re.test(email);
    };

    const handleContinue = () => {
        if (!formData.firstName || !formData.lastName) showErrorMessage("Please enter your name.");
        else if (!formData.email) showErrorMessage("Please enter your e-mail address.");
        else if (!isEmailValid) showErrorMessage("Please enter a valid e-mail address.");
        else if (!formData.password || !formData.confirmPassword) showErrorMessage("Please enter your password.");
        else if (formData.password !== formData.confirmPassword) showErrorMessage("Passwords do not match.");
        else if (!lengthcheck) showErrorMessage("Password should be at least 6 characters.");
        else setFormPage(2);
    };

    const FormStep = () => (
        <div className="form-step">
            <button className="btn-step" onClick={() => setFormPage(1)}>
                1
            </button>
            <div className="step-line"></div>
            <button
                className="btn-step"
                onClick={handleContinue}
                style={{ backgroundColor: formPage !== 2 && "#686868" }}
            >
                2
            </button>
        </div>
    );

    return (
        <div className="register">
            <Link to="/">
                <img
                    src={require("../../../assets/img/logo/logo_dentscape.webp")}
                    alt="Dentscape"
                    className="login-logo"
                />
            </Link>

            <div className="register-container">
                <h3>Sign up</h3>

                <button className="btn-google" onClick={() => signUpWithGoogle()}>
                    <img
                        src={require("../../../assets/img/dentist/icon_google_account.webp")}
                        alt="google"
                        className="icon-google"
                    />
                    Continue with Google
                </button>

                <div className="divider">
                    <div className="divider-line" />
                    <div className="divider-text">or</div>
                    <div className="divider-line" />
                </div>

                <div className="name-container">
                    <div className="name-box">
                        <p>First name</p>
                        <Input
                            id="firstName"
                            type="text"
                            className="input-textBox"
                            value={formData.firstName}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    firstName: e.target.value,
                                })
                            }
                            placeholder="(Required)"
                        />
                    </div>
                    <div className="name-box">
                        <p>Last name</p>
                        <Input
                            id="lastName"
                            type="text"
                            className="input-textBox"
                            value={formData.lastName}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    lastName: e.target.value,
                                })
                            }
                            placeholder="(Required)"
                        />
                    </div>
                </div>

                <div className="input-box">
                    <p>Email</p>
                    <Input
                        id="email"
                        type="email"
                        className="input-textBox"
                        value={formData.email}
                        onChange={(e) => {
                            const email = e.target.value;
                            setFormData({ ...formData, email: email });
                            setIsEmailValid(checkEmail(email));
                        }}
                        placeholder="(Required)"
                    />
                </div>

                <div className="input-box">
                    <p>Password</p>
                    <Input.Password
                        id="password"
                        className="input-textBox"
                        value={formData.password}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                password: e.target.value,
                            })
                        }
                        placeholder="(Required)"
                        iconRender={(visible) =>
                            visible ? (
                                <img src={require("../../../assets/img/icon_show.png")} />
                            ) : (
                                <img src={require("../../../assets/img/icon_hide.png")} />
                            )
                        }
                    />
                </div>

                <div className="checkbox-container">
                    <Checkbox
                        id="agree"
                        checked={formData.agree}
                        onChange={(e) => {
                            const agreeTermsDate = formatISO(new Date());
                            setFormData({
                                ...formData,
                                agree: e.target.checked,
                                agreeTermsDate: e.target.checked ? agreeTermsDate : "",
                            });
                        }}
                    >
                        <p>
                            I agree to{" "}
                            <button className="privacy-btn" onClick={() => setHandlePrivacy(true)}>
                                Privacy Policy
                            </button>{" "}
                            and{" "}
                            <button className="privacy-btn" onClick={() => setHandleTerms(true)}>
                                Terms of Use
                            </button>
                        </p>
                    </Checkbox>

                    <Checkbox
                        checked={formData.marketingMail}
                        id="mailing"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                marketingMail: e.target.checked,
                            })
                        }
                    >
                        <p>Get the latest news and tips about using Preteeth AI.</p>
                    </Checkbox>
                </div>

                <button className="btn-main" onClick={register}>
                    Sign up
                </button>
            </div>

            <p className="login-text">
                Already have an account ? &nbsp; &nbsp;
                <Link to="/login" className="login-link">
                    Sign in
                </Link>
            </p>

            <Modal className="registering-modal" open={registering} footer={null} closable={false} centered>
                <Spin indicator={<LoadingOutlined style={{ color: "#191919" }} spin />} />
                <p className="registering-text">Registering</p>
                <p>Please do not close the window.</p>
            </Modal>

            <Modal
                className="privacy-modal"
                width={"fit-content"}
                open={handlePrivacy || handleTerms}
                footer={null}
                onCancel={() => {
                    setHandlePrivacy(false);
                    setHandleTerms(false);
                }}
                centered
            >
                {handlePrivacy ? <PrivacyPage /> : <TermsPage />}
            </Modal>
        </div>
    );
}
