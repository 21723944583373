import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Delete.scss";
import "./AccountDeleted.css";

export default function AccountDeleted() {
    const navigate = useNavigate();
    const [seconds, setSeconds] = useState(10);

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        } else navigate("/");
    });

    return (
        <div className="delete" style={{ justifyContent: "center" }}>
            <Link to="/">
                <img
                    src={require("../../../assets/img/logo/logo_dentscape.webp")}
                    alt="Dentscape"
                    className="login-logo"
                />
            </Link>
            <div className="delete-container" style={{ height: "45vh" }}>
                <h4 className="delete-title" style={{ fontSize: "1.4rem", color: "#83E4FC" }}>
                    Successfully deleted
                </h4>
                <h4 className="userInfo">If you need help in the future, we will always be here for your service.</h4>
                <p style={{ color: "#c4c4c4", marginTop: "25px", marginBottom: "0" }}>{seconds}</p>
                <Link className="btn-back" to="/">
                    Back to home page
                </Link>
            </div>
        </div>
    );
}
