import React, { lazy, Suspense, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import "./App.css";
import HomePage from "./pages/Dentist/HomePage";
import AccountPage from "./pages/Dentist/AccountPage";
import Patient from "./pages/Patient/PatientPage";
import GlobalContext from "./store";
import BlogPost from "./components/Dentist/Blog/BlogPost";
import packageJson from "../package.json";
import Payment from "./pages/Dentist/PaymentPage";
import APIPage from "./pages/Dentist/APIPage";
import DeleteAccountInstructions from "./components/Account/Delete/Instructions";
import PricePage from "./pages/Dentist/PricePage";

const PreteethAIProPage = lazy(() =>
    import(/* webpackChunkName: "PreteethAIProPage" */ "./pages/Dentist/PreteethAIProPage")
);
const BlogPage = lazy(() => import(/* webpackChunkName: "blogPage" */ "./pages/Dentist/BlogPage"));
const TutorialPage = lazy(() => import(/* webpackChunkName: "tutorialPage" */ "./pages/Dentist/TutorialPage"));
const ContactPage = lazy(() => import(/* webpackChunkName: "contactPage" */ "./pages/Dentist/ContactPage"));
const ContactPipedrivePage = lazy(() =>
    import(/* webpackChunkName: "contactPipedrivePage" */ "./pages/Dentist/ContactPipedrivePage")
);
const BookADemoPage = lazy(() => import(/* webpackChunkName: "BookADemoPage" */ "./pages/Dentist/BookADemoPage"));
const TryForFreePage = lazy(() => import(/* webpackChunkName: "TryForFreePage" */ "./pages/Dentist/TryForFreePage"));
const ActionLinkPage = lazy(() => import(/* webpackChunkName: "actionLinkPage" */ "./pages/Dentist/ActionLinkPage"));
const VerifiedPage = lazy(() =>
    import(/* webpackChunkName: "verifiedPage" */ "./components/Account/EmailVerification/VerifiedPage")
);
const ResetPassword = lazy(() =>
    import(/* webpackChunkName: "resetPassword" */ "./components/Account/Password/ResetPassword")
);
const DownloadDesktop = lazy(() => import(/* webpackChunkName: "downloadDesktop" */ "./pages/Dentist/DownloadDesktop"));
const OpenApp = lazy(() => import(/* webpackChunkName: "openApp" */ "./pages/Dentist/OpenApp"));
const PrivacyPage = lazy(() => import(/* webpackChunkName: "privacyPage" */ "./pages/PrivacyPage"));
const TermsPage = lazy(() => import(/* webpackChunkName: "termsPage" */ "./pages/TermsPage"));
const Unsubscribe = lazy(() =>
    import(/* webpackChunkName: "unsubscribe" */ "./components/Account/Unsubscribe/Unsubscribe")
);

function App() {
    const [loading, setLoading] = useState(true);
    const spinner = document.getElementById("spinner");

    useEffect(() => {
        const timer = setTimeout(() => {
            if (spinner) {
                spinner.style.display = "none";
                setLoading(false);
            }
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    let didInit = false;
    useEffect(() => {
        const caching = () => {
            let version = localStorage.getItem("version");
            if (version !== packageJson.version || version === null) {
                if ("caches" in window) {
                    caches.keys().then((names) => {
                        // Delete all the cache files
                        names.forEach((name) => {
                            caches.delete(name);
                        });
                    });
                }

                // Makes sure the page reloads. Changes are only visible after you refresh.
                window.location.reload(true);
                localStorage.clear();
                localStorage.setItem("version", packageJson.version);
            }
        };

        if (!didInit) {
            didInit = true;
            caching();
        }
    }, []);

    const isAccountRelated = () => {
        const { pathname } = window.location;
        return (
            pathname === "/register" ||
            pathname === "/login" ||
            pathname === "/sendreset" ||
            pathname === "/emailVerification" ||
            pathname === "/changePassword" ||
            pathname === "/delete" ||
            pathname === "/accountDeleted" ||
            pathname === "/openapp" ||
            pathname === "/actionLink" ||
            pathname === "/download-preteeth-ai-pro-desktop" ||
            pathname === "/userSurvey" ||
            pathname === "/privacy" ||
            pathname === "/termsofuse" ||
            pathname === "/privacy-smileCare" ||
            pathname === "/termsofuse-smileCare"
        );
    };

    return (
        <GlobalContext>
            <Router>
                <Suspense
                    fallback={
                        <h3
                            style={{
                                color: "#00C8F0",
                                margin: "50px 0",
                                textAlign: "center",
                            }}
                        >
                            Loading...
                        </h3>
                    }
                >
                    <Routes>
                        <Route path="/" element={!loading && <HomePage />} />
                        <Route path="/SmileCare" element={!loading && <Patient />} />
                        <Route path="/PreteethAIPro" element={!loading && <PreteethAIProPage />} />
                        <Route path="/API" element={!loading && <APIPage />} />
                        <Route path="/PreteethAIPro/payment" element={!loading && <Payment />} />
                        <Route
                            path="/PreteethAIPro/payment/price"
                            element={!loading && <PricePage product="PreteethAIPro" />}
                        />
                        <Route path="/blog" element={!loading && <BlogPage />} />
                        <Route path="/blog/:postId" element={!loading && <BlogPost type="blog" />} />
                        <Route path="/tutorial" element={!loading && <TutorialPage />} />
                        <Route path="/tutorialPost/:postId" element={!loading && <BlogPost type="tutorial" />} />
                        <Route path="/contact" element={!loading && <ContactPage />} />
                        <Route path="/contactPipedrive" element={!loading && <ContactPipedrivePage />} />
                        <Route path="/bookADemo" element={!loading && <BookADemoPage />} />
                        <Route path="/tryForFree" element={!loading && <TryForFreePage />} />
                        <Route path="/dashboard" element={<AccountPage state="dashboard" />} />
                        <Route path="/openApp" element={<OpenApp />} />
                        <Route path="/delete" element={<AccountPage state="delete" />} />
                        <Route path="/accountDeleted" element={<AccountPage state="accountDeleted" />} />
                        <Route path="/login" element={<AccountPage state="login" />} />
                        <Route path="/register" element={<AccountPage state="register" />} />
                        <Route path="/sendreset" element={<AccountPage state="sendReset" />} />
                        <Route path="/emailVerification" element={<AccountPage state="emailVerification" />} />
                        <Route path="/changePassword" element={<AccountPage state="changePassword" />} />
                        <Route path="/actionLink" element={<ActionLinkPage />} />
                        <Route path="/emailVerification/verified" element={<VerifiedPage />} />
                        <Route path="/resetPassword" element={<ResetPassword />} />
                        <Route path="/download-preteeth-ai-pro-desktop" element={<DownloadDesktop />} />
                        <Route path="/privacy" element={<PrivacyPage />} />
                        <Route path="/termsofuse" element={<TermsPage />} />
                        <Route path="/delete-account-instructions" element={<DeleteAccountInstructions />} />
                        <Route path="/privacy-smileCare" element={<PrivacyPage type={"SmileCare"} />} />
                        <Route path="/termsofuse-smileCare" element={<TermsPage type={"SmileCare"} />} />
                        <Route path="/PreteethAIPro/Unsubscribe" element={<Unsubscribe product="PreteethAIPro" />} />

                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </Suspense>

                {!isAccountRelated() && (
                    <CookieConsent
                        buttonStyle={{
                            padding: "5px 15px",
                            borderRadius: "20px",
                            background: "#83E4FC",
                            color: "#333",
                        }}
                        declineButtonStyle={{
                            padding: "5px 15px",
                            borderRadius: "20px",
                            background: "#C4C4C4",
                            color: "#333",
                        }}
                        enableDeclineButton
                    >
                        This website uses cookies to ensure you get the best experience on our website.
                    </CookieConsent>
                )}
            </Router>
        </GlobalContext>
    );
}

export default App;
