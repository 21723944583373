import "./PriceBox.scss";

export default function PriceBox({ plan, price, activeProduct, choosedPlan, setChoosedPlan }) {
    const handleChoosedPlan = (plan) => {
        setChoosedPlan(plan);
    };

    return (
        <div
            className="price-box"
            style={{
                borderColor: choosedPlan !== plan && "#686868",
                filter: choosedPlan !== plan && "opacity(0.5)",
            }}
            onClick={() => {
                if (activeProduct && activeProduct !== plan) return;
                handleChoosedPlan(plan);
            }}
        >
            {activeProduct && activeProduct !== plan && (
                <div className="disable-text">
                    <p>You can switch to other plan only after your current plan expires.</p>
                </div>
            )}

            {plan === "annual" && (
                <div className="annual-discount">
                    <p>
                        10 months <span>+ 2 months free</span>
                    </p>
                </div>
            )}

            <h3 className="plan-title">{plan.charAt(0).toUpperCase() + plan.slice(1)}</h3>

            <div className="price-content">
                <p>Billed every {plan === "monthly" ? "30" : plan === "annual" && "365"} days</p>
                <h3 className="price">${`${price}`.replace("$", "")}</h3>
            </div>
        </div>
    );
}
