import "./Steps.scss";

export default function Steps() {
    return (
        <div className="whole-step-container">
            <div className="title-container">
                <p className="text-title">
                    Simplify Dental Design <br /> with Dentscape AI
                </p>
            </div>
            <div className="detailed-steps-container">
                <div
                    className="step-box"
                    data-aos="fade-up"
                    data-aos-duration="750"
                >
                    <img
                        src={require("../../../../assets/img/dentist/home/step_upload_icon.webp")}
                        alt="step1"
                        className="step-image"
                    />
                    <p className="text-step-index">Step 1</p>
                    <p className="text-step-name">Upload Scan Files</p>
                    <p className="text-step-description">
                        Batch Upload for Peak Efficiency: Streamline Your
                        Workflow with Multiple Scan Uploads
                    </p>
                </div>
                <div
                    className="step-box"
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="150"
                >
                    <img
                        src={require("../../../../assets/img/dentist/home/step_auto_icon.webp")}
                        alt="step2"
                        className="step-image"
                    />
                    <p className="text-step-index">Step 2</p>
                    <p className="text-step-name">Automated Design</p>
                    <p className="text-step-description">
                        Precision with AI: Your Automated CAD Designer for
                        High-Quality Dental Restorations
                    </p>
                </div>
                <div
                    className="step-box"
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="300"
                >
                    <img
                        src={require("../../../../assets/img/dentist/home/step_check_icon.webp")}
                        alt="step3"
                        className="step-image"
                    />
                    <p className="text-step-index">Step 3</p>
                    <p className="text-step-name">Check and Export</p>
                    <p className="text-step-description">
                        Finalize with Confidence: Review, Adjust, and Export
                        with Total Control
                    </p>
                </div>
            </div>
        </div>
    );
}
