import useMeasure from "react-use-measure";
import "./Testimonial.scss";
import { animate, motion, useMotionValue } from "framer-motion";
import { useEffect, useState } from "react";

export default function Testimonial() {
    const [windowSize, setWindowSize] = useState("large");

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1024) {
                setWindowSize("large");
            } else if (window.innerWidth > 768) {
                setWindowSize("medium");
            } else {
                setWindowSize("small");
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    let [ref, { width }] = useMeasure();
    const xTranslation = useMotionValue(0);

    useEffect(() => {
        let controls;
        let finalPosition =
            windowSize === "large"
                ? -width / 3 - 150
                : windowSize === "medium"
                ? -width - 500
                : -width / 3 - 15;

        controls = animate(xTranslation, [0, finalPosition], {
            ease: "linear",
            duration: windowSize === "small" ? 50 : 25,
            repeat: Infinity,
            repeatType: "loop",
            repeatDelay: 0,
        });

        return () => {
            if (controls) {
                controls.stop();
            }
        };
    }, [xTranslation, width]);

    const testimonials = [
        {
            name: "Alex, CDT",
            content:
                "Dentscape AI changed everything in our lab. What used to take hours, we now do in minutes. It's like gaining an extra team member overnight!",
        },
        {
            name: "Samantha, CDT",
            content:
                "Since we started using Dentscape AI, we've tripled our output without missing a beat on quality. It's a game-changer for busy days!",
        },
        {
            name: "Dr. Emily, DDS",
            content:
                "Dentscape AI has changed the way we handle restorations. Adjusting and finalizing is so much easier now, which means better outcomes every time.",
        },
        {
            name: "John, CDT",
            content:
                "Thanks to Dentscape AI's precision, we're spending less time on fixes and more on smiles. Our clients love the quality boost!",
        },
        {
            name: "Michelle, CDT",
            content:
                "Dentscape AI has cut our design time by 90%. It's not just fast—it makes sure everything fits right the first go.",
        },
        {
            name: "Dr. Richard, DDS",
            content:
                "With Dentscape AI, we do restorations fast and flawless. Our patients are happier and so are we!",
        },
    ];

    return (
        <div className="testimonial">
            <h1 className="title">
                Professionals are already feeling the evolution
            </h1>

            <motion.div
                className="testimonial-card-container"
                ref={ref}
                style={{ x: xTranslation }}
            >
                {[...testimonials, ...testimonials, ...testimonials].map(
                    (testimonial, index) => (
                        <div
                            key={index}
                            className={`testimonial-card ${
                                index % 3 === 0
                                    ? "row-start-center"
                                    : index % 3 === 1
                                    ? "row-start-top"
                                    : "row-start-bottom"
                            }`}
                            style={{
                                gridColumn: `${
                                    Math.ceil((index + 1) / 3) * 2 -
                                    (index % 3 === 0 ? 1 : 0)
                                }`,
                            }}
                        >
                            <h2 className="testimonial-card-name">
                                {testimonial.name}
                            </h2>
                            <h4 className="testimonial-card-content">
                                {testimonial.content}
                            </h4>
                        </div>
                    )
                )}
            </motion.div>
        </div>
    );
}
