import "./Dialog.css";

function BotDialog({ children }) {
  return (
    <div className="bot-dialog-container">
      <img
        className="bot-avatar"
        src={require("../../../../assets/img/2C/icon_smileCareAvatar.webp")}
        alt="SmileCare"
      />
      <div className="dialog bot-dialog">{children}</div>
    </div>
  );
}

function UserDialog({ children }) {
  return <div className="dialog user-dialog">{children}</div>;
}

export { BotDialog, UserDialog };
