import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    applyActionCode,
    confirmPasswordReset,
    updatePassword,
    EmailAuthProvider,
    reauthenticateWithCredential,
    GoogleAuthProvider,
    signInWithPopup,
    fetchSignInMethodsForEmail,
} from "firebase/auth";
import { getFirestore, doc, collection, deleteDoc, setDoc, getDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { child, get, getDatabase, ref } from "firebase/database";
import { createStripeCustomer, deleteStripeCustomer } from "./payment";
import { addPerson, deletePerson, getPersonDetails, searchPeople, updatePerson } from "./pipedrive";
import { formatISO } from "date-fns";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "dentscape.ai",
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const realtimeDB = getDatabase(app);

// Signin function with email/password
export async function logInWithEmailAndPassword(email, password, showErrorMessage) {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err.message);
        if (err.message === "Firebase: Error (auth/wrong-password).") {
            // check if user is google account
            const signInMethods = await fetchSignInMethodsForEmail(auth, email);
            if (signInMethods.includes("google.com")) {
                showErrorMessage("This email account is registered with Google. Please sign in with Google.");
            }
        } else showErrorMessage(err.message);
    }
}

// check if user is free trial account
export async function checkFreeTrial(email) {
    const dbRef = ref(realtimeDB);
    const isFreeTrial = await get(child(dbRef, `website/free_trial_account`)).then((snapshot) => {
        // snapshot.val() is an array, check if user email is in the array
        if (snapshot.val().includes(email)) {
            return true;
        } else {
            return false;
        }
    });

    return isFreeTrial;
}

export async function signUpWithGoogle() {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
        prompt: "select_account",
        display: "popup",
    });

    try {
        await signInWithPopup(auth, provider).then(async (result) => {
            const { region, country } = await setRegion();
            const isNewUser = result.user.metadata.creationTime === result.user.metadata.lastSignInTime;

            if (isNewUser) {
                const firstName = result._tokenResponse.firstName;
                const lastName = result._tokenResponse.lastName;
                const name = `${firstName} ${lastName}`;
                const email = result.user.email;
                const registerDate = formatISO(new Date(result.user.metadata.creationTime));
                window.dataLayer.push({
                    event: "sign_up_start",
                    user_name: name,
                    user_email: email,
                    user_registerDate: registerDate,
                    user_region: region,
                });

                // Create user in Firebase database
                await setDoc(doc(collection(db, "user_datas"), result.user.uid), {
                    uid: result.user.uid,
                    firstName,
                    lastName,
                    email,
                    region,
                    registerDate,
                    agreeTermsDate: registerDate,
                    pricePlan: "$19",
                    signUpType: "google",
                });

                await setDoc(doc(db, "user_profiles_v2", `${result.user.uid}`), {
                    email,
                    region,
                });

                window.dataLayer.push({
                    event: "sign_up_set_firestore_success",
                    email: email,
                });

                // Create user in Pipedrive
                const { totalItems, id } = await searchPeople(email);
                if (totalItems >= 1) {
                    await updatePerson({
                        id,
                        firstName,
                        lastName,
                        status: "Verified",
                        email,
                        registerDate,
                        uid: result.user.uid,
                        pricePlan: "$19",
                        signUpType: "google",
                    });
                } else {
                    await addPerson({
                        email,
                        status: "Verified",
                        firstName,
                        lastName,
                        registerDate,
                        uid: result.user.uid,
                        pricePlan: "$19",
                        signUpType: "google",
                        registrationSource: 68, // 68 = Website
                    });
                }
                window.dataLayer.push({
                    event: "sign_up_set_pipedrive_success",
                    email: email,
                });

                // Create user in Stripe
                await createStripeCustomer(email, result.user.uid, name);

                window.dataLayer.push({
                    event: "sign_up_set_stripe_success",
                    email: email,
                });
            }

            return { message: "Account created successfully." };
        });
    } catch (err) {
        console.error(err.message);

        const user = auth.currentUser;
        if (user) {
            let message;
            if (err.message === "Firebase: Error (auth/email-already-in-use).")
                message = "This email account has already been used.";
            else message = "Failed to create account. Please try again later or contact us.";

            window.dataLayer.push({
                event: "sign_up_error",
                email: user.email,
                error: err.message !== undefined ? err.message : err,
            });

            return { message: message };
        } else if (err.message === "Firebase: Error (auth/popup-blocked).") {
            return {
                message: "Popup blocked. Please enable popup for this website and try again or use another browser.",
            };
        }
    }
}

export async function setRegion() {
    try {
        const response = await fetch("https://ipapi.co/json/");
        if (!response.ok) {
            throw new Error("Failed to fetch region data.");
        }
        const data = await response.json();
        let region;

        if (["NA", "SA", "AN"].includes(data.continent_code)) {
            region = "us";
        } else if (["EU", "AF"].includes(data.continent_code)) {
            region = "eu";
        } else {
            //continent_code == "AS" or "OC"
            region = "asia";
        }

        return { region, country: data.country };
    } catch (error) {
        console.error(error);
        return { region: "", country: "" };
    }
}

// Register function with email/password
export async function registerWithEmailAndPassword({
    firstName,
    lastName,
    email,
    password,
    registerDate,
    marketingMail,
    agreeTermsDate,
}) {
    let user, region, country;

    // Steps:
    // 1. Create user in Firebase auth
    // 2. Create user in Firebase database
    // 3. Create user in Pipedrive
    // 4. Create user in Stripe
    // 5. Send verification email
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        user = res.user;
        const name = `${firstName} ${lastName}`;
        region = (await setRegion()).region;
        country = (await setRegion()).country;

        window.dataLayer.push({
            event: "sign_up_start",
            user_name: name,
            user_email: email,
            user_registerDate: registerDate,
            user_region: region,
        });

        // Create user in Firebase database
        await setDoc(doc(collection(db, "user_datas"), user.uid), {
            uid: user.uid,
            firstName,
            lastName,
            email,
            region,
            registerDate,
            agreeTermsDate,
            pricePlan: "$19",
            signUpType: "email",
        });

        await setDoc(doc(db, "user_profiles_v2", `${user.uid}`), {
            email,
            region,
        });

        window.dataLayer.push({
            event: "sign_up_set_firestore_success",
            email: email,
        });

        // Create user in Pipedrive
        const { totalItems, id } = await searchPeople(email);
        if (totalItems >= 1) {
            await updatePerson({
                id,
                firstName,
                lastName,
                status: "Unverified",
                email,
                registerDate,
                uid: user.uid,
                pricePlan: "$19",
                signUpType: "email",
            });
        } else {
            await addPerson({
                email,
                status: "Unverified",
                firstName,
                lastName,
                registerDate,
                uid: user.uid,
                marketingMail,
                pricePlan: "$19",
                signUpType: "email",
                registrationSource: 68, // 68 = Website
            });
        }
        window.dataLayer.push({
            event: "sign_up_set_pipedrive_success",
            email: email,
        });

        // Create user in Stripe
        await createStripeCustomer(email, user.uid, name);
        window.dataLayer.push({
            event: "sign_up_set_stripe_success",
            email: email,
        });

        // Send verification email
        const [sendEmailStatus, actionLink] = await sendVerificationEmail(auth.currentUser.email, name);
        if (sendEmailStatus !== "Email successfully sent") {
            window.dataLayer.push({
                event: "sign_up_send_email_error",
                email: email,
            });
            throw new Error("Failed to send verification email.");
        } else {
            const newId = (await searchPeople(email)).id;
            await updatePerson({
                id: id !== null ? id : newId,
                verifyLink: actionLink,
            });
            window.dataLayer.push({
                event: "sign_up_send_email_success",
                email: email,
            });
        }

        return { message: "Account created successfully." };
    } catch (err) {
        console.error(err.message);
        await rollbackUserDatabase(user, region);

        let message;
        if (err.message === "Firebase: Error (auth/email-already-in-use).")
            message = "This email account has already been used.";
        else if (err.message === "Firebase: Error (auth/invalid-email).") message = "Invalid email address.";
        else if (err.message === "Firebase: Password should be at least 6 characters (auth/weak-password).")
            message = "Password should be at least 6 characters.";
        else message = "Failed to create account. Please try again later or contact us.";

        window.dataLayer.push({
            event: "sign_up_error",
            email: email,
            error: err.message !== undefined ? err.message : err,
        });

        return { message: message };
    }
}

export async function tryForFreeRegister(formData) {
    const {
        firstName,
        lastName,
        phoneNumber,
        email,
        password,
        companyName,
        companySize,
        jobTitle,
        interestedProduct,
        agreeTermsDate,
        marketingMail,
    } = formData;
    const registerDate = formatISO(new Date());
    const name = `${firstName} ${lastName}`;
    const region = (await setRegion()).region;
    let user;

    window.dataLayer.push({
        event: "sign_up_start",
        user_name: name,
        user_email: email,
        user_registerDate: registerDate,
        user_region: region,
    });

    const { id } = await searchPeople(email);

    try {
        const interestedProductLabelId =
            interestedProduct === "Dentscape"
                ? 98
                : interestedProduct === "Preteeth AI Pro"
                ? 97
                : interestedProduct === "API service"
                ? 96
                : null;

        // Check if email is already used
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        if (signInMethods.length > 0) {
            // User exists, check registration source in Pipedrive
            const personDetails = await getPersonDetails(id);
            const registrationSource = personDetails?.["72c6bfb26045deebf334a14bf585cf27769e3f54"];
            const createdDate = new Date(personDetails?.add_time.split(" ")[0]);
            const existingPersonLabelIds = personDetails?.label_ids;

            // "68" = Website, "69" = App, "70" = AI dental CAD
            if (registrationSource === "70" || (registrationSource === "68" && createdDate >= new Date("2024-08-27"))) {
                await updatePerson({
                    id,
                    labelIds: [...existingPersonLabelIds, interestedProductLabelId],
                });

                return {
                    success: false,
                    message: "Already registered with AI dental CAD.",
                };
            } else {
                const uid = personDetails?.["d808f0e9b1f39a02a34c2e1a1c5e2a4ba51d2ea9"];
                if (!uid) {
                    throw new Error("Failed to get user ID.");
                }

                await setDoc(
                    doc(db, "user_datas", uid),
                    {
                        companyName,
                        companySize,
                        jobTitle,
                    },
                    { merge: true }
                );

                await setDoc(doc(db, "dental_cad_user_datas", uid), {
                    uid,
                    firstName,
                    lastName,
                    phoneNumber,
                    email,
                    companyName,
                    companySize,
                    jobTitle,
                    agreeTermsDate,
                    signUpType: "email",
                });

                await updatePerson({
                    id,
                    labelIds: [...existingPersonLabelIds, interestedProductLabelId],
                    companyName,
                    companySize,
                    jobTitle,
                });

                return {
                    success: false,
                    message: "Already registered with Preteeth AI.",
                };
            }
        } else {
            // Proceed with new registration
            const res = await createUserWithEmailAndPassword(auth, email, password);
            user = res.user;

            // Create user in Firebase database
            await setDoc(doc(collection(db, "dental_cad_user_datas"), user.uid), {
                uid: user.uid,
                firstName,
                lastName,
                phoneNumber,
                email,
                password,
                companyName,
                companySize,
                jobTitle,
                registerDate,
                agreeTermsDate,
                signUpType: "email",
            });

            await setDoc(doc(db, "user_datas", `${user.uid}`), {
                uid: user.uid,
                firstName,
                lastName,
                phoneNumber,
                email,
                password,
                companyName,
                companySize,
                jobTitle,
                registerDate,
                agreeTermsDate,
                signUpType: "email",
            });

            // Create user in Pipedrive
            await addPerson({
                uid: user.uid,
                labelIds: [interestedProductLabelId],
                status: "Unverified",
                firstName,
                lastName,
                email,
                phoneNumber,
                companyName,
                companySize,
                jobTitle,
                registerDate,
                marketingMail,
                signUpType: "email",
                registrationSource: 68, // 68 = Website
            });

            // Send verification email
            const interestedProductStr = interestedProduct.replace(/\s+/g, "");
            const [sendEmailStatus, actionLink] = await sendVerificationEmail(
                auth.currentUser.email,
                name,
                interestedProductStr
            );
            if (sendEmailStatus !== "Email successfully sent") {
                throw new Error("Failed to send verification email.");
            } else {
                const newId = (await searchPeople(email)).id;
                await updatePerson({
                    id: id !== null ? id : newId,
                    verifyLink: actionLink,
                });
            }

            return {
                success: true,
                message: "Account created successfully.",
            };
        }
    } catch (err) {
        console.error(err.message);
        await deleteDoc(doc(collection(db, "user_datas"), user.uid));
        await deleteDoc(doc(db, "user_profiles_v2", `${user.uid}`));

        const { totalItems, id } = await searchPeople(user.email);
        if (totalItems >= 1) {
            await deletePerson(id);
        }

        if (user) {
            await user.delete();
        }

        let message;
        if (err.message === "Firebase: Error (auth/email-already-in-use).")
            message = "This email account has already been used.";
        else if (err.message === "Firebase: Error (auth/invalid-email).") message = "Invalid email address.";
        else if (err.message === "Firebase: Password should be at least 6 characters (auth/weak-password).")
            message = "Password should be at least 6 characters.";
        else message = "Failed to create account. Please try another email address or contact us.";

        window.dataLayer.push({
            event: "sign_up_error",
            email: email,
            error: err.message !== undefined ? err.message : err,
        });

        return {
            success: false,
            message: message,
        };
    }
}

async function rollbackUserDatabase(user) {
    try {
        await deleteDoc(doc(collection(db, "user_datas"), user.uid));
        await deleteDoc(doc(db, "user_profiles_v2", `${user.uid}`));

        const { totalItems, id } = await searchPeople(user.email);
        if (totalItems >= 1) {
            await deletePerson(id);
        }

        await deleteStripeCustomer(user.uid);

        if (user) {
            await user.delete();
        }
    } catch (error) {
        console.error(error.message);
    }

    return;
}

export async function sendVerificationEmail(userEmail, userName, interestedProduct) {
    const res = await fetch(
        `https://us-central1-backend-server-333005.cloudfunctions.net/sendVerifyEmailForDentalCAD`,
        {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                userEmail,
                userName,
                redirectUrl: `https://dentscape.ai/tryForFree?status=verified&email=${userEmail}&product=${interestedProduct}`,
            }),
        }
    );
    const resBody = await res.json();
    if (res.status !== 200) {
        throw new Error(resBody.message);
    }

    return [resBody.message, resBody.link];
}

export async function handleUserVerification(actionCode, email) {
    try {
        await applyActionCode(auth, actionCode);
        await uploadVerifiedStatus(email);
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function uploadVerifiedStatus(email) {
    try {
        const { id } = await searchPeople(email);
        await updatePerson({
            id,
            email,
            status: "Verified",
        });

        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
}

async function reAuth({ password }) {
    return new Promise(async (resolve, reject) => {
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(user.email, password);
        await reauthenticateWithCredential(user, credential)
            .then(() => {
                resolve(user);
            })
            .catch((error) => {
                reject(error.message);
            });
    });
}

// Reset password function
export async function sendPasswordReset(email, setIsSend, setSendError) {
    try {
        await sendResetPasswordEmail(email);
        setIsSend(true);
    } catch (err) {
        console.error(err);
        setIsSend(false);
        setSendError(err.message);
    }
}

async function sendResetPasswordEmail(userEmail) {
    const res = await fetch(
        `https://us-central1-backend-server-333005.cloudfunctions.net/v1/sendResetPasswordEmail?userEmail=${userEmail}`,
        {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json; charset=UTF-8",
            },
        }
    );
    const resBody = await res.json();
    if (res.status !== 200) {
        throw Error(resBody.message);
    }

    return resBody;
}

export async function handleResetPassword(actionCode, newPassword, setIsChanged) {
    confirmPasswordReset(auth, actionCode, newPassword)
        .then((resp) => {
            console.log(resp);
            setIsChanged(true);
        })
        .catch((error) => {
            console.log(error);
            setIsChanged(false);
        });
}

//Change password
export async function changePassword({ oldPassword, newPassword }) {
    try {
        const user = await reAuth({ password: oldPassword });
        await updatePassword(user, newPassword);
        return { status: true, message: "Password changed successfully." };
    } catch (err) {
        if (err === "Firebase: Error (auth/wrong-password).") {
            return {
                status: false,
                message: "Incorrect password, please try again.",
            };
        } else if (err === "Firebase: Error (auth/missing-password).") {
            return {
                status: false,
                message: "Please enter your password.",
            };
        } else {
            return { status: false, message: err };
        }
    }
}

// Logout function
export const logout = () => {
    document.cookie = "activeProduct=; id=; expires=Thu, 01 Jan 2023 00:00:00 UTC; path=/;";
    signOut(auth);
};

//Delete account
async function deleteAccountDatabase(userUid, deleteReason) {
    const docRef = doc(db, "user_datas", userUid);
    const userDoc = await getDoc(docRef);

    if (!userDoc.exists()) {
        return;
    }

    const data = userDoc.data();

    const updateData = {
        ...data,
        status: "Deleted",
        deleteReason,
    };

    await setDoc(doc(db, "deleted-users", userUid), updateData);
    await deleteDoc(docRef);
}

export async function deleteAccount({ deleteReason, oldPassword }) {
    try {
        const user = auth.currentUser;
        const isSignInWithGoogle = user.providerData[0].providerId === "google.com";

        if (!isSignInWithGoogle) {
            await reAuth({ password: oldPassword });
        }
        const userEmail = user.email;
        const userUid = user.uid;

        const { id } = await searchPeople(userEmail);
        await updatePerson({
            id,
            email: userEmail,
            status: "Deleted",
        });
        await deleteAccountDatabase(userUid, deleteReason);
        await user.delete();

        console.log("Account deleted.");
        return { status: true, message: "Account deleted successfully." };
    } catch (error) {
        console.error(error);
        if (error === "Firebase: Error (auth/wrong-password).") {
            return {
                status: false,
                message: "Incorrect password, please try again.",
            };
        } else if (error === "Firebase: Error (auth/missing-password).") {
            return {
                status: false,
                message: "Please enter your password.",
            };
        } else {
            return {
                status: false,
                message: "Failed to delete account. Please try again later or contact us.",
            };
        }
    }
}

export async function getDesktopFileUrl() {
    const docRef = doc(db, "preteeth_ai_pro_desktop", "fileData");
    const docSnap = await getDoc(docRef);
    const fileData = docSnap.data();

    return {
        url: fileData.url,
        size: fileData.size,
        version: fileData.version,
    };
}
