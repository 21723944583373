import { Progress, Segmented } from "antd";
import "./APIDemo.css";
import { useEffect, useState } from "react";

export default function APIDemo({ contactRef }) {
    const [demoType, setDemoType] = useState("ortho");
    const [demoImg, setDemoImg] = useState(1);
    const [showDemoResult, setShowDemoResult] = useState(false);
    const [imgHover, setImgHover] = useState(false);
    const [demoProgress, setDemoProgress] = useState(100);
    const [loading, setLoading] = useState(false);
    const [imgNum, setImgNum] = useState(0);

    const handleUpload = async () => {
        setDemoProgress(0);
        setLoading(true);
        setShowDemoResult(true);
    };

    useEffect(() => {
        const countdown = setInterval(() => {
            setDemoProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 100 / 8;
                } else {
                    setLoading(false);
                    clearInterval(countdown);
                    return 100;
                }
            });
        }, 1000);

        return () => {
            clearInterval(countdown);
        };
    }, [loading, demoProgress]);

    const renderUploadButton = () => {
        if (demoImg) {
            return (
                <button
                    onClick={() => {
                        setDemoImg(null);
                        setShowDemoResult(false);
                    }}
                    onMouseOver={() => setImgHover(true)}
                    onMouseOut={() => setImgHover(false)}
                >
                    {imgHover && (
                        <div>
                            <svg
                                className="btn-cancel"
                                viewBox="0 0 88 88"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g>
                                    <ellipse cx="37.5" cy="37.4999" rx="37.5" ry="37.4999" />
                                    <path
                                        d="M49.2666 52.9972L38.0773 41.8079L26.8879 52.9973L23.1581 49.2675L34.3475 38.0781L23.1581 26.8887L26.8879 23.1589L38.0773 34.3483L49.2667 23.1589L52.9964 26.8887L41.8071 38.0781L52.9964 49.2674L49.2666 52.9972Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <filter
                                        id="filter0_d_5911_19422"
                                        x="0"
                                        y="0"
                                        width="87.288"
                                        height="87.288"
                                        filterUnits="userSpaceOnUse"
                                        colorInterpolationFilters="sRGB"
                                    >
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix
                                            in="SourceAlpha"
                                            type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"
                                        />
                                        <feOffset dx="6.14399" dy="6.14399" />
                                        <feGaussianBlur stdDeviation="3.072" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix
                                            type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                                        />
                                        <feBlend
                                            mode="normal"
                                            in2="BackgroundImageFix"
                                            result="effect1_dropShadow_5911_19422"
                                        />
                                        <feBlend
                                            mode="normal"
                                            in="SourceGraphic"
                                            in2="effect1_dropShadow_5911_19422"
                                            result="shape"
                                        />
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                    )}
                    <img
                        src={require(`../../../assets/img/dentist/api/demo/${demoType}-demo${demoImg}/img-demo.webp`)}
                        alt="preview"
                    />
                </button>
            );
        } else {
            <p>Select a demo image</p>;
        }
    };

    const loadingScreen = () => (
        <div className="loading-screen">
            <img src={require("../../../assets/img/logo/logo_preteeth.png")} alt="Preteeth AI" />
            <Progress percent={demoProgress} showInfo={false} />
            <p>{demoProgress.toFixed(0)}%</p>
        </div>
    );

    return (
        <div id="apiDemo">
            <h3 data-aos="zoom-in">Experience the Magic of Preteeth AI API</h3>
            <Segmented
                options={["Ortho", "Veneer"]}
                value={demoType.charAt(0).toUpperCase() + demoType.slice(1)}
                onChange={(value) => {
                    setDemoType(value.toLowerCase());
                    setShowDemoResult(false);
                }}
                data-aos="zoom-in"
                data-aos-delay="200"
            />
            <div className="demo-box" data-aos="zoom-in" data-aos-delay="400">
                {loading && loadingScreen()}
                <div className="demo-images">
                    {[1, 2, 3].map((index) => (
                        <img
                            key={index}
                            className={`img-demo ${demoImg === index && "img-demo-active"}`}
                            alt="Demo"
                            src={require(`../../../assets/img/dentist/api/demo/${demoType}-demo${index}/img-demo.webp`)}
                            onClick={() => {
                                setDemoImg(index);
                                setShowDemoResult(false);
                                setImgNum(0);
                            }}
                        />
                    ))}
                </div>

                <div className="demo-content">
                    <div className="upload-label">{renderUploadButton()}</div>

                    <button
                        className={`btn-next ${(demoImg === null || showDemoResult) && "btn-next-invalid"}`}
                        onClick={handleUpload}
                        disabled={demoImg === null || showDemoResult}
                    >
                        <svg viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <circle cx="40" cy="40" r="40" />
                            </g>
                            <mask
                                id="mask0_5911_18660"
                                style={{ maskType: "alpha" }}
                                maskUnits="userSpaceOnUse"
                                x="18"
                                y="18"
                                width="45"
                                height="45"
                            >
                                <rect x="18" y="18" width="45" height="45" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_5911_18660)">
                                <path
                                    d="M40.5 55.5L37.8281 52.875L48.3281 42.375H25.5V38.625H48.3281L37.8281 28.125L40.5 25.5L55.5 40.5L40.5 55.5Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <filter
                                    id="filter0_d_5911_18660"
                                    x="0"
                                    y="0"
                                    width="92"
                                    height="92"
                                    filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB"
                                >
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                    />
                                    <feOffset dx="6" dy="6" />
                                    <feGaussianBlur stdDeviation="3" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                                    <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_5911_18660"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_5911_18660"
                                        result="shape"
                                    />
                                </filter>
                            </defs>
                        </svg>
                    </button>

                    <div className="result-box">
                        <div className={`result-img-box ${!loading && showDemoResult && "result-img-box-show"}`}>
                            {!loading && showDemoResult ? (
                                demoType === "veneer" ? (
                                    <img
                                        src={require(`../../../assets/img/dentist/api/demo/veneer-demo${demoImg}/img-result${
                                            imgNum + 1
                                        }.webp`)}
                                        alt="Veneer After"
                                    />
                                ) : (
                                    <img
                                        src={require(`../../../assets/img/dentist/api/demo/${demoType}-demo${demoImg}/img-result.webp`)}
                                        alt="Simulation Result"
                                    />
                                )
                            ) : null}
                        </div>
                        {!loading && showDemoResult && demoType === "veneer" && (
                            <div className="carousel-button-box">
                                <button onClick={() => setImgNum(imgNum === 0 ? 3 : imgNum - 1)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 24">
                                        <path
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2.5"
                                            d="M12 2 2 12l10 10"
                                        />
                                    </svg>
                                </button>

                                <button onClick={() => setImgNum(imgNum === 3 ? 0 : imgNum + 1)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 24">
                                        <path
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2.5"
                                            d="m1.5 22 10-10-10-10"
                                        />
                                    </svg>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <button
                className="btn-getAccess"
                data-aos="zoom-in"
                data-aos-delay="300"
                onClick={() => contactRef.current.scrollIntoView({ behavior: "smooth" })}
            >
                Try your own case
            </button>
        </div>
    );
}
