import "./CTA.scss";
import { Link } from "react-router-dom";

export default function CTA() {
    return (
        <div className="cta" data-aos="zoom-in" data-aos-duration="750">
            <h1 className="cta-title">
                Speed, precision, and more time for yourself.
                <br />
                Try it for free.
            </h1>
            <Link
                className="btn-start-now"
                to="/contact"
                onClick={() => window.scrollTo(0, 0)}
            >
                Start Now
            </Link>
        </div>
    );
}
