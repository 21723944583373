import { Col, Divider, Modal, Row, Tabs } from "antd";
import "./API.css";
import React from "react";
import { useRef, useState } from "react";
import allAPI from "../../../json/allAPI.json";
import { useNavigate } from "react-router-dom";
import APIDemo from "./APIDemo";

export default function API() {
  const [activeTA, setActiveTA] = useState(0);
  const [analysisModalOpen, setAnalysisModalOpen] = useState(-1);
  const [simulationModalOpen, setSimulationModalOpen] = useState(-1);
  //   const [simulaImgModalOpen, setSimulaImgModalOpen] = useState(false);
  const contactRef = useRef(null);
  const navigate = useNavigate();

  const TA = [
    {
      name: "Clear Aligner / \nOrthodontic Company",
      benefits: [
        "Spot issues with AI Smile Analysis",
        "Excite patients with AI Smile Simulation",
        "Flexible integration into your patient engagement workflow",
      ],
      img: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 75 75">
          <circle
            cx="37.5"
            cy="37.5"
            r="37.5"
            fill={activeTA === 0 ? "#333" : "#fff"}
            opacity={activeTA !== 0 ? "0.4" : "1"}
          />
          <path
            fill="#fff"
            d="M48.842 46.217H25.364c-2.283 0-4.141 1.388-4.141 3.117 0 6.55 7.141 11.884 15.913 11.884 8.771 0 15.913-5.333 15.913-11.884-.033-1.704-1.924-3.117-4.207-3.117Z"
          />
        </svg>
      ),
    },
    {
      name: "Practice Management (PMS)",
      benefits: [
        "Empower users with winning cosmetic cases",
        "Streamline consultation workflows effortlessly",
        "Comprehensive dental solution powered by AI",
      ],
      img: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 75 75">
          <path
            fill={activeTA === 1 ? "#333" : "#fff"}
            d="M68.656 20.031v20.313H6.25V20.03c0-7.593 6.188-13.781 13.781-13.781h34.844c7.594 0 13.781 6.188 13.781 13.781Z"
            opacity=".4"
          />
          <path
            fill={activeTA === 1 ? "#333" : "#fff"}
            d="M6.25 40.381v.625a13.781 13.781 0 0 0 13.781 13.782h12a3.134 3.134 0 0 1 3.125 3.125v3.03a3.134 3.134 0 0 1-3.125 3.126H24.47a2.344 2.344 0 0 0 0 4.687h26.093a2.36 2.36 0 0 0 2.344-2.344 2.36 2.36 0 0 0-2.343-2.343H43a3.134 3.134 0 0 1-3.125-3.125v-3.032A3.134 3.134 0 0 1 43 54.788h11.906a13.78 13.78 0 0 0 13.782-13.782v-.625H6.25Z"
          />
        </svg>
      ),
    },
    {
      name: "Patient Engagement Software (PES)",
      benefits: [
        "Go beyond basic messaging services",
        "Unleash cosmetic opportunities for your users",
        "No heavy investment required for AI team setup",
      ],
      img: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 75 75">
          <path
            fill={activeTA === 2 ? "#333" : "#fff"}
            d="M62.5 38.125v5.313c0 9.843-5.625 14.062-14.063 14.062H20.314c-8.438 0-14.063-4.219-14.063-14.063V26.564c0-9.844 5.625-14.063 14.063-14.063h8.437a12.043 12.043 0 0 0-.625 3.906v12.188c0 3.031 1 5.593 2.781 7.375 1.782 1.781 4.344 2.781 7.375 2.781v4.344c0 1.593 1.813 2.562 3.157 1.687l9.03-6.031h8.126c1.406 0 2.718-.219 3.906-.625Z"
          />
          <path
            fill={activeTA === 2 ? "#333" : "#fff"}
            d="M68.748 16.406v12.188c0 4.656-2.375 8.156-6.25 9.531-1.187.406-2.5.625-3.906.625h-8.125l-9.031 6.031c-1.344.875-3.156-.093-3.156-1.687V38.75c-3.032 0-5.594-1-7.375-2.781-1.782-1.782-2.782-4.344-2.782-7.375V16.406c0-1.406.22-2.718.625-3.906 1.375-3.875 4.875-6.25 9.532-6.25h20.312c6.094 0 10.156 4.063 10.156 10.156ZM45.618 66.4H36.71v-8.906a2.36 2.36 0 0 0-2.344-2.344 2.36 2.36 0 0 0-2.344 2.344V66.4h-8.906a2.36 2.36 0 0 0-2.344 2.344 2.36 2.36 0 0 0 2.344 2.344h22.5a2.36 2.36 0 0 0 2.344-2.344 2.36 2.36 0 0 0-2.344-2.344Z"
            opacity=".4"
          />
          <path
            fill={activeTA === 2 ? "#333" : "#fff"}
            d="M48.254 25a2.34 2.34 0 0 1-.902-4.502A2.34 2.34 0 1 1 48.254 25Zm8.778 0a2.34 2.34 0 1 1-.006-4.68 2.34 2.34 0 0 1 .006 4.68Zm-17.59 0a2.34 2.34 0 0 1-.902-4.502 2.34 2.34 0 1 1 .9 4.502Z"
          />
        </svg>
      ),
    },
    {
      name: "Dental Service Organization (DSO)",
      benefits: [
        "Generate more cosmetic cases with AI Smile Analysis",
        "Increase case acceptance rate through AI Smile Simulation",
        "No need for expensive AI team setup",
      ],
      img: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 75 75">
          <path
            fill={activeTA === 3 ? "#333" : "#fff"}
            d="M46.906 46.094v22.625l-37.5.062V46.094l-.031-4.688V22.094a6.266 6.266 0 0 1 2.781-5.188l12.5-8.343a6.232 6.232 0 0 1 6.938 0l12.5 8.343a6.19 6.19 0 0 1 2.781 5.188v19.312l.031 4.688Z"
            opacity=".4"
          />
          <path
            fill={activeTA === 3 ? "#333" : "#fff"}
            d="M68.75 66.41h-3.969v-9.376c2.969-.968 5.125-3.75 5.125-7.03v-6.25c0-4.095-3.344-7.438-7.437-7.438-4.094 0-7.438 3.343-7.438 7.437v6.25c0 3.25 2.125 6 5.032 7v9.406H6.25a2.36 2.36 0 0 0-2.344 2.344 2.36 2.36 0 0 0 2.344 2.344h56.031c.063 0 .094.031.157.031.062 0 .093-.031.156-.031h6.156a2.36 2.36 0 0 0 2.344-2.344 2.36 2.36 0 0 0-2.344-2.344Z"
          />
          <path
            fill={activeTA === 3 ? "#333" : "#fff"}
            d="M46.906 46.094h-37.5l-.031-4.688h37.5l.031 4.688Zm-18.781 25a2.361 2.361 0 0 1-2.344-2.344V57.031a2.36 2.36 0 0 1 2.344-2.343 2.36 2.36 0 0 1 2.344 2.343V68.75a2.361 2.361 0 0 1-2.344 2.344Zm1.49-45.709h3.77c.883 0 1.615.732 1.615 1.615s-.732 1.615-1.615 1.615h-3.77v3.77c0 .883-.732 1.615-1.615 1.615a1.627 1.627 0 0 1-1.615-1.615v-3.77h-3.77A1.627 1.627 0 0 1 21 27c0-.883.732-1.615 1.615-1.615h3.77v-3.77c0-.883.732-1.615 1.615-1.615s1.615.732 1.615 1.615v3.77Z"
          />
        </svg>
      ),
    },
    {
      name: "Dental Marketing Company",
      benefits: [
        "Boost your value proposition with AI",
        "No need for costly AI team setup",
        "Easy integration for seamless operation",
      ],
      img: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 75 75">
          <path
            fill={activeTA === 4 ? "#333" : "#fff"}
            d="M66.781 35.594v18.718c0 8.626-7 15.626-15.625 15.626H23.844c-8.625 0-15.625-7-15.625-15.626v-18.5c2.375 2.563 5.75 4.032 9.406 4.032 3.938 0 7.719-1.969 10.094-5.125a11.732 11.732 0 0 0 9.781 5.125c4 0 7.563-1.875 9.719-5 2.406 3.093 6.125 5 10 5 3.781 0 7.218-1.532 9.562-4.25Z"
            opacity=".4"
          />
          <path
            fill={activeTA === 4 ? "#333" : "#fff"}
            d="M46.842 3.906h-18.75l-2.313 23c-.187 2.125.125 4.125.907 5.938 1.812 4.25 6.062 7 10.812 7 4.813 0 8.969-2.688 10.844-6.969a12.44 12.44 0 0 0 .938-4.5v-.594L46.841 3.906Z"
          />
          <path
            fill={activeTA === 4 ? "#333" : "#fff"}
            d="m69.877 25.844-.906-8.657c-1.313-9.437-5.594-13.28-14.75-13.28h-12l2.312 23.437c.031.312.063.656.063 1.25a11.931 11.931 0 0 0 1.437 4.468c2.25 4.126 6.625 6.782 11.188 6.782 4.156 0 7.906-1.844 10.25-5.094 1.875-2.5 2.718-5.656 2.406-8.906ZM20.596 3.906c-9.188 0-13.438 3.844-14.782 13.375l-.843 8.594c-.313 3.344.593 6.594 2.562 9.125 2.375 3.094 6.031 4.844 10.094 4.844 4.562 0 8.937-2.657 11.156-6.719a11.632 11.632 0 0 0 1.5-4.719l2.438-24.468H20.596v-.032Z"
            opacity=".6"
          />
          <path
            fill={activeTA === 4 ? "#333" : "#fff"}
            d="M35.469 52.063a7.796 7.796 0 0 0-6.969 7.78v10.094h17.97v-9c.031-6.53-3.813-9.624-11-8.874Z"
          />
        </svg>
      ),
    },
  ];

  const ImgCarousel = ({ item, imgNum, setImgNum }) => (
    <div className="img-carousel">
      <button
        onClick={() =>
          setImgNum(imgNum === 0 ? item.afterImg.length - 1 : imgNum - 1)
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="20"
          fill="none"
          viewBox="0 0 14 24"
        >
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
            d="M12 2 2 12l10 10"
          />
        </svg>
      </button>
      <div className="img-content">
        <img
          className="img-simulation"
          src={require(`../../../assets/img/dentist/api/simulation/${item.afterImg[imgNum]}`)}
          alt={`${item.name} After`}
          loading="lazy"
          // onClick={() => setSimulaImgModalOpen(true)}
        />
        {/* <Modal
            className="simulationImg-modal"
            centered
            open={simulaImgModalOpen}
            onCancel={() => setSimulaImgModalOpen(false)}
            footer={null}
          >
            <img
              className="img-simulation"
              src={require(`../../../assets/img/dentist/api/simulaion/${item.afterImg[imgNum]}`)}
              alt={`${item.name} After`}
            />
          </Modal> */}
        <div style={{ display: "flex", gap: "3px", marginTop: "5px" }}>
          {item.afterImg.map((_, index) => (
            <button key={index} onClick={() => setImgNum(index)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8.5"
                height="8.5"
                fill="none"
                viewBox="0 0 10 10"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.5"
                  fill={imgNum === index ? "#fff" : "none"}
                  stroke="#fff"
                />
              </svg>
            </button>
          ))}
        </div>
      </div>
      <button
        onClick={() =>
          setImgNum(imgNum === item.afterImg.length - 1 ? 0 : imgNum + 1)
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="20"
          fill="none"
          viewBox="0 0 14 24"
        >
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
            d="m1.5 22 10-10-10-10"
          />
        </svg>
      </button>
    </div>
  );

  const ApiModal = ({ type, item, index, modalOpen, onCancel }) => {
    const [imgNum, setImgNum] = useState(0);

    return (
      <Modal
        className={`api-modal ${type === "simulation" && "simulation-modal"}`}
        centered
        open={modalOpen === index && true}
        onCancel={() => {
          onCancel();
          if (type === "simulation") {
            setImgNum(0);
          }
        }}
        footer={null}
        maskStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
      >
        {type === "analysis" ? (
          <img
            src={require(`../../../assets/img/dentist/api/analysis/${item.img}`)}
            alt={item.name}
            loading="lazy"
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <img
              className="img-simulation"
              src={require(`../../../assets/img/dentist/api/simulation/${item.beforeImg}`)}
              alt={`${item.name} Before`}
              loading="lazy"
            />
            {item.afterImg.length === 1 ? (
              <img
                className="img-simulation"
                src={require(`../../../assets/img/dentist/api/simulation/${item.afterImg}`)}
                alt={`${item.name} After`}
                loading="lazy"
              />
            ) : (
              <ImgCarousel item={item} imgNum={imgNum} setImgNum={setImgNum} />
            )}
          </div>
        )}
        <Divider type="vertical" />
        <div className="detail-content">
          <h3>{item.name}</h3>
          <p>{item.description}</p>
        </div>
      </Modal>
    );
  };

  const analysisAPIs = (
    <Row className="apiDetail-container">
      {allAPI.analysis.map((item, index) => (
        <React.Fragment key={index}>
          <Col
            className="api-card"
            data-aos="zoom-in"
            data-aos-delay={index * 100}
            xl={7}
            lg={11}
            md={11}
            sm={24}
            onClick={() => setAnalysisModalOpen(index)}
          >
            <img
              className="api-card-img"
              src={require(`../../../assets/img/dentist/api/analysis/${item.img}`)}
              alt={item.name}
            />
            <h4>{item.name}</h4>
            <button
              className="btn-learnMore"
              onClick={() => setAnalysisModalOpen(index)}
            >
              Learn more <span className="arrow"></span>
            </button>
          </Col>
          <ApiModal
            type="analysis"
            item={item}
            index={index}
            modalOpen={analysisModalOpen}
            onCancel={() => setAnalysisModalOpen(-1)}
          />
        </React.Fragment>
      ))}
    </Row>
  );

  const simulationAPIs = (
    <Row className="apiDetail-container">
      {allAPI.simulation.map((item, index) => (
        <React.Fragment key={index}>
          <Col
            className="api-card"
            data-aos="zoom-in"
            data-aos-delay={index * 100}
            xl={7}
            lg={11}
            md={11}
            sm={24}
            onClick={() => setSimulationModalOpen(index)}
          >
            <img
              className="api-card-img"
              src={require(`../../../assets/img/dentist/api/simulation/${item.mainImg}`)}
              alt={item.name}
            />
            <h4>{item.name}</h4>
            <button
              className="btn-learnMore"
              onClick={() => setSimulationModalOpen(index)}
            >
              Learn more <span className="arrow"></span>
            </button>
          </Col>
          <ApiModal
            type="simulation"
            item={item}
            index={index}
            modalOpen={simulationModalOpen}
            onCancel={() => setSimulationModalOpen(-1)}
          />
        </React.Fragment>
      ))}
    </Row>
  );

  const items = [
    {
      key: "1",
      label: "Analysis",
      children: analysisAPIs,
    },
    {
      key: "2",
      label: "Simulation",
      children: simulationAPIs,
    },
  ];

  return (
    <div id="api">
      <div className="api-bg">
        <div className="api-intro">
          <h2 data-aos="fade-in" data-aos-duration="600">
            Revolutionize Smile Design with<br></br> AI Smile Analysis and
            Simulation API
          </h2>
          <Divider />
          <h4 data-aos="fade-in" data-aos-duration="600" data-aos-delay="400">
            Harness the potential of our Dental AI Simulation API for accurate
            and realistic dental treatment planning.
          </h4>
        </div>

        {/* TA & benefits */}
        <Row className="ta-container">
          <Col xl={10}>
            <h2 data-aos="fade-in" data-aos-duration="600">
              Looking to enhance your dental software capabilities with
              AI-driven simulation?
            </h2>
            <h4 data-aos="fade-in" data-aos-duration="500">
              Our AI Analysis and Simulation API integrates seamlessly with
              leading dental software, offering advanced orthodontic simulation
              and precise smile analysis capabilities.
            </h4>
            <div className="cta-btnBox">
              <button
                className="btn-getAccess"
                data-aos="zoom-in"
                data-aos-delay="300"
                onClick={() =>
                  contactRef.current.scrollIntoView({ behavior: "smooth" })
                }
              >
                Get API Access
              </button>
              <button
                className="btn-viewDocs"
                onClick={() =>
                  window.open(
                    "https://preteeth-ai.readme.io/reference/quick-start"
                  )
                }
                data-aos="zoom-in"
                data-aos-delay="450"
              >
                View Docs
              </button>
            </div>
          </Col>
          <Col className="ta-detail-container" xl={11} sm={24}>
            {TA.map((item, index) => (
              <div
                className="ta-card"
                style={{
                  backgroundColor: activeTA === index && "#fff",
                  borderColor: activeTA === index && "#fff",
                }}
                key={index}
                data-aos="zoom-in"
                data-aos-delay={index * 150}
              >
                <div className="ta-card-header">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {item.img}
                    <p style={{ color: activeTA === index && "#000" }}>
                      {item.name}
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      setActiveTA(index);
                    }}
                  >
                    {activeTA === index ? (
                      <svg
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.54939 5.55C5.7233 5.37584 5.92984 5.23767 6.1572 5.1434C6.38455 5.04913 6.62826 5.00061 6.87439 5.00061C7.12051 5.00061 7.36422 5.04913 7.59158 5.1434C7.81893 5.23767 8.02547 5.37584 8.19939 5.55L14.9994 12.3475L21.7994 5.55C21.9734 5.376 22.18 5.23797 22.4073 5.1438C22.6346 5.04963 22.8783 5.00116 23.1244 5.00116C23.3705 5.00116 23.6141 5.04963 23.8415 5.1438C24.0688 5.23797 24.2754 5.376 24.4494 5.55C24.6234 5.724 24.7614 5.93057 24.8556 6.15791C24.9498 6.38526 24.9982 6.62892 24.9982 6.875C24.9982 7.12107 24.9498 7.36474 24.8556 7.59208C24.7614 7.81943 24.6234 8.02599 24.4494 8.2L17.6519 15L24.4494 21.8C24.8008 22.1514 24.9982 22.628 24.9982 23.125C24.9982 23.622 24.8008 24.0986 24.4494 24.45C24.098 24.8014 23.6214 24.9988 23.1244 24.9988C22.6274 24.9988 22.1508 24.8014 21.7994 24.45L14.9994 17.6525L8.19939 24.45C7.84797 24.8014 7.37136 24.9988 6.87439 24.9988C6.37741 24.9988 5.9008 24.8014 5.54939 24.45C5.19797 24.0986 5.00055 23.622 5.00055 23.125C5.00055 22.628 5.19797 22.1514 5.54939 21.8L12.3469 15L5.54939 8.2C5.37523 8.02608 5.23706 7.81954 5.14279 7.59219C5.04852 7.36483 5 7.12112 5 6.875C5 6.62887 5.04852 6.38516 5.14279 6.15781C5.23706 5.93045 5.37523 5.72391 5.54939 5.55Z"
                          fill="#333333"
                        />
                      </svg>
                    ) : (
                      <svg
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.5 16.5C7.10218 16.5 6.72064 16.342 6.43934 16.0607C6.15804 15.7794 6 15.3978 6 15C6 14.6022 6.15804 14.2206 6.43934 13.9393C6.72064 13.658 7.10218 13.5 7.5 13.5H22.5C22.8978 13.5 23.2794 13.658 23.5607 13.9393C23.842 14.2206 24 14.6022 24 15C24 15.3978 23.842 15.7794 23.5607 16.0607C23.2794 16.342 22.8978 16.5 22.5 16.5H7.5Z"
                          fill="white"
                        />
                        <path
                          d="M13.5 7.5C13.5 7.10218 13.658 6.72064 13.9393 6.43934C14.2206 6.15804 14.6022 6 15 6C15.3978 6 15.7794 6.15804 16.0607 6.43934C16.342 6.72064 16.5 7.10218 16.5 7.5V22.5C16.5 22.8978 16.342 23.2794 16.0607 23.5607C15.7794 23.842 15.3978 24 15 24C14.6022 24 14.2206 23.842 13.9393 23.5607C13.658 23.2794 13.5 22.8978 13.5 22.5V7.5Z"
                          fill="white"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                <div
                  className={`ta-content ${
                    activeTA === index && " ta-content-show"
                  }`}
                >
                  <Divider />
                  <ul>
                    {item.benefits.map((benefit, index) => (
                      <li key={index}>{benefit}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </Col>
        </Row>

        <APIDemo contactRef={contactRef} />
      </div>

      {/* API detail */}
      <div className="apiDetail">
        <Tabs defaultActiveKey="1" items={items} />
      </div>

      {/* Contact us */}
      <div className="api-contact" ref={contactRef}>
        <h4 data-aos="zoom-in-down" data-aos-duration="500">
          Looking to connect your application with our API?<br></br>
          Reach us via email, and we'll be happy to assist you.
        </h4>
        <a
          className="btn-contactUs"
          href="mailto:service@preteeth.com"
          data-aos="zoom-in-down"
          data-aos-duration="500"
          data-aos-delay="400"
        >
          Contact us
        </a>
      </div>
    </div>
  );
}
