import React, { createContext, useEffect, useState } from "react";

export const storeContext = createContext();

const GlobalContext = ({ children }) => {
    const [userType, setUserType] = useState("");
    const [openModal, setOpenModal] = useState(true);
    const [patientCtaButton, setPatientCtaButton] = useState("");
    const [currentMd, setCurrentMd] = useState(7);
    const [name, setName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [type, setType] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [region, setRegion] = useState("");
    const [ipCountry, setIpCountry] = useState("");
    const [preteethAIProPayment, setPreteethAIProPayment] = useState([]);
    const [isFreeTrial, setIsFreeTrial] = useState(true);
    const [fromLogin, setFromLogin] = useState(false);

    //Get region
    let getRegionInit = false;
    useEffect(() => {
        const getRegion = async () => {
            try {
                const response = await fetch("https://ipapi.co/json/");
                if (!response.ok) {
                    throw new Error("Failed to fetch region data.");
                }
                const data = await response.json();

                if (data.continent_code === "NA" || data.continent_code === "SA" || data.continent_code === "AN")
                    setRegion("us");
                else if (data.continent_code === "EU" || data.continent_code === "AF") setRegion("eu");
                //continent_code === "AS" or "OC"
                else setRegion("asia");
                setIpCountry(data.country_name);
            } catch (error) {
                console.error(error);
                setRegion("asia");
            }
        };

        async function doGetRegion() {
            if (!getRegionInit) {
                getRegionInit = true;
                await getRegion();
            }
        }

        doGetRegion();
    }, []);

    return (
        <storeContext.Provider
            value={{
                userType,
                setUserType,
                openModal,
                setOpenModal,
                patientCtaButton,
                setPatientCtaButton,
                currentMd,
                setCurrentMd,
                name,
                setName,
                firstName,
                setFirstName,
                lastName,
                setLastName,
                type,
                setType,
                country,
                setCountry,
                city,
                setCity,
                phone,
                setPhone,
                region,
                setRegion,
                ipCountry,
                setIpCountry,
                preteethAIProPayment,
                setPreteethAIProPayment,
                isFreeTrial,
                setIsFreeTrial,
                fromLogin,
                setFromLogin,
            }}
        >
            {children}
        </storeContext.Provider>
    );
};

export default GlobalContext;
