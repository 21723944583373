import { useState, useRef, useContext, useCallback } from "react";
import Webcam from "react-webcam";
import { storeContext } from "../../../../store";
import { smileCareStore } from "../store";
import { utcToZonedTime, format } from "date-fns-tz";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../../api/firebase";
import { Col, Modal, Row, message } from "antd";
import imageCompression from "browser-image-compression";
import "./UploadPhoto.scss";

export default function UploadPhoto() {
  const { region } = useContext(storeContext);
  const {
    userId,
    openCamera,
    setOpenCamera,
    setDate,
    smilePic,
    setSmilePic,
    smilePicAsFile,
    setSmilePicAsFile,
    setAnalyzing,
    setSmilePicUrl,
    setAnalysisResult,
  } = useContext(smileCareStore);
  const webcamRef = useRef(null);
  const imgRef = useRef(null);
  const [facingMode, setFacingMode] = useState("user");
  const [openModal, setOpenModal] = useState(false);
  const [showRetake, setShowRetake] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const MIN_IMAGE_SIZE = 500 * 1024; // 500KB
  const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB

  const videoConstraints = {
    aspectRatio: { ideal: 1.3333 },
    width: { ideal: 900 },
    height: { ideal: 1200 },
    facingMode: facingMode,
  };

  function handleCamera() {
    startWebcam();
    setOpenModal(true);
    setOpenCamera(!openCamera);
  }

  const base64ToBlob = (base64Data) => {
    const parts = base64Data.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const byteCharacters = atob(parts[1]);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  const capture = useCallback(() => {
    setSmilePic(webcamRef.current.getScreenshot());
    const base64Data = webcamRef.current.getScreenshot();
    const blob = base64ToBlob(base64Data);
    setSmilePicAsFile(blob);
  }, [webcamRef]);

  const startWebcam = async () => {
    if (webcamRef.current) {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: videoConstraints,
      });
      webcamRef.current.video.srcObject = stream;
    }
  };

  const stopWebcam = () => {
    if (webcamRef.current) {
      const videoTracks = webcamRef.current.video.srcObject.getVideoTracks();
      videoTracks.forEach((track) => track.stop());
    }
  };

  const processImage = async (image) => {
    let processedImage = image;

    if (processedImage.size < MIN_IMAGE_SIZE) {
      processedImage = await resizeImage(processedImage);
    }

    if (processedImage.size > MAX_IMAGE_SIZE) {
      processedImage = await compressImage(processedImage);
    }

    if (
      !processedImage.type.includes("jpeg") ||
      !processedImage.type.includes("jpg") ||
      !processedImage.type.includes("png")
    ) {
      processedImage = await convertImage(processedImage);
    }

    return processedImage;
  };

  const resizeImage = async (image) => {
    const img = new Image();
    img.src = URL.createObjectURL(image);

    await img.decode();

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width =
      Math.min(img.width, img.height) < 400 ? img.width * 2.5 : img.width * 2;
    canvas.height =
      Math.min(img.width, img.height) < 400 ? img.height * 2.5 : img.height * 2;
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/png");
    });
  };

  // use browser-image-compression library to compress image
  const compressImage = async (image) => {
    const options = {
      maxSizeMB: 5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      fileType: "image/png",
    };

    return await imageCompression(image, options);
  };

  const convertImage = async (image) => {
    const img = new Image();
    img.src = URL.createObjectURL(image);

    await img.decode();

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        "image/png",
        0.9
      );
    });
  };

  async function uploadPhoto(e) {
    const image = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.addEventListener("load", fileLoad);

    try {
      if (image.type === "image/heic") {
        const convertedImage = await convertImage(image);
        fileReader.readAsDataURL(convertedImage);
        setSmilePic(convertedImage);
        setSmilePicAsFile(convertedImage);
      } else {
        fileReader.readAsDataURL(image);
        setSmilePic(image);
        setSmilePicAsFile(image);
      }

      const img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = () => {
        if (Math.min(img.width, img.height) < 800) {
          setShowRetake(true);
        } else {
          setOpenModal(true);
        }
      };
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Please upload a valid image file, such as JPG or PNG.",
        style: {
          marginTop: "15vh",
        },
      });
    }
  }

  const fileLoad = (e) => {
    setSmilePic(e.target.result);
  };

  const uploadToBucket = async () => {
    const date = format(
      utcToZonedTime(new Date(), "UTC"),
      "yyyy-MM-dd-HH:mm:ss"
    );
    setDate(date);

    const upload = async () => {
      console.log("start of upload");
      setAnalyzing(true);
      const processedImage = await processImage(smilePicAsFile);

      if (smilePicAsFile === "") {
        console.error(
          `not an image, the image file is a ${typeof smilePicAsFile}`
        );
      } else {
        const storageRef = ref(
          storage,
          `gs://backend-server-333005-${region}_v2/smile_care/${userId}/${date}-analysisImages/originalPhoto`
        );
        const uploadTask = uploadBytesResumable(storageRef, processedImage);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            console.log(snapshot.state);
          },
          (error) => {
            console.log(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setSmilePicUrl(url);

              let formdata = new FormData();
              formdata.append("img_url", url);
              formdata.append(
                "token",
                process.env.REACT_APP_ANALYSIS_API_TOKEN
              );
              formdata.append("bucket", `backend-server-333005-${region}_v2`);
              formdata.append(
                "upload_path",
                `smile_care/${userId}/${date}-analysisImages`
              );

              const requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow",
              };

              fetch(
                "https://preteeth.me/smile_care_api/text_and_image_analysis/test_user_id/2",
                requestOptions
              )
                .then((response) => response.json())
                .then(async (result) => {
                  const gum_and_upper_analysis_string_list =
                    await generateTextFromAnalysis(
                      result.gum_and_upper_analysis_string_list
                    );
                  const smile_curve_and_lower_analysis_string_list =
                    await generateTextFromAnalysis(
                      result.smile_curve_and_lower_analysis_string_list
                    );
                  const middle_incisors_analysis_string_list =
                    await generateTextFromAnalysis(
                      result.middle_incisors_analysis_string_list
                    );
                  setAnalysisResult({
                    img_urls: result.img_urls,
                    gum_and_upper_analysis_string_list,
                    smile_curve_and_lower_analysis_string_list,
                    middle_incisors_analysis_string_list,
                  });
                  setAnalyzing(false);
                })
                .catch((error) => {
                  console.log("error", error);
                  setAnalysisResult({
                    error: true,
                  });
                  setSmilePic(null);
                  setSmilePicAsFile(null);
                  setSmilePicUrl(null);
                  setAnalyzing(false);
                });
            });
          }
        );
      }
    };

    await upload();
  };

  const generateTextFromAnalysis = async (text) => {
    const apiBody = {
      model: "gpt-3.5-turbo", // change to "gpt-4" when it's released
      messages: [
        {
          role: "system",
          content: `Please pretend you are an AI dentist and explain to the patient according to the findings below: ${text} Please give a warm and insightful explanation and make it short (keep the response under 70 tokens). Be concise yet informative. Incorporate HTML tags for appropriate line breaks. Skip the greeting. Don't give a conclusion yet.`,
        },
      ],
      temperature: 0,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      },
      body: JSON.stringify(apiBody),
    });

    const data = await response.json();

    return data.choices[0].message.content;
  };

  function switchCamera() {
    setFacingMode(facingMode === "user" ? "environment" : "user");
  }

  return (
    <div className="upload-container">
      {contextHolder}
      {smilePic === null && (
        <div className="btn-box">
          <input
            id="fusk"
            type="file"
            onChange={uploadPhoto}
            style={{ display: "none" }}
          />
          <label htmlFor="fusk" className="btn-openAlbum">
            Upload a photo
          </label>
          <button className="btn-takePhoto" onClick={handleCamera}>
            Take a photo
          </button>
        </div>
      )}

      <Modal
        className="uploadPhoto-modal"
        title={openCamera ? "Take a photo" : "Upload a photo"}
        open={openModal}
        onCancel={() => {
          setOpenModal(false);
          setOpenCamera(false);
          setSmilePic(null);
          setSmilePicAsFile(null);
          setSmilePicUrl(null);
          stopWebcam();
        }}
        footer={false}
        centered
        width="fit-content"
      >
        {!smilePic && openCamera ? (
          <div className="camera-container">
            <div>
              <img
                className="img-cameraGrid"
                src={require("../../../../assets/img/2C/img_cameraGrid.webp")}
              />
              <Webcam
                ref={webcamRef}
                mirrored={facingMode === "user" ? true : false}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                style={{ borderRadius: "5px" }}
              />
            </div>
            <Row className="camera-footer">
              <Col span={8}>
                <input
                  id="fusk"
                  type="file"
                  onChange={uploadPhoto}
                  style={{ display: "none" }}
                />
                <label htmlFor="fusk" className="btn-openAlbum">
                  <svg
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_4061_2258"
                      style={{ maskType: "alpha" }}
                      maskUnits="userSpaceOnUse"
                      x="6"
                      y="6"
                      width="20"
                      height="20"
                    >
                      <rect x="6" y="6" width="20" height="20" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_4061_2258)">
                      <path
                        d="M13.4974 17.667H21.8307L18.9557 13.917L17.0391 16.417L15.7474 14.7503L13.4974 17.667ZM12.6641 21.0003C12.2057 21.0003 11.8134 20.8371 11.487 20.5107C11.1606 20.1844 10.9974 19.792 10.9974 19.3337V9.33366C10.9974 8.87533 11.1606 8.48296 11.487 8.15658C11.8134 7.83019 12.2057 7.66699 12.6641 7.66699H22.6641C23.1224 7.66699 23.5148 7.83019 23.8411 8.15658C24.1675 8.48296 24.3307 8.87533 24.3307 9.33366V19.3337C24.3307 19.792 24.1675 20.1844 23.8411 20.5107C23.5148 20.8371 23.1224 21.0003 22.6641 21.0003H12.6641ZM12.6641 19.3337H22.6641V9.33366H12.6641V19.3337ZM9.33073 24.3337C8.8724 24.3337 8.48003 24.1705 8.15365 23.8441C7.82726 23.5177 7.66406 23.1253 7.66406 22.667V11.0003H9.33073V22.667H20.9974V24.3337H9.33073Z"
                        fill="#333333"
                      />
                    </g>
                    <circle
                      cx="16"
                      cy="16"
                      r="15.25"
                      stroke="#333333"
                      strokeWidth="1.5"
                    />
                  </svg>
                </label>
              </Col>
              <Col span={8} className="takePhoto-box">
                <button className="btn-takePhoto" onClick={capture}>
                  <svg
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="29.9931"
                      cy="30.0018"
                      r="23.0556"
                      fill="#C9C9C9"
                    />
                    <path
                      d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30ZM3.73421 30C3.73421 44.5062 15.4938 56.2658 30 56.2658C44.5062 56.2658 56.2658 44.5062 56.2658 30C56.2658 15.4938 44.5062 3.73421 30 3.73421C15.4938 3.73421 3.73421 15.4938 3.73421 30Z"
                      fill="#C9C9C9"
                    />
                  </svg>
                </button>
              </Col>
              <Col span={8} className="switch-box">
                <button className="btn-switch" onClick={switchCamera}>
                  <svg
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15.25"
                      stroke="#333333"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M15.2 24C14.1867 23.8928 13.2433 23.6147 12.37 23.1658C11.4967 22.7169 10.7367 22.134 10.09 21.4171C9.44333 20.7002 8.93333 19.8794 8.56 18.9548C8.18667 18.0302 8 17.0452 8 16C8 14.7806 8.24333 13.6549 8.73 12.6231C9.21667 11.5913 9.88 10.7069 10.72 9.96985H8.8V8.36181H13.6V13.1859H12V10.995C11.2667 11.5846 10.6833 12.3116 10.25 13.1759C9.81667 14.0402 9.6 14.9816 9.6 16C9.6 17.6482 10.1367 19.072 11.21 20.2714C12.2833 21.4707 13.6133 22.1709 15.2 22.3719V24ZM18.4 23.6382V18.8141H20V21.005C20.7333 20.402 21.3167 19.6717 21.75 18.8141C22.1833 17.9564 22.4 17.0184 22.4 16C22.4 14.3518 21.8633 12.928 20.79 11.7286C19.7167 10.5293 18.3867 9.82915 16.8 9.62814V8C18.8267 8.20101 20.5333 9.05863 21.92 10.5729C23.3067 12.0871 24 13.8961 24 16C24 17.2194 23.7567 18.3451 23.27 19.3769C22.7833 20.4087 22.12 21.2931 21.28 22.0302H23.2V23.6382H18.4Z"
                      fill="#333333"
                    />
                  </svg>
                </button>
              </Col>
            </Row>
          </div>
        ) : (
          <>
            <div className="smileExample">
              <img className="img-originalPic" src={smilePic} ref={imgRef} />
            </div>
            <div className="photoInstructions">
              <button className="btn-next" onClick={uploadToBucket}>
                <svg
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_4061_1416"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="19"
                    height="19"
                  >
                    <rect width="19" height="19" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_4061_1416)">
                    <path
                      d="M8.70573 12.667V6.21491L6.6474 8.27324L5.53906 7.12533L9.4974 3.16699L13.4557 7.12533L12.3474 8.27324L10.2891 6.21491V12.667H8.70573ZM4.7474 15.8337C4.31198 15.8337 3.93924 15.6786 3.62917 15.3686C3.3191 15.0585 3.16406 14.6857 3.16406 14.2503V11.8753H4.7474V14.2503H14.2474V11.8753H15.8307V14.2503C15.8307 14.6857 15.6757 15.0585 15.3656 15.3686C15.0556 15.6786 14.6828 15.8337 14.2474 15.8337H4.7474Z"
                      fill="white"
                    />
                  </g>
                </svg>
                Upload
              </button>
            </div>
          </>
        )}
      </Modal>

      <Modal
        className="uploadPhoto-modal retake-modal"
        title="Caution"
        open={showRetake}
        footer={false}
        centered
        width="fit-content"
        closable={false}
      >
        <h4>
          Poor-quality photos may degrade simulation quality, hindering accurate
          teeth position and shape identification.
          <br />
          Are you sure you want to proceed?
        </h4>
        <div className="btn-box">
          <button
            className="btn-next btn-retake"
            onClick={() => {
              setShowRetake(false);
              setSmilePic(null);
              setSmilePicAsFile(null);
            }}
          >
            Retake
          </button>
          <button
            className="btn-next"
            onClick={() => {
              setOpenModal(true);
              setShowRetake(false);
            }}
          >
            Proceed
          </button>
        </div>
      </Modal>
    </div>
  );
}
