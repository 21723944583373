import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset } from "../../../api/firebase";
import "./SendEmail.scss";
import { Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function SendEmail() {
    const [email, setEmail] = useState("");
    const [isSend, setIsSend] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [sendError, setSendError] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return;
        if (user && user?.emailVerified) navigate("/dashboard");
        if (user && !user?.emailVerified) navigate("/emailVerification");
        if (error) navigate("/");
    }, [user, loading, error, navigate]);

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        }
    }, [seconds]);

    const send = async () => {
        setIsSending(true);
        await sendPasswordReset(email, setIsSend, setSendError);
        setIsSending(false);
        setSeconds(60);
    };

    return (
        <div className="reset">
            <Link to="/">
                <img
                    src={require("../../../assets/img/logo/logo_dentscape.webp")}
                    alt="Dentscape"
                    className="login-logo"
                />
            </Link>
            <div className="reset-container">
                {isSend ? (
                    <>
                        <h4>Password reset email sent successfully</h4>
                        <p className="send-text">
                            Please check your email inbox.
                            <br />
                            It might takes up to 10 minutes to receive the password reset email, and the email could be
                            in the spam folder.
                        </p>
                        {seconds > 0 ? (
                            <p style={{ color: "#FFFFFF" }}>
                                Haven't receive the email ? You can resend after {"("}
                                {seconds}
                                {")"}
                            </p>
                        ) : (
                            <button className="btn-send" onClick={send}>
                                {isSending ? <LoadingOutlined /> : "Resend"}
                            </button>
                        )}
                    </>
                ) : (
                    <>
                        <h4>Fogot your password?</h4>
                        <p style={{ margin: "20px 0" }}>We'll email you to reset your password.</p>
                        <Input
                            type="text"
                            className="input-textBox"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setSendError(null);
                            }}
                            placeholder="Email"
                            style={{ borderColor: sendError ? "#aa3e1c" : "" }}
                        />

                        {sendError && email === "" ? (
                            <p className="incorrect">Please enter your email</p>
                        ) : sendError === "There is no user record corresponding to the provided email." ? (
                            <p className="incorrect">Couldn't find your Preteeth AI account</p>
                        ) : (
                            sendError === "The email address is improperly formatted." && (
                                <p className="incorrect">The email address is improperly formatted</p>
                            )
                        )}
                        <button className="btn-send" onClick={send}>
                            {isSending ? <LoadingOutlined /> : "Send"}
                        </button>
                    </>
                )}
            </div>

            {isSend ? (
                <Link className="btn-signUp" to="/login" style={{ marginTop: "30px" }}>
                    Sign in with another account
                </Link>
            ) : (
                <div className="signUp-box">
                    <p>Don't have an account?</p>
                    <Link className="btn-signUp" to="/register">
                        Sign up
                    </Link>
                </div>
            )}
        </div>
    );
}
export default SendEmail;
