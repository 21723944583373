import { useEffect, useState } from "react";
import "./Hero.scss";
import { ungzip } from "pako";
import * as THREE from "three";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, OrthographicCamera } from "@react-three/drei";
import { Link } from "react-router-dom";

export default function Hero() {
    const [model, setModel] = useState(null);
    const [windowSize, setWindowSize] = useState("xxl");
    const modelCache = new Map();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1800) {
                setWindowSize("xxl");
            } else if (window.innerWidth > 1024) {
                setWindowSize("xl");
            } else if (window.innerWidth > 768) {
                setWindowSize("m");
            } else {
                setWindowSize("sm");
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const loadModel = async () => {
            if (modelCache.has("crown.glb.gz")) {
                return modelCache.get("crown.glb.gz");
            }

            const file = await fetch(require("../../../../assets/3D/crown.glb.gz"))
                .then((res) => res.arrayBuffer())
                .then((buf) => new Uint8Array(buf));
            const ungzippedArrayBuffer = ungzip(file);
            const blob = new Blob([ungzippedArrayBuffer]);
            const url = URL.createObjectURL(blob);

            return new Promise((resolve) => {
                const loader = new GLTFLoader();
                loader.load(url, (gltf) => {
                    let geometry = gltf.scene.children[0].geometry;
                    delete geometry.attributes.normal;
                    geometry = BufferGeometryUtils.mergeVertices(geometry);
                    geometry.computeVertexNormals();

                    const newModel = {
                        object: new THREE.Mesh(
                            geometry,
                            new THREE.MeshStandardMaterial({
                                color: "#c9c9c9",
                                metalness: 0.1,
                                side: THREE.DoubleSide,
                                roughness: 0.364,
                            })
                        ),
                    };

                    modelCache.set("crown.glb.gz", newModel);

                    // clean up
                    URL.revokeObjectURL(url);
                    resolve(newModel);
                });
            });
        };

        if (model === null) {
            loadModel().then((model) => {
                setModel(model);
            });
        }
    }, [model]);

    return (
        <div className="hero">
            <h1 className="title">Dentscape AI</h1>
            <h1 className="subtitle">Dental CAD Designer, Day and Night</h1>
            <div className="d-flex gap-3 gap-md-5">
                <Link className="btn-book-demo" to="bookADemo" data-aos="fade-up" data-aos-duration="750">
                    Book a Demo
                </Link>
                <Link className="btn-try-for-free" to="tryForFree" data-aos="fade-up" data-aos-duration="750">
                    Try for Free
                </Link>
            </div>

            <Canvas
                style={{
                    height: windowSize === "sm" ? "38.5vh" : windowSize === "m" ? "45vh" : "75vh",
                }}
            >
                <ambientLight color={"#000000"} />
                <directionalLight color="0xffffff" intensity={1} position={[0, 50, 0]} />
                <directionalLight color="0xffffff" intensity={2} position={[10, 0, 200]} />
                <directionalLight color="0xffffff" intensity={1} position={[-10, 0, -200]} />
                <OrthographicCamera
                    makeDefault
                    zoom={windowSize === "xxl" ? 50 : windowSize === "xl" ? 40 : windowSize === "m" ? 35 : 21}
                    position={[31.557, 0, -11.0665]}
                />
                <OrbitControls
                    target={[11.557, -5.93965, -11.0665]}
                    makeDefault
                    enableZoom={false}
                    enablePan={false}
                    minPolarAngle={Math.PI / 4}
                    maxPolarAngle={Math.PI / 4}
                />
                {model && <primitive object={model.object} />}
            </Canvas>
        </div>
    );
}
