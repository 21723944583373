import { useContext } from "react";
import { Link } from "react-router-dom";
import "./Billing.scss";
import { storeContext } from "../../../store";

export default function Billing() {
    const { preteethAIProPayment, isFreeTrial } = useContext(storeContext);

    const dateFormater = (date) => {
        return new Date(date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
        });
    };

    const renderSubscriptionStatus = (payment, product) => {
        if (payment.length === 0) {
            return product === "Preteeth AI Pro" && isFreeTrial
                ? "Free Trial"
                : "No Subscription";
        } else {
            if (payment[0].status === "active") {
                return payment[0].subscriptionType;
            } else {
                return payment[0].status;
            }
        }
    };

    const renderSubscriptionDetails = (payment) => {
        if (payment.length !== 0) {
            return (
                <>
                    <p>Start: {dateFormater(payment[0].startDate)}</p>
                    <p>
                        {payment[0].status !== "canceled" &&
                        !payment[0].cancelAtPeriodEnd
                            ? "Renews"
                            : "Expires"}
                        : {dateFormater(payment[0].currentPeriodEnd)}
                    </p>
                </>
            );
        }
    };

    return (
        <div className="billing">
            <div className="billing-content">
                <div className="text">
                    <h4 className="billing-title">Preteeth AI Pro</h4>
                    <p style={{ textTransform: "capitalize" }}>
                        Subscription:{" "}
                        {renderSubscriptionStatus(
                            preteethAIProPayment,
                            "Preteeth AI Pro"
                        )}
                    </p>
                    {renderSubscriptionDetails(preteethAIProPayment)}
                </div>
                <div className="info-btns">
                    {preteethAIProPayment.length === 0 && (
                        <Link to="/PreteethAIPro/Payment" className="btn-edit">
                            Payment
                        </Link>
                    )}
                    {preteethAIProPayment.length !== 0 &&
                        (preteethAIProPayment[0].status === "active" &&
                        preteethAIProPayment[0].cancelAtPeriodEnd === false ? (
                            <Link
                                to="/PreteethAIPro/Unsubscribe"
                                className="btn-unsubscribe"
                            >
                                Unsubscribe
                            </Link>
                        ) : (
                            <Link
                                to="/PreteethAIPro/Payment"
                                className="btn-edit"
                            >
                                Resubscribe
                            </Link>
                        ))}
                </div>
            </div>

            {/* <div className="billing-content">
        <div className="text">
          <h4 className="billing-title">Dental Assistant</h4>
          <p style={{ textTransform: "capitalize" }}>
            Subscription:{" "}
            {renderSubscriptionStatus(
              dentalAssistantPayment,
              "Dental Assistant"
            )}
          </p>
          {renderSubscriptionDetails(dentalAssistantPayment)}
        </div>
        <div className="info-btns">
          {dentalAssistantPayment.length === 0 && (
            <Link
              to="/DentalAssistant/Payment"
              className="btn-edit"
              style={{ marginRight: "0" }}
            >
              Payment
            </Link>
          )}
          {dentalAssistantPayment.length !== 0 &&
            (dentalAssistantPayment[0].status === "active" ? (
              <Link
                to="/DentalAssistant/Unsubscribe"
                className="btn-unsubscribe"
              >
                Unsubscribe
              </Link>
            ) : (
              <Link to="/DentalAssistant/Payment" className="btn-edit">
                Payment
              </Link>
            ))}
        </div>
      </div> */}
        </div>
    );
}
