import { Divider, Input, Space, notification } from "antd";
import { CheckoutBtn, findPromotionCode } from "../../../../api/payment";
import "./PriceUI.scss";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import PriceBox from "./PriceBox";

export const PreteethAIProBox = ({ uid, product, activeProduct, pricePlan }) => {
    const [api, contextHolder] = notification.useNotification();
    const [choosedPlan, setChoosedPlan] = useState("monthly");
    const [priceId, setPriceId] = useState(null);
    const [showPromoCodeInput, setShowPromoCodeInput] = useState(false);
    const [promocode, setPromocode] = useState(null);
    const [promocodeId, setPromocodeId] = useState(null);
    const [applyPromo, setApplyPromo] = useState(false);
    const [promocodeLoading, setPromocodeLoading] = useState(false);
    let MONTHLY_PRICE = pricePlan === "$9" || pricePlan === "low" ? 9 : 19;
    let ANNUAL_PRICE = pricePlan === "$9" || pricePlan === "low" ? 90 : 190;

    useEffect(() => {
        if (choosedPlan === "monthly") {
            setPriceId(
                pricePlan === "$9" || pricePlan === "low"
                    ? process.env.REACT_APP_PRICE_ID_9_MONTHLY
                    : process.env.REACT_APP_PRICE_ID_19_MONTHLY
            );
        } else {
            setPriceId(
                pricePlan === "$9" || pricePlan === "low"
                    ? process.env.REACT_APP_PRICE_ID_9_ANNUAL
                    : process.env.REACT_APP_PRICE_ID_19_ANNUAL
            );
        }
    }, [choosedPlan, pricePlan]);

    const checkPromoAvailable = async () => {
        setPromocodeLoading(true);
        const promoId = await findPromotionCode({ promoCode: promocode });

        if (
            promoId !== null &&
            promoId !== process.env.REACT_APP_PROMOCODE_33 &&
            promoId !== process.env.REACT_APP_PROMOCODE_50
        ) {
            setPromocodeId(promoId);
            setApplyPromo(true);
        } else {
            api.error({
                message: "Promo code is not available",
                description: "Please check your promo code again.",
                placement: "bottomRight",
            });
        }
        setPromocodeLoading(false);
    };

    return (
        <div className="price-container">
            <h2 className="title">Premium Plan</h2>

            <div className="price-boxes">
                <PriceBox
                    plan={"monthly"}
                    price={MONTHLY_PRICE}
                    activeProduct={activeProduct}
                    choosedPlan={choosedPlan}
                    setChoosedPlan={setChoosedPlan}
                />
                <PriceBox
                    plan={"annual"}
                    price={ANNUAL_PRICE}
                    activeProduct={activeProduct}
                    choosedPlan={choosedPlan}
                    setChoosedPlan={setChoosedPlan}
                />
            </div>

            {!showPromoCodeInput ? (
                <button className="btn-add-promo-code" onClick={() => setShowPromoCodeInput(!showPromoCodeInput)}>
                    <PlusIcon />
                    Add Promo Code
                </button>
            ) : (
                <Space.Compact className="promocode-box">
                    {contextHolder}
                    <Input
                        className="input-promo-code"
                        placeholder="Enter Promo Code"
                        value={promocode}
                        onChange={(value) => setPromocode(value.target.value.toUpperCase())}
                        disabled={applyPromo}
                        style={{ borderRadius: "100px" }}
                    />
                    {applyPromo ? (
                        <button
                            className="btn-cancel"
                            onClick={() => {
                                setApplyPromo(false);
                                setPromocode(null);
                            }}
                        >
                            <CancelIcon />
                        </button>
                    ) : (
                        <button
                            className="btn-apply"
                            onClick={checkPromoAvailable}
                            disabled={promocodeLoading || !promocode}
                        >
                            {promocodeLoading ? <LoadingOutlined /> : "Apply"}
                        </button>
                    )}
                </Space.Compact>
            )}

            <Divider className="price-divider" />

            <div className="guarantee-box">
                <p className="text-guarantee">30-day money-back guarantee</p>
                <p className="text-learn-more">
                    Learn more about cancellation and refund policy in{" "}
                    <a href="/termsofuse" target="_blank">
                        Term of Use.
                    </a>
                </p>
            </div>

            <CheckoutBtn
                uid={uid}
                product={product}
                type={choosedPlan}
                priceId={priceId}
                promocode={promocodeId}
                activeProduct={activeProduct}
            />
        </div>
    );
};

const PlusIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 11V15C9 15.2833 9.09583 15.5208 9.2875 15.7125C9.47917 15.9042 9.71667 16 10 16C10.2833 16 10.5208 15.9042 10.7125 15.7125C10.9042 15.5208 11 15.2833 11 15V11H15C15.2833 11 15.5208 10.9042 15.7125 10.7125C15.9042 10.5208 16 10.2833 16 10C16 9.71667 15.9042 9.47917 15.7125 9.2875C15.5208 9.09583 15.2833 9 15 9H12.5H11V5C11 4.71667 10.9042 4.47917 10.7125 4.2875C10.5208 4.09583 10.2833 4 10 4C9.71667 4 9.47917 4.09583 9.2875 4.2875C9.09583 4.47917 9 4.71667 9 5V9H5C4.71667 9 4.47917 9.09583 4.2875 9.2875C4.09583 9.47917 4 9.71667 4 10C4 10.2833 4.09583 10.5208 4.2875 10.7125C4.47917 10.9042 4.71667 11 5 11H9ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z"
            fill="#E4E4E4"
        />
    </svg>
);

const CancelIcon = () => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
            id="mask0_5547_10311"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_5547_10311)">
            <path
                d="M12 13.4L14.9 16.3C15.0833 16.4833 15.3167 16.575 15.6 16.575C15.8833 16.575 16.1167 16.4833 16.3 16.3C16.4833 16.1167 16.575 15.8833 16.575 15.6C16.575 15.3167 16.4833 15.0833 16.3 14.9L13.4 12L16.3 9.1C16.4833 8.91667 16.575 8.68333 16.575 8.4C16.575 8.11667 16.4833 7.88333 16.3 7.7C16.1167 7.51667 15.8833 7.425 15.6 7.425C15.3167 7.425 15.0833 7.51667 14.9 7.7L12 10.6L9.1 7.7C8.91667 7.51667 8.68333 7.425 8.4 7.425C8.11667 7.425 7.88333 7.51667 7.7 7.7C7.51667 7.88333 7.425 8.11667 7.425 8.4C7.425 8.68333 7.51667 8.91667 7.7 9.1L10.6 12L7.7 14.9C7.51667 15.0833 7.425 15.3167 7.425 15.6C7.425 15.8833 7.51667 16.1167 7.7 16.3C7.88333 16.4833 8.11667 16.575 8.4 16.575C8.68333 16.575 8.91667 16.4833 9.1 16.3L12 13.4ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"
                fill="#E4E4E4"
            />
        </g>
    </svg>
);
