import Chat from "./Chat";
import SmileCareContext from "./store";
import "./style.css";

export default function SmileCare() {
  return (
    <SmileCareContext>
      <div id="smile-care">
        <h2 data-aos="zoom-in">Take the first step to care your Smile</h2>
        <h4 className="subtitle" data-aos="zoom-in" data-aos-delay="200">
          SmileCare is intended for informational purposes only.<br></br>Please
          refrain from taking any actions without the validation or consultation
          of a qualified dentist.
        </h4>
        <Chat />
      </div>
    </SmileCareContext>
  );
}
