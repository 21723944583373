import React from "react";
import { Card } from "antd";

export default function AnalysisCard({
  imgSrc,
  analysisData,
  onClick,
  className,
  imgResultSrc,
  imgResultAlt,
  middleIncisorsAnalysisImg,
  style,
}) {
  return (
    <Card
      hoverable
      className={`analysis-content-box ${className}`}
      onClick={onClick}
    >
      <div className="analysis-imgs">
        <img src={imgSrc} alt="Original photo" />
        {imgResultSrc && (
          <img
            className="img-result"
            src={imgResultSrc}
            alt={imgResultAlt}
            style={style}
          />
        )}
        {middleIncisorsAnalysisImg !== "" && (
          <img
            className="img-result"
            src={middleIncisorsAnalysisImg}
            alt="Gum and upper analysis"
          />
        )}
      </div>
      <p>{analysisData}</p>
    </Card>
  );
}
