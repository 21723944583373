import { lazy, Suspense } from "react";
import Navbar from "../../components/Dentist/Navbar/Navbar";
import API from "../../components/Dentist/API/API";

const Footer = lazy(() =>
    import(
        /* webpackChunkName: "footer" */ "../../components/Dentist/Footer/Footer"
    )
);

export default function APIPage() {
    return (
        <div id="api">
            <Navbar />
            <API />
            <Suspense
                fallback={
                    <h3
                        style={{
                            color: "#00C8F0",
                            margin: "50px 0",
                            textAlign: "center",
                        }}
                    >
                        Loading...
                    </h3>
                }
            >
                <Footer colorScheme="light" />
            </Suspense>
        </div>
    );
}
