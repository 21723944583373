import { lazy, Suspense, useState } from "react";
import Navbar from "../../components/Patient/Navbar/Navbar";
import Hero from "../../components/Hero/Hero";
import "./Patient.css";
import WelcomeModal from "../../components/CustomModal/WelcomeModal";
import AOS from "aos";
import "aos/dist/aos.css";
import "animate.css";
import SmileCare from "../../components/Patient/SmileCare";
import { Link as Linkto } from "react-scroll";

const Features = lazy(() =>
    import(
        /* webpackChunkName: "features" */ "../../components/Patient/Features/Features"
    )
);
const Introduction = lazy(() =>
    import(
        /* webpackChunkName: "introduction" */ "../../components/Patient/Introduction/Introduction"
    )
);
const FAQ = lazy(() =>
    import(/* webpackChunkName: "FAQ" */ "../../components/Patient/FAQ/FAQ")
);
const Footer = lazy(() =>
    import(
        /* webpackChunkName: "footer" */ "../../components/Patient/Footer/Footer"
    )
);

AOS.init();

export default function Patient() {
    const [faq, setFaq] = useState(-1);
    // const [open, setOpen] = useState(false);
    // const [name, setName] = useState("");
    // const [email, setEmail] = useState("");
    // const [warning, setWarning] = useState(false);
    // const [error, setError] = useState(null);

    // const handleClick = () => {
    //   if (name === "" || email === "") setWarning(true);
    //   else {
    //     setWarning(false);
    //     setOpen(true);
    //   }
    // };

    // const isValidEmail = (address) => {
    //   return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    //     address
    //   );
    // };

    // const handleChange = (e) => {
    //   if (!isValidEmail(e.target.value)) {
    //     setError("Email is invalid");
    //   } else {
    //     setError(null);
    //   }
    // };

    // useEffect(() => {
    //   if (name !== "" || email !== "") setWarning(false);
    // }, [name, email]);

    // const scrollToTop = () => {
    //     window.scrollTo(0, 0);
    // }

    return (
        <div id="patientPage">
            {/* <WelcomeModal type="patient" /> */}
            <Navbar />
            <Hero type="patient" />
            <Suspense
                fallback={
                    <h3
                        style={{
                            color: "#00C8F0",
                            margin: "50px 0",
                            textAlign: "center",
                        }}
                    >
                        Loading...
                    </h3>
                }
            >
                <div id="howItWorks"></div>
                <Introduction />
                <SmileCare />
                <Features />

                <div className="data-container">
                    <svg
                        viewBox="0 0 90 90"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="45"
                            cy="45"
                            r="45"
                            fill="url(#paint0_linear_3819_3117)"
                        />
                        <mask
                            id="mask0_3819_3117"
                            style={{ maskType: "alpha" }}
                            maskUnits="userSpaceOnUse"
                            x="13"
                            y="13"
                            width="64"
                            height="64"
                        >
                            <rect
                                x="13"
                                y="13"
                                width="64"
                                height="64"
                                fill="#D9D9D9"
                            />
                        </mask>
                        <g mask="url(#mask0_3819_3117)">
                            <path
                                d="M40.9974 54.333H48.9974V47.6663H55.6641V39.6663H48.9974V32.9997H40.9974V39.6663H34.3307V47.6663H40.9974V54.333ZM44.9974 71.6663C38.8196 70.1108 33.7196 66.5663 29.6974 61.033C25.6752 55.4997 23.6641 49.3552 23.6641 42.5997V26.333L44.9974 18.333L66.3307 26.333V42.5997C66.3307 49.3552 64.3196 55.4997 60.2974 61.033C56.2752 66.5663 51.1752 70.1108 44.9974 71.6663Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <linearGradient
                                id="paint0_linear_3819_3117"
                                x1="71"
                                y1="8"
                                x2="21"
                                y2="83.5"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#4BD7F3" />
                                <stop
                                    offset="1"
                                    stopColor="#4BD7F3"
                                    stopOpacity="0.3"
                                />
                            </linearGradient>
                        </defs>
                    </svg>

                    <h2>Your data is safe with us</h2>
                    <h4>
                        We use advanced encryption and security protocols,
                        complying with HIPAA and GDPR standards to ensure utmost
                        protection and privacy.
                    </h4>
                </div>
            </Suspense>

            <div id="faq" className="faq-container">
                <Suspense
                    fallback={
                        <h3
                            style={{
                                color: "#00C8F0",
                                margin: "50px 0",
                                textAlign: "center",
                            }}
                        >
                            Loading...
                        </h3>
                    }
                >
                    <FAQ faq={faq} setFaq={setFaq} />
                </Suspense>
            </div>

            <div className="cta-container">
                <h2>
                    Experience highly personalized, state-of-the-art oral care
                </h2>
                <Linkto
                    className="btn-meetAIAssistant"
                    to="smile-care"
                    duration={450}
                    smooth={true}
                >
                    Meet your AI Assistant
                </Linkto>
            </div>

            <Suspense
                fallback={
                    <h3
                        style={{
                            color: "#00C8F0",
                            margin: "50px 0",
                            textAlign: "center",
                        }}
                    >
                        Loading...
                    </h3>
                }
            >
                <Footer type="patient" />
            </Suspense>
        </div>
    );
}
