import { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import "./AccountSetting.scss";
import { auth, db, setRegion } from "../../../api/firebase";
import {
    updateDoc,
    doc,
    deleteField,
    getDoc,
    setDoc,
} from "firebase/firestore";
import { Form, Input, Modal, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { storeContext } from "../../../store";
import { searchPeople, updatePerson } from "../../../api/pipedrive";

export default function AccountSetting() {
    const { name, firstName, lastName, type, country, city, phone } =
        useContext(storeContext);
    const [user] = useAuthState(auth);
    const [tempFirstName, setTempFirstName] = useState("");
    const [tempLastName, setTempLastName] = useState("");
    const [tempType, setTempType] = useState("");
    const [tempCountry, setTempCountry] = useState("");
    const [tempCity, setTempCity] = useState("");
    const [tempPhone, setTempPhone] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updating, setUpdating] = useState(false);
    const userDocRef = doc(db, "user_datas", user?.uid);

    let dataInit = false;
    useEffect(() => {
        if (!dataInit) {
            setTempFirstName(firstName);
            setTempLastName(lastName);
            setTempType(type);
            setTempCountry(country);
            setTempCity(city);
            setTempPhone(phone);

            dataInit = true;
        }
    }, [firstName, lastName, type, country, city, phone]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "firstName") setTempFirstName(value);
        if (name === "lastName") setTempLastName(value);
    };

    const upload = async () => {
        setUpdating(true);
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            const updates = {};

            if (tempFirstName !== data.firstName)
                updates.firstName = tempFirstName;
            if (tempLastName !== data.lastName) updates.lastName = tempLastName;
            if (tempFirstName !== "" && tempLastName !== "" && data.name !== "")
                updates.name = deleteField();
            if (tempType !== data.type) updates.type = tempType;
            if (tempCountry !== data.country) updates.country = tempCountry;
            if (tempCity !== data.city) updates.city = tempCity;
            if (tempPhone !== data.phoneNumber) updates.phoneNumber = tempPhone;

            if (Object.keys(updates).length > 0) {
                updateDoc(userDocRef, updates);
            }
        } else {
            const { region } = await setRegion();

            setDoc(userDocRef, {
                uid: user.uid,
                firstName: tempFirstName ? tempFirstName : firstName,
                lastName: tempLastName ? tempLastName : lastName,
                type: tempType ? tempType : type,
                email: user.email,
                country: tempCountry ? tempCountry : country,
                city: tempCity ? tempCity : city,
                region,
                phoneNumber: tempPhone ? tempPhone : phone,
                status: "Verified",
            });
        }

        const personId = (await searchPeople(user?.email)).id;
        await updatePerson({
            id: personId,
            email: user?.email,
            firstName: tempFirstName ? tempFirstName : firstName,
            lastName: tempLastName ? tempLastName : lastName,
            type: tempType ? tempType : type,
            phoneNumber: tempPhone ? tempPhone : phone,
            country: tempCountry ? tempCountry : country,
            city: tempCity ? tempCity : city,
        });

        setUpdating(false);
        setIsModalVisible(true);
    };

    const success = () => {
        setIsModalVisible(false);
        window.location.reload();
    };

    return (
        <div className="editProfile">
            <Form>
                <div className="editComponent">
                    <p className="info">Name</p>
                    <Form.Item className="name">
                        <Input
                            type="text"
                            className="input-textBox"
                            defaultValue={firstName}
                            value={tempFirstName}
                            onChange={handleInputChange}
                            name="firstName"
                            placeholder={name ? name : "First Name"}
                        />
                        <Input
                            type="text"
                            className="input-textBox"
                            defaultValue={lastName}
                            value={tempLastName}
                            onChange={handleInputChange}
                            name="lastName"
                            placeholder="Last Name"
                        />
                    </Form.Item>
                </div>

                <div className="editComponent">
                    <p className="info">Email</p>
                    <p className="info" style={{ color: "#83E4FC" }}>
                        {user?.email}
                    </p>
                </div>

                <div className="editComponent">
                    <p className="info">Password</p>
                    <Link to="/changePassword">
                        <p className="btn-changePass">Change password</p>
                    </Link>
                </div>

                <div className="editComponent">
                    <p className="info">Type</p>
                    <Form.Item>
                        <Select
                            className="input-textBox"
                            defaultValue={type}
                            value={tempType}
                            onChange={(value) => setTempType(value)}
                        >
                            <Select.Option value="Dentist">
                                Dentist
                            </Select.Option>
                            <Select.Option value="Dental Technician">
                                Dental Technician
                            </Select.Option>
                            <Select.Option value="Patient">
                                Patient
                            </Select.Option>
                            <Select.Option value="Work in dental industry">
                                Work in dental industry
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </div>

                <div className="editComponent">
                    <p className="info">Region</p>
                    <Form.Item className="name">
                        <CountryDropdown
                            value={tempCountry}
                            valueType="short"
                            onChange={(value) => setTempCountry(value)}
                            classes="input-textBox"
                        />
                        <RegionDropdown
                            country={tempCountry}
                            value={tempCity}
                            countryValueType="short"
                            onChange={(value) => setTempCity(value)}
                            classes="input-textBox"
                        />
                    </Form.Item>
                </div>

                <div className="editComponent">
                    <p className="info">Phone</p>
                    <Form.Item>
                        <PhoneInput
                            international
                            className="phone-input"
                            defaultCountry={tempCountry}
                            value={tempPhone}
                            onChange={setTempPhone}
                            placeholder="Phone Number"
                            error={
                                tempPhone
                                    ? isValidPhoneNumber(tempPhone)
                                        ? undefined
                                        : "Invalid phone number"
                                    : "Phone number required"
                            }
                        />
                    </Form.Item>
                </div>
            </Form>

            <button className="btn-save" onClick={upload}>
                {updating ? <LoadingOutlined /> : "Save"}
            </button>
            <div className="dashboard-delete">
                <Link className="btn-delete" to="/delete">
                    I want to delete account
                </Link>
            </div>

            <Modal
                open={isModalVisible}
                footer={
                    <button className="btn-ok" onClick={success}>
                        OK
                    </button>
                }
            >
                <p>Success!</p>
            </Modal>
        </div>
    );
}
