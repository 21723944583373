import "./Terms.scss";

export default function TermsPage({ type }) {
    const PreteethAIPro = () => (
        <>
            <h2>TERMS OF USE</h2>
            <p style={{ color: "#595959" }}>Last updated May 06, 2024</p>

            <p>
                We are Preteeth AI Co., Ltd. ("Company," "we," "us," "our"), a company registered in Taiwan at:
                <br />
                <br />
                11F., No. 87, Songjiang Rd., Zhongshan Dist., Taipei City. Our VAT number is 90833308.
                <br />
                <br />
                We operate the mobile applications Preteeth AI Pro and Dentscape (collectively referred to as the
                "Apps"), as well as any other related products and services that refer or link to these legal terms (the
                "Legal Terms") (collectively, the "Services").
                <br />
                <br />
                You can contact us by email at{" "}
                <a
                    href="mailto:info@dentscape.ai"
                    className="__cf_email__"
                    data-cfemail="47342235312e242207373522332222332f6924282a"
                >
                    info@dentscape.ai
                </a>{" "}
                or by mail to 11F., No. 87, Songjiang Rd., Zhongshan Dist., Taipei City, Taiwan.
            </p>
            <p>
                These Legal Terms constitute a legally binding agreement made between you, whether personally or on
                behalf of an entity ("you"), and Preteeth AI Co., Ltd., concerning your access to and use of the
                Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by
                all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY
                PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
            </p>
            <p>
                We will provide you with prior notice of any scheduled changes to the Services you are using. The
                modified Legal Terms will become effective upon posting or notifying you by email, as stated in the
                email message. By continuing to use the Services after the effective date of any changes, you agree to
                be bound by the modified terms.
            </p>
            <p>
                The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not
                permitted to use or register for the Services.
                <br />
                We recommend that you print a copy of these Legal Terms for your records.
            </p>

            <h3>TABLE OF CONTENTS</h3>
            <ol>
                <a href="#services">
                    <li>OUR SERVICES</li>
                </a>
                <a href="#intellectual">
                    <li>INTELLECTUAL PROPERTY RIGHTS</li>
                </a>
                <a href="#representations">
                    <li>USER REPRESENTATIONS</li>
                </a>
                <a href="#registration">
                    <li>USER REGISTRATION</li>
                </a>
                <a href="#purchase">
                    <li>PURCHASES AND PAYMENT</li>
                </a>
                <a href="#subscriptions">
                    <li>SUBSCRIPTIONS AND PAYMENTS</li>
                </a>
                <a href="#software">
                    <li>SOFTWARE</li>
                </a>
                <a href="#prohibited">
                    <li>PROHIBITED ACTIVITIES</li>
                </a>
                <a href="#userGenerated">
                    <li>USER GENERATED CONTRIBUTIONS</li>
                </a>
                <a href="#contribution">
                    <li>CONTRIBUTION LICENSE</li>
                </a>
                <a href="#mobileApplication">
                    <li>MOBILE APPLICATION LICENSE</li>
                </a>
                <a href="#servicesManagement">
                    <li>SERVICES MANAGEMENT</li>
                </a>
                <a href="#privacyPolicy">
                    <li>PRIVACY POLICY</li>
                </a>
                <a href="#termTermination">
                    <li>TERM AND TERMINATION</li>
                </a>
                <a href="#modification">
                    <li>MODIFICATIONS AND INTERRUPTIONS</li>
                </a>
                <a href="#governingLaw">
                    <li>GOVERNING LAW</li>
                </a>
                <a href="#disputeResolution">
                    <li>DISPUTE RESOLUTION</li>
                </a>
                <a href="#corrections">
                    <li>CORRECTIONS</li>
                </a>
                <a href="#disclaimer">
                    <li>DISCLAIMER</li>
                </a>
                <a href="#limitationLiability">
                    <li>LIMITATIONS OF LIABILITY</li>
                </a>
                <a href="#indemnification">
                    <li>INDEMNIFICATION</li>
                </a>
                <a href="#userData">
                    <li>USER DATA</li>
                </a>
                <a href="#electronicCommunications">
                    <li>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</li>
                </a>
                <a href="#californiaUsers">
                    <li>CALIFORNIA USERS AND RESIDENTS</li>
                </a>
                <a href="#miscellaneous">
                    <li>MISCELLANEOUS</li>
                </a>
                <a href="#contact">
                    <li>CONTACT US</li>
                </a>
            </ol>

            <h3 id="services">1. OUR SERVICES</h3>
            <p>
                The information provided when using the Services is not intended for distribution to or use by any
                person or entity in any jurisdiction or country where such distribution or use would be contrary to law
                or regulation or which would subject us to any registration requirement within such jurisdiction or
                country. Accordingly, those persons who choose to access the Services from other locations do so on
                their own initiative and are solely responsible for compliance with local laws, if and to the extent
                local laws are applicable.
            </p>
            <p>
                Our Services are designed to comply with the General Data Protection Regulation (GDPR) for our users in
                the European Union. We have implemented measures to ensure that your personal data is processed in
                compliance with GDPR, including data protection by design and by default, and we offer rights to access,
                correct, delete, and restrict the processing of your data.
            </p>

            <h3 id="intellectual">2. INTELLECTUAL PROPERTY RIGHTS</h3>
            <p>2.1 Our intellectual property</p>
            <p>
                We are the owner or the licensee of all intellectual property rights in our Services, including all
                source code, databases, functionality, software, website designs, audio, video, text, photographs, and
                graphics in the Services (collectively, the "Content"), as well as the trademarks, service marks, and
                logos contained therein (the "Marks").
            </p>
            <p>
                Our Content and Marks are protected by copyright and trademark laws (and various other intellectual
                property rights and unfair competition laws) and treaties in Taiwan and around the world.
            </p>
            <p>
                The Content and Marks are provided in or through the Services "AS IS" for your internal business purpose
                only.
            </p>
            <p>2.2 Your use of our Services</p>
            <p>
                Subject to your compliance with these Legal Terms, including the "PROHIBITED ACTIVITIES" section below,
                we grant you a non-exclusive, non-transferable, revocable license to:
            </p>
            <ol>
                <li>access the Services; and</li>
                <li>
                    download or print a copy of any portion of the Content to which you have properly gained access,
                    solely for your internal business purpose.
                </li>
            </ol>
            <p>
                Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no
                Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly
                displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any
                commercial purpose whatsoever, without our express prior written permission.
            </p>
            <p>
                If you wish to make any use of the Services, Content, or Marks other than as set out in this section or
                elsewhere in our Legal Terms, please address your request to: info@dentscape.ai. If we ever grant you
                permission to post, reproduce, or publicly display any part of our Services or Content, you must
                identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright
                or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
            </p>
            <p>We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.</p>
            <p>
                Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms
                and your right to use our Services will terminate immediately.
            </p>
            <p>2.3 Your submissions</p>
            <p>
                Please review this section and the "PROHIBITED ACTIVITIES" section carefully prior to using our Services
                to understand the (a) rights you give us and (b) obligations you have when you post or upload any
                content through the Services.
            </p>
            <p>
                Submissions: By directly sending us any question, comment, suggestion, idea, feedback, or other
                information about the Services ("Submissions"), you agree to assign to us all intellectual property
                rights in such Submission. You agree that we shall own this Submission and be entitled to its
                unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without
                acknowledgment or compensation to you.
            </p>
            <p>
                You are responsible for what you post or upload: By sending us Submissions through any part of the
                Services you:
            </p>
            <ol>
                <li>
                    confirm that you have read and agree with our "PROHIBITED ACTIVITIES" and will not post, send,
                    publish, upload, or transmit through the Services any Submission that is illegal, harassing,
                    hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person
                    or group, sexually explicit, false, inaccurate, deceitful, or misleading;
                </li>
                <li>
                    to the extent permissible by applicable law, waive any and all moral rights to any such Submission;
                </li>
                <li>
                    warrant that any such Submission is original to you or that you have the necessary rights and
                    licenses to submit such Submissions and that you have full authority to grant us the above-mentioned
                    rights in relation to your Submissions; and
                </li>
                <li>warrant and represent that your Submissions do not constitute confidential information.</li>
            </ol>
            <p>
                You are solely responsible for your Submissions and you expressly agree to reimburse us for any and all
                losses that we may suffer because of your breach of (a) this section, (b) any third party’s intellectual
                property rights, or (c) applicable law.
            </p>

            <h3 id="representations">3. USER REPRESENTATIONS</h3>
            <p>By using the Services, you represent and warrant that:</p>
            <ol>
                <li>all registration information you submit will be true, accurate, current, and complete;</li>
                <li>
                    you will maintain the accuracy of such information and promptly update such registration information
                    as necessary;
                </li>
                <li>you have the legal capacity and you agree to comply with these Legal Terms;</li>
                <li>you are not a minor in the jurisdiction in which you reside;</li>
                <li>
                    you will not access the Services through automated or non-human means, whether through a bot,
                    script, or otherwise;
                </li>
                <li>you will not use the Services for any illegal or unauthorized purpose;</li>
                <li>your use of the Services will not violate any applicable law or regulation; and</li>
                <li>
                    you acknowledge that any simulated images or restoration designs generated by the Services are for
                    reference purposes only, and the final decision on their adjustment and use shall be made by dental
                    professionals.
                </li>
            </ol>
            <p>
                If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right
                to suspend or terminate your account and refuse any and all current or future use of the Services (or
                any portion thereof).
            </p>

            <h3 id="registration">4. USER REGISTRATION</h3>
            <p>
                You may be required to register to use the Services. You agree to keep your password confidential and
                will be responsible for all use of your account and password. We reserve the right to remove, reclaim,
                or change a username you select if we determine, in our sole discretion, that such username is
                inappropriate, obscene, or otherwise objectionable.
            </p>

            <h3 id="purchase">5. PURCHASES AND PAYMENT</h3>
            <h4>Preteeth AI Pro</h4>
            <ul>
                <li>
                    Billing and Payment: The subscription for Preteeth AI Pro is based on a monthly or yearly plan.
                    Users authorize us to charge their payment method on a recurring basis without requiring prior
                    approval for each recurring charge, until such time as the applicable subscription is canceled.
                </li>
                <li>
                    Pricing: The subscription fee is charged at the beginning of each billing cycle and is
                    non-refundable.
                </li>
                <li>
                    Payment Methods: We accept the following forms of payment:
                    <ul>
                        <li>American Express</li>
                        <li>Diners Club</li>
                        <li>Mastercard</li>
                        <li>Visa</li>
                    </ul>
                </li>
            </ul>
            <h4>Dentscape</h4>
            <ul>
                <li>
                    Billing and Payment: The usage of Dentscape is based on a credit system. Users purchase credits to
                    use the Services.
                </li>
                <li>Pricing: The cost of credits varies based on the selected package and is non-refundable.</li>
                <li>
                    Payment Methods: We accept the following forms of payment:
                    <ul>
                        <li>American Express</li>
                        <li>Diners Club</li>
                        <li>Mastercard</li>
                        <li>Visa</li>
                    </ul>
                </li>
            </ul>

            <h3 id="subscriptions">6. SUBSCRIPTIONS AND PAYMENTS</h3>
            <h4>Preteeth AI Pro</h4>
            <ul>
                <li>
                    Trial: Upon registering an account and starting to use the Services, new users will immediately
                    receive a one-week free trial. For subscriptions purchased via the App Store or Google Play, a
                    one-week free trial is available for monthly subscriptions, and a two-week free trial is available
                    for annual subscriptions. After the trial period expires, if the subscription is not canceled at
                    least 24 hours before the end of the trial, it will automatically renew.
                </li>
                <li>
                    Billing and Renewal: Your subscription will continue and automatically renew unless canceled. By
                    subscribing to the Services, you authorize us to charge your payment method on a recurring basis
                    without requiring prior approval for each recurring charge, until such time as the applicable
                    subscription is canceled. The length of your billing cycle will depend on the type of subscription
                    plan you selected when subscribing to the Services. To avoid automatic renewal, you must cancel your
                    subscription at least 24 hours before the end of the current trial or subscription period.
                </li>
                <li>
                    Cancellation: All purchases are non-refundable. Subscriptions purchased via the App Store or Google
                    Play must be canceled through the respective platform. Users who subscribed via our website can
                    cancel their subscription within the app. The cancellation will take effect at the end of the
                    current paid term. If you cancel less than 24 hours before the end of your current term, the
                    cancellation will not take effect until the following billing cycle. If you have any questions or
                    are unsatisfied with our Services, please contact us at{" "}
                    <a href="mailto:info@dentscape.ai">info@dentscape.ai</a>.
                </li>
            </ul>
            <h4>Dentscape</h4>
            <ul>
                <li>
                    Trial: We offer new users one 20 free credits to use the Services upon registration. Upon exhausting
                    the free trial opportunity, users must purchase credits to continue using the Services.
                </li>
                <li>
                    Billing and Renewal: Users purchase credits as needed to use the Services. Credits do not
                    automatically renew, and users must manually purchase additional credits to continue using the
                    Services.
                </li>
                <li>
                    Cancellation: All purchases are non-refundable. Credits are non-refundable. If you have any
                    questions or are unsatisfied with our Services, please contact us at{" "}
                    <a href="mailto:info@dentscape.ai">info@dentscape.ai</a>.
                </li>
            </ul>

            <h3 id="software">7. SOFTWARE</h3>
            <p>
                We may include software for use in connection with our Services. If such software is accompanied by an
                end user license agreement ("EULA"), the terms of the EULA will govern your use of the software. If such
                software is not accompanied by a EULA, then we grant to you a non-exclusive, revocable, personal, and
                non-transferable license to use such software solely in connection with our services and in accordance
                with these Legal Terms. Any software and any related documentation is provided "AS IS" without warranty
                of any kind, either express or implied, including, without limitation, the implied warranties of
                merchantability, fitness for a particular purpose, or non-infringement. You accept any and all risk
                arising out of use or performance of any software. You may not reproduce or redistribute any software
                except in accordance with the EULA or these Legal Terms.
            </p>

            <h3 id="prohibited">8. PROHIBITED ACTIVITIES</h3>
            <p>
                You may not access or use the Services for any purpose other than that for which we make the Services
                available. The Services may not be used in connection with any commercial endeavors except those that
                are specifically endorsed or approved by us.
            </p>
            <p>As a user of the Services, you agree not to:</p>
            <ol>
                <li>
                    Systematically retrieve data or other content from the Services to create or compile, directly or
                    indirectly, a collection, compilation, database, or directory without written permission from us.
                </li>
                <li>
                    Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account
                    information such as user passwords.
                </li>
                <li>
                    Circumvent, disable, or otherwise interfere with security-related features of the Services,
                    including features that prevent or restrict the use or copying of any Content or enforce limitations
                    on the use of the Services and/or the Content contained therein.
                </li>
                <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
                <li>
                    Use any information obtained from the Services in order to harass, abuse, or harm another person.
                </li>
                <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
                <li>Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
                <li>Engage in unauthorized framing of or linking to the Services.</li>
                <li>
                    Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material,
                    including excessive use of capital letters and spamming (continuous posting of repetitive text),
                    that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies,
                    impairs, disrupts, alters, or interferes with the use, features, functions, operation, or
                    maintenance of the Services.
                </li>
                <li>
                    Engage in any automated use of the system, such as using scripts to send comments or messages, or
                    using any data mining, robots, or similar data gathering and extraction tools.
                </li>
                <li>Delete the copyright or other proprietary rights notice from any Content.</li>
                <li>Attempt to impersonate another user or person or use the username of another user.</li>
                <li>
                    Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or
                    active information collection or transmission mechanism, including without limitation, clear
                    graphics interchange formats ("gifs"), 1×1 pixels, web bugs, cookies, or other similar devices
                    (sometimes referred to as "spyware" or "passive collection mechanisms" or "pcms").
                </li>
                <li>
                    Interfere with, disrupt, or create an undue burden on the Services or the networks or services
                    connected to the Services.
                </li>
                <li>
                    Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any
                    portion of the Services to you.
                </li>
                <li>
                    Attempt to bypass any measures of the Services designed to prevent or restrict access to the
                    Services, or any portion of the Services.
                </li>
                <li>
                    Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or
                    other code.
                </li>
                <li>
                    Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of
                    the software comprising or in any way making up a part of the Services.
                </li>
                <li>
                    Except as may be the result of standard search engine or Internet browser usage, use, launch,
                    develop, or distribute any automated system, including without limitation, any spider, robot, cheat
                    utility, scraper, or offline reader that accesses the Services, or use or launch any unauthorized
                    script or other software.
                </li>
                <li>
                    Make any unauthorized use of the Services, including collecting usernames and/or email addresses of
                    users by electronic or other means for the purpose of sending unsolicited email, or creating user
                    accounts by automated means or under false pretenses.
                </li>
                <li>
                    Use the Services as part of any effort to compete with us or otherwise use the Services and/or the
                    Content for any revenue-generating endeavor or commercial enterprise.
                </li>
                <li>Sell or otherwise transfer your profile.</li>
            </ol>

            <h3 id="userGenerated">9. USER GENERATED CONTRIBUTIONS</h3>
            <p>
                The Services does not offer users to submit or post content. We may provide you with the opportunity to
                create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and
                materials to us or on the Services, including but not limited to text, writings, video, audio,
                photographs, graphics, comments, suggestions, or personal information or other material (collectively,
                "Contributions"). Contributions may be viewable by other users of the Services and through third-party
                websites. As such, any Contributions you transmit may be treated in accordance with the Services'
                Privacy Policy. When you create or make available any Contributions, you thereby represent and warrant
                that:
            </p>
            <ol>
                <li>
                    The creation, distribution, transmission, public display, or performance, and the accessing,
                    downloading, or copying of your Contributions do not and will not infringe the proprietary rights,
                    including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any
                    third party.
                </li>
                <li>
                    You are the creator and owner of or have the necessary licenses, rights, consents, releases, and
                    permissions to use and to authorize us, the Services, and other users of the Services to use your
                    Contributions in any manner contemplated by the Services and these Legal Terms.
                </li>
                <li>
                    You have the written consent, release, and/or permission of each and every identifiable individual
                    person in your Contributions to use the name or likeness of each and every such identifiable
                    individual person to enable inclusion and use of your Contributions in any manner contemplated by
                    the Services and these Legal Terms.
                </li>
                <li>Your Contributions are not false, inaccurate, or misleading.</li>
                <li>
                    Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid
                    schemes, chain letters, spam, mass mailings, or other forms of solicitation.
                </li>
                <li>
                    Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous,
                    slanderous, or otherwise objectionable (as determined by us).
                </li>
                <li>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
                <li>
                    Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other
                    person and to promote violence against a specific person or class of people.
                </li>
                <li>Your Contributions do not violate any applicable law, regulation, or rule.</li>
                <li>Your Contributions do not violate the privacy or publicity rights of any third party.</li>
                <li>
                    Your Contributions do not violate any applicable law concerning child pornography, or otherwise
                    intended to protect the health or well-being of minors.
                </li>
                <li>
                    Your Contributions do not include any offensive comments that are connected to race, national
                    origin, gender, sexual preference, or physical handicap.
                </li>
                <li>
                    Your Contributions do not otherwise violate, or link to material that violates, any provision of
                    these Legal Terms, or any applicable law or regulation.
                </li>
            </ol>
            <p>
                Any use of the Services in violation of the foregoing violates these Legal Terms and may result in,
                among other things, termination or suspension of your rights to use the Services.
            </p>

            <h3 id="contribution">10. CONTRIBUTION LICENSE</h3>
            <p>
                You and Services agree that we may access, store, process, and use any information and personal data
                that you provide following the terms of the Privacy Policy and your choices (including settings).
            </p>
            <p>
                By submitting suggestions or other feedback regarding the Services, you agree that we can use and share
                such feedback for any purpose without compensation to you.
            </p>
            <p>
                We do not assert any ownership over your Contributions. You retain full ownership of all of your
                Contributions and any intellectual property rights or other proprietary rights associated with your
                Contributions. We are not liable for any statements or representations in your Contributions provided by
                you in any area on the Services. You are solely responsible for your Contributions to the Services and
                you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action
                against us regarding your Contributions.
            </p>

            <h3 id="mobileApplication">11. MOBILE APPLICATION LICENSE</h3>
            <h4>Use License</h4>
            <p>
                If you access the Services via the App, then we grant you a revocable, non-exclusive, non-transferable,
                limited right to install and use the App on wireless electronic devices owned or controlled by you, and
                to access and use the App on such devices strictly in accordance with the terms and conditions of this
                mobile application license contained in these Legal Terms. You shall not:
            </p>
            <ol>
                <li>
                    except as permitted by applicable law, decompile, reverse engineer, disassemble, attempt to derive
                    the source code of, or decrypt the App;
                </li>
                <li>
                    make any modification, adaptation, improvement, enhancement, translation, or derivative work from
                    the App;
                </li>
                <li>
                    violate any applicable laws, rules, or regulations in connection with your access or use of the App;
                </li>
                <li>
                    remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark)
                    posted by us or the licensors of the App;
                </li>
                <li>
                    use the App for any revenue-generating endeavor, commercial enterprise, or other purpose for which
                    it is not designed or intended;
                </li>
                <li>
                    make the App available over a network or other environment permitting access or use by multiple
                    devices or users at the same time;
                </li>
                <li>
                    use the App for creating a product, service, or software that is, directly or indirectly,
                    competitive with or in any way a substitute for the App;
                </li>
                <li>
                    use the App to send automated queries to any website or to send any unsolicited commercial email; or
                </li>
                <li>
                    use any proprietary information or any of our interfaces or our other intellectual property in the
                    design, development, manufacture, licensing, or distribution of any applications, accessories, or
                    devices for use with the App.
                </li>
            </ol>
            <h4>Apple and Android Devices</h4>
            <p>
                The following terms apply when you use the App obtained from either the Apple Store or Google Play (each
                an "App Distributor") to access the Services:
            </p>
            <ol>
                <li>
                    the license granted to you for our App is limited to a non-transferable license to use the
                    application on a device that utilizes the Apple iOS or Android operating systems, as applicable, and
                    in accordance with the usage rules set forth in the applicable App Distributor’s terms of service;
                </li>
                <li>
                    we are responsible for providing any maintenance and support services with respect to the App as
                    specified in the terms and conditions of this mobile application license contained in these Legal
                    Terms or as otherwise required under applicable law, and you acknowledge that each App Distributor
                    has no obligation whatsoever to furnish any maintenance and support services with respect to the
                    App;
                </li>
                <li>
                    in the event of any failure of the App to conform to any applicable warranty, you may notify the
                    applicable App Distributor, and the App Distributor, in accordance with its terms and policies, may
                    refund the purchase price, if any, paid for the App, and to the maximum extent permitted by
                    applicable law, the App Distributor will have no other warranty obligation whatsoever with respect
                    to the App;
                </li>
                <li>
                    you represent and warrant that (i) you are not located in a country that is subject to a US
                    government embargo, or that has been designated by the US government as a "terrorist supporting"
                    country and (ii) you are not listed on any US government list of prohibited or restricted parties;
                </li>
                <li>
                    you must comply with applicable third-party terms of agreement when using the App, e.g., if you have
                    a VoIP application, then you must not be in violation of their wireless data service agreement when
                    using the App; and
                </li>
                <li>
                    you acknowledge and agree that the App Distributors are third-party beneficiaries of the terms and
                    conditions in this mobile application license contained in these Legal Terms, and that each App
                    Distributor will have the right (and will be deemed to have accepted the right) to enforce the terms
                    and conditions in this mobile application license contained in these Legal Terms against you as a
                    third-party beneficiary thereof.
                </li>
            </ol>

            <h3 id="servicesManagement">12. SERVICES MANAGEMENT</h3>
            <p>We reserve the right, but not the obligation, to:</p>
            <ol>
                <li>monitor the Services for violations of these Legal Terms;</li>
                <li>
                    take appropriate legal action against anyone who, in our sole discretion, violates the law or these
                    Legal Terms, including without limitation, reporting such user to law enforcement authorities;
                </li>
                <li>
                    in our sole discretion and without limitation, refuse, restrict access to, limit the availability
                    of, or disable (to the extent technologically feasible) any of your Contributions or any portion
                    thereof;
                </li>
                <li>
                    in our sole discretion and without limitation, notice, or liability, to remove from the Services or
                    otherwise disable all files and content that are excessive in size or are in any way burdensome to
                    our systems; and
                </li>
                <li>
                    otherwise manage the Services in a manner designed to protect our rights and property and to
                    facilitate the proper functioning of the Services.
                </li>
            </ol>

            <h3 id="privacyPolicy">13. PRIVACY POLICY</h3>
            <p>
                We care about data privacy and security. Please review our Privacy Policy:{" "}
                <a href="https://dentscape.ai/privacy" target="_blank" rel="noreferrer">
                    https://dentscape.ai/privacy
                </a>
                . By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these
                Legal Terms.
            </p>
            <p>
                Our Services are designed to comply with the General Data Protection Regulation (GDPR) for our users in
                the European Union. We have implemented measures to ensure that your personal data is processed in
                compliance with GDPR, including data protection by design and by default. We offer rights to access,
                correct, delete, and restrict the processing of your data.
            </p>
            <h4>Data Storage and Processing</h4>
            <ul>
                <li>
                    European Union: If you are accessing our Services from the European Union, your data will be stored
                    and processed on servers located within the European Union. We ensure that your data will not be
                    transferred out of the European Union.
                </li>
                <li>
                    Other Regions: The Services are hosted in Taiwan. If you access the Services from any other region
                    of the world with laws or other requirements governing personal data collection, use, or disclosure
                    that differ from applicable laws in Taiwan, then through your continued use of the Services, you are
                    transferring your data to Taiwan, and you expressly consent to have your data transferred to and
                    processed in Taiwan.
                </li>
            </ul>
            <p>
                By using the Services, you agree to the collection, use, and sharing of your information in accordance
                with our Privacy Policy. We take all necessary steps to ensure that your data is treated securely and in
                accordance with this Privacy Policy.
                <br />
                If you have any questions about our data practices or your rights regarding your personal data, please
                contact us at info@dentscape.ai.
            </p>

            <h3 id="termTermination">14. TERM AND TERMINATION</h3>
            <h4>Preteeth AI Pro</h4>
            <ul>
                <li>Term: The term of the subscription is based on the selected plan (monthly or yearly).</li>
                <li>
                    Termination: Users can cancel their subscription at any time, and the cancellation will take effect
                    at the end of the current paid term. We reserve the right to terminate the subscription for any
                    violations of the terms.
                </li>
            </ul>
            <h4>Dentscape</h4>
            <ul>
                <li>Term: The term of the credit usage is indefinite, subject to the availability of credits.</li>
                <li>
                    Termination: Users can stop using the service at any time. We reserve the right to terminate the
                    account for any violations of the terms.
                </li>
            </ul>

            <h3 id="modification">15. MODIFICATIONS AND INTERRUPTIONS</h3>
            <p>
                We reserve the right to change, modify, or remove the contents of the Services at any time or for any
                reason at our sole discretion without notice. However, we have no obligation to update any information
                on our Services. We will not be liable to you or any third party for any modification, price change,
                suspension, or discontinuance of the Services.
                <br />
                We cannot guarantee the Services will be available at all times. We may experience hardware, software,
                or other problems or need to perform maintenance related to the Services, resulting in interruptions,
                delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise
                modify the Services at any time or for any reason without notice to you. You agree that we have no
                liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use
                the Services during any downtime or discontinuance of the Services. Nothing in these Legal Terms will be
                construed to obligate us to maintain and support the Services or to supply any corrections, updates, or
                releases in connection therewith.
            </p>

            <h3 id="governingLaw">16. GOVERNING LAW</h3>
            <p>
                These Legal Terms shall be governed by and defined following the laws of Taiwan. Preteeth AI Co., Ltd.
                and you irrevocably consent that any dispute which may arise in connection with these Legal Terms shall
                be exclusively resolved in the Taipei District Court in Taipei, Taiwan.
            </p>

            <h3 id="disputeResolution">17. DISPUTE RESOLUTION</h3>
            <p>
                You agree to irrevocably submit all disputes related to these Legal Terms or the legal relationship
                established by these Legal Terms to the exclusive jurisdiction of the Taipei District Court in Taipei,
                Taiwan. Preteeth AI Co., Ltd. reserves the right to bring proceedings in the courts of the country where
                you reside if necessary to enforce any judgment or order obtained in the Taipei District Court.
            </p>

            <h3 id="corrections">18. CORRECTIONS</h3>
            <p>
                There may be information on the Services that contains typographical errors, inaccuracies, or omissions,
                including descriptions, pricing, availability, and various other information. We reserve the right to
                correct any errors, inaccuracies, or omissions and to change or update the information on the Services
                at any time, without prior notice.
            </p>

            <h3 id="disclaimer">19. DISCLAIMER</h3>
            <p>
                THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES
                WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
                OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE
                IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE
                NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE
                CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR
                RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL
                INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
                SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
                TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
                TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY
                CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
                CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE,
                GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY
                THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY
                BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
                ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
                OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND
                EXERCISE CAUTION WHERE APPROPRIATE.
                <br />
                ANY SIMULATED IMAGES OR RESTORATION DESIGNS GENERATED BY THE SERVICES ARE FOR REFERENCE PURPOSES ONLY.
                THE FINAL DECISION ON THEIR ADJUSTMENT AND USE SHALL BE MADE BY DENTAL PROFESSIONALS. WE DO NOT ASSUME
                RESPONSIBILITY FOR ANY MEDICAL OR DENTAL DECISIONS BASED ON THE USE OF THE SERVICES.
            </p>

            <h3 id="limitationLiability">20. LIMITATIONS OF LIABILITY</h3>
            <p>
                IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
                DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
                PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE
                HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
                HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT
                ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH
                PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING OR $30.00 USD. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS
                DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF
                THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
                YOU MAY HAVE ADDITIONAL RIGHTS.
            </p>

            <h3 id="indemnification">21. INDEMNIFICATION</h3>
            <p>
                You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of
                our respective officers, agents, partners, and employees, from and against any loss, damage, liability,
                claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or
                arising out of:
            </p>
            <ol>
                <li>use of the Services;</li>
                <li>breach of these Legal Terms;</li>
                <li>any breach of your representations and warranties set forth in these Legal Terms;</li>
                <li>
                    your violation of the rights of a third party, including but not limited to intellectual property
                    rights; or
                </li>
                <li>
                    any overt harmful act toward any other user of the Services with whom you connected via the
                    Services.
                </li>
            </ol>
            <p>
                Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense
                and control of any matter for which you are required to indemnify us, and you agree to cooperate, at
                your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such
                claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
            </p>

            <h3 id="userData">22. USER DATA</h3>
            <p>
                We will maintain certain data that you transmit to the Services for the purpose of managing the
                performance of the Services, as well as data relating to your use of the Services. Although we perform
                regular routine backups of data, you are solely responsible for all data that you transmit or that
                relates to any activity you have undertaken using the Services. You agree that we shall have no
                liability to you for any loss or corruption of any such data, and you hereby waive any right of action
                against us arising from any such loss or corruption of such data.
            </p>

            <h3 id="electronicCommunications">23. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h3>
            <p>
                Visiting the Services, sending us emails, and completing online forms constitute electronic
                communications. You consent to receive electronic communications, and you agree that all agreements,
                notices, disclosures, and other communications we provide to you electronically, via email and on the
                Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE
                USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
                NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You
                hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other
                laws in any jurisdiction which require an original signature or delivery or retention of non-electronic
                records, or to payments or the granting of credits by any means other than electronic means.
            </p>

            <h3 id="californiaUsers">24. CALIFORNIA USERS AND RESIDENTS</h3>
            <p>
                If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit
                of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625
                North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916)
                445-1254.
            </p>

            <h3 id="miscellaneous">25. MISCELLANEOUS</h3>
            <p>
                These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the
                Services constitute the entire agreement and understanding between you and us. Our failure to exercise
                or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or
                provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all
                of our rights and obligations to others at any time. We shall not be responsible or liable for any loss,
                damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or
                part of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that
                provision or part of the provision is deemed severable from these Legal Terms and does not affect the
                validity and enforceability of any remaining provisions. There is no joint venture, partnership,
                employment or agency relationship created between you and us as a result of these Legal Terms or use of
                the Services. You agree that these Legal Terms will not be construed against us by virtue of having
                drafted them. You hereby waive any and all defenses you may have based on the electronic form of these
                Legal Terms and the lack of signing by the parties hereto to execute these Legal Terms.
            </p>

            <h3 id="contact">26. CONTACT US</h3>
            <p>
                In order to resolve a complaint regarding the Services or to receive further information regarding use
                of the Services, please contact us at:
            </p>
            <p>
                Preteeth AI Co., Ltd.
                <br />
                11F., No. 87, Songjiang Rd., Zhongshan Dist.
                <br />
                Taipei City, Taiwan
                <br />
                <a
                    href="mailto:info@dentscape.ai"
                    className="__cf_email__"
                    data-cfemail="47342235312e242207373522332222332f6924282a"
                >
                    info@dentscape.ai
                </a>
            </p>
        </>
    );

    const SmileCare = () => (
        <>
            <h2>Terms & Conditions</h2>
            <p style={{ color: "#595959" }}>Updated at August 16th, 2023</p>

            <h3>General Terms</h3>
            <p>
                By accessing and placing an order with SmileCare, you confirm that you are in agreement with and bound
                by the terms of service contained in the Terms & Conditions outlined below. These terms apply to the
                entire website and any email or other type of communication between you and SmileCare.
            </p>
            <p>
                Under no circumstances shall SmileCare team be liable for any direct, indirect, special, incidental or
                consequential damages, including, but not limited to, loss of data or profit, arising out of the use, or
                the inability to use, the materials on this site, even if SmileCare team or an authorized representative
                has been advised of the possibility of such damages. If your use of materials from this site results in
                the need for servicing, repair or correction of equipment or data, you assume any costs thereof.
            </p>
            <p>
                SmileCare will not be responsible for any outcome that may occur during the course of usage of our
                resources. We reserve the rights to change prices and revise the resources usage policy in any moment.
            </p>

            <h3>License</h3>
            <p>
                SmileCare grants you a revocable, non-exclusive, non-transferable, limited license to download, install
                and use the website strictly in accordance with the terms of this Agreement.
            </p>
            <p>
                These Terms & Conditions are a contract between you and SmileCare (referred to in these Terms &
                Conditions as "SmileCare", "us", "we" or "our"), the provider of the SmileCare website and the services
                accessible from the SmileCare website (which are collectively referred to in these Terms & Conditions as
                the "SmileCare Service").
            </p>
            <p>
                You are agreeing to be bound by these Terms & Conditions. If you do not agree to these Terms &
                Conditions, please do not use the SmileCare Service. In these Terms & Conditions, "you" refers both to
                you as an individual and to the entity you represent. If you violate any of these Terms & Conditions, we
                reserve the right to cancel your account or block access to your account without notice.
            </p>

            <h3>Definitions and key terms</h3>
            <p>
                To help explain things as clearly as possible in this Terms & Conditions, every time any of these terms
                are referenced, are strictly defined as:
            </p>
            <ul>
                <li>
                    Cookie: small amount of data generated by a website and saved by your web browser. It is used to
                    identify your browser, provide analytics, remember information about you such as your language
                    preference or login information.
                </li>
                <li>
                    Company: when this terms mention “Company,” “we,” “us,” or “our,” it refers to Preteeth AI Co.,
                    Ltd., (11F., No. 87, Songjiang Rd., Zhongshan Dist., Taipei City, Taiwan), that is responsible for
                    your information under this Terms & Conditions.
                </li>
                <li>Country: where SmileCare or the owners/founders of SmileCare are based, in this case is Taiwan</li>
                <li>
                    Device: any internet connected device such as a phone, tablet, computer or any other device that can
                    be used to visit SmileCare and use the services.
                </li>
                <li>
                    Service: refers to the service provided by SmileCare as described in the relative terms (if
                    available) and on this platform.
                </li>
                <li>
                    Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners,
                    and others who provide our content or whose products or services we think may interest you.
                </li>
                <li>Website: SmileCare's site, which can be accessed via this URL: https://dentscape.ai/SmileCare</li>
                <li>You: a person or entity that is registered with SmileCare to use the Services.</li>
            </ul>
            <p>
                This Terms & Conditions were created with{" "}
                <a href="https://termify.io" target="_blank" rel="noreferrer">
                    Termify.io
                </a>
                .
            </p>

            <h3>Restrictions</h3>
            <p>You agree not to, and you will not permit others to:</p>
            <ul>
                <li>
                    License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise
                    commercially exploit the website or make the platform available to any third party.
                </li>
                <li>
                    Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part
                    of the website.
                </li>
                <li>
                    Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of
                    SmileCare or its affiliates, partners, suppliers or the licensors of the website.
                </li>
            </ul>

            <h3>Return and Refund Policy</h3>
            <p>
                Thanks for shopping at SmileCare. We appreciate the fact that you like to buy the stuff we build. We
                also want to make sure you have a rewarding experience while you're exploring, evaluating, and
                purchasing our products.
            </p>
            <p>
                As with any shopping experience, there are terms and conditions that apply to transactions at SmileCare.
                We'll be as brief as our attorneys will allow. The main thing to remember is that by placing an order or
                making a purchase at SmileCare, you agree to the terms along with SmileCare's Privacy Policy.
            </p>
            <p>
                If, for any reason, You are not completely satisfied with any good or service that we provide, don't
                hesitate to contact us and we will discuss any of the issues you are going through with our product.
            </p>

            <h3>Your Suggestions</h3>
            <p>
                Any feedback, comments, ideas, improvements or suggestions (collectively, "Suggestions") provided by you
                to SmileCare with respect to the website shall remain the sole and exclusive property of SmileCare.
            </p>
            <p>
                SmileCare shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose
                and in any way without any credit or any compensation to you.
            </p>

            <h3>Your Consent</h3>
            <p>
                We've updated our Terms & Conditions to provide you with complete transparency into what is being set
                when you visit our site and how it's being used. By using our website, registering an account, or making
                a purchase, you hereby consent to our Terms & Conditions.
            </p>

            <h3>Links to Other Websites</h3>
            <p>
                This Terms & Conditions applies only to the Services. The Services may contain links to other websites
                not operated or controlled by SmileCare. We are not responsible for the content, accuracy or opinions
                expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or
                completeness by us. Please remember that when you use a link to go from the Services to another website,
                our Terms & Conditions are no longer in effect. Your browsing and interaction on any other website,
                including those that have a link on our platform, is subject to that website's own rules and policies.
                Such third parties may use their own cookies or other methods to collect information about you.
            </p>

            <h3>Cookies</h3>
            <p>
                SmileCare uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small
                piece of data stored on your computer or mobile device by your web browser. We use Cookies to enhance
                the performance and functionality of our website but are non-essential to their use. However, without
                these cookies, certain functionality like videos may become unavailable or you would be required to
                enter your login details every time you visit the website as we would not be able to remember that you
                had logged in previously. Most web browsers can be set to disable the use of Cookies. However, if you
                disable Cookies, you may not be able to access functionality on our website correctly or at all. We
                never place Personally Identifiable Information in Cookies.
            </p>

            <h3>Changes To Our Terms & Conditions</h3>
            <p>
                You acknowledge and agree that SmileCare may stop (permanently or temporarily) providing the Service (or
                any features within the Service) to you or to users generally at SmileCare's sole discretion, without
                prior notice to you. You may stop using the Service at any time. You do not need to specifically inform
                SmileCare when you stop using the Service. You acknowledge and agree that if SmileCare disables access
                to your account, you may be prevented from accessing the Service, your account details or any files or
                other materials which is contained in your account.
            </p>
            <p>
                If we decide to change our Terms & Conditions, we will post those changes on this page, and/or update
                the Terms & Conditions modification date below.
            </p>

            <h3>Modifications to Our website</h3>
            <p>
                SmileCare reserves the right to modify, suspend or discontinue, temporarily or permanently, the website
                or any service to which it connects, with or without notice and without liability to you.
            </p>

            <h3>Updates to Our website</h3>
            <p>
                SmileCare may from time to time provide enhancements or improvements to the features/ functionality of
                the website, which may include patches, bug fixes, updates, upgrades and other modifications
                ("Updates").
            </p>
            <p>
                Updates may modify or delete certain features and/or functionalities of the website. You agree that
                SmileCare has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any
                particular features and/or functionalities of the website to you.
            </p>
            <p>
                You further agree that all Updates will be (i) deemed to constitute an integral part of the website, and
                (ii) subject to the terms and conditions of this Agreement.
            </p>

            <h3>Third-Party Services</h3>
            <p>
                We may display, include or make available third-party content (including data, information, applications
                and other products services) or provide links to third-party websites or services ("Third- Party
                Services").
            </p>
            <p>
                You acknowledge and agree that SmileCare shall not be responsible for any Third-Party Services,
                including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency,
                quality or any other aspect thereof. SmileCare does not assume and shall not have any liability or
                responsibility to you or any other person or entity for any Third-Party Services.
            </p>
            <p>
                Third-Party Services and links thereto are provided solely as a convenience to you and you access and
                use them entirely at your own risk and subject to such third parties' terms and conditions.
            </p>

            <h3>Term and Termination</h3>
            <p>This Agreement shall remain in effect until terminated by you or SmileCare.</p>
            <p>
                SmileCare may, in its sole discretion, at any time and for any or no reason, suspend or terminate this
                Agreement with or without prior notice.
            </p>
            <p>
                This Agreement will terminate immediately, without prior notice from SmileCare, in the event that you
                fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting
                the website and all copies thereof from your computer.
            </p>
            <p>
                Upon termination of this Agreement, you shall cease all use of the website and delete all copies of the
                website from your computer.
            </p>
            <p>
                Termination of this Agreement will not limit any of SmileCare's rights or remedies at law or in equity
                in case of breach by you (during the term of this Agreement) of any of your obligations under the
                present Agreement.
            </p>

            <h3>Copyright Infringement Notice</h3>
            <p>
                If you are a copyright owner or such owner's agent and believe any material on our website constitutes
                an infringement on your copyright, please contact us setting forth the following information: (a) a
                physical or electronic signature of the copyright owner or a person authorized to act on his behalf; (b)
                identification of the material that is claimed to be infringing; (c) your contact information, including
                your address, telephone number, and an email; (d) a statement by you that you have a good faith belief
                that use of the material is not authorized by the copyright owners; and (e) the a statement that the
                information in the notification is accurate, and, under penalty of perjury you are authorized to act on
                behalf of the owner.
            </p>

            <h3>Indemnification</h3>
            <p>
                You agree to indemnify and hold SmileCare and its parents, subsidiaries, affiliates, officers,
                employees, agents, partners and licensors (if any) harmless from any claim or demand, including
                reasonable attorneys' fees, due to or arising out of your: (a) use of the website; (b) violation of this
                Agreement or any law or regulation; or (c) violation of any right of a third party.
            </p>

            <h3>No Warranties</h3>
            <p>
                The website is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without
                warranty of any kind. To the maximum extent permitted under applicable law, SmileCare, on its own behalf
                and on behalf of its affiliates and its and their respective licensors and service providers, expressly
                disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the website,
                including all implied warranties of merchantability, fitness for a particular purpose, title and
                non-infringement, and warranties that may arise out of course of dealing, course of performance, usage
                or trade practice. Without limitation to the foregoing, SmileCare provides no warranty or undertaking,
                and makes no representation of any kind that the website will meet your requirements, achieve any
                intended results, be compatible or work with any other software, websites, systems or services, operate
                without interruption, meet any performance or reliability standards or be error free or that any errors
                or defects can or will be corrected.
            </p>
            <p>
                Without limiting the foregoing, neither SmileCare nor any SmileCare's provider makes any representation
                or warranty of any kind, express or implied: (i) as to the operation or availability of the website, or
                the information, content, and materials or products included thereon; (ii) that the website will be
                uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or
                content provided through the website; or (iv) that the website, its servers, the content, or e-mails
                sent from or on behalf of SmileCare are free of viruses, scripts, trojan horses, worms, malware,
                timebombs or other harmful components.
            </p>
            <p>
                Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations
                on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations
                may not apply to you.
            </p>

            <h3>Limitation of Liability</h3>
            <p>
                Notwithstanding any damages that you might incur, the entire liability of SmileCare and any of its
                suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall
                be limited to the amount actually paid by you for the website.
            </p>
            <p>
                To the maximum extent permitted by applicable law, in no event shall SmileCare or its suppliers be
                liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not
                limited to, damages for loss of profits, for loss of data or other information, for business
                interruption, for personal injury, for loss of privacy arising out of or in any way related to the use
                of or inability to use the website, third-party software and/or third-party hardware used with the
                website, or otherwise in connection with any provision of this Agreement), even if SmileCare or any
                supplier has been advised of the possibility of such damages and even if the remedy fails of its
                essential purpose.
            </p>
            <p>
                Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential
                damages, so the above limitation or exclusion may not apply to you.
            </p>

            <h3>Severability</h3>
            <p>
                If any provision of this Agreement is held to be unenforceable or invalid, such provision will be
                changed and interpreted to accomplish the objectives of such provision to the greatest extent possible
                under applicable law and the remaining provisions will continue in full force and effect.
            </p>
            <p>
                This Agreement, together with the Privacy Policy and any other legal notices published by SmileCare on
                the Services, shall constitute the entire agreement between you and SmileCare concerning the Services.
                If any provision of this Agreement is deemed invalid by a court of competent jurisdiction, the
                invalidity of such provision shall not affect the validity of the remaining provisions of this
                Agreement, which shall remain in full force and effect. No waiver of any term of this Agreement shall be
                deemed a further or continuing waiver of such term or any other term, and SmileCare's failure to assert
                any right or provision under this Agreement shall not constitute a waiver of such right or provision.
                YOU AND SmileCare AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE
                WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY
                BARRED.
            </p>

            <h3>Waiver</h3>
            <p>
                Except as provided herein, the failure to exercise a right or to require performance of an obligation
                under this Agreement shall not effect a party's ability to exercise such right or require such
                performance at any time thereafter nor shall be the waiver of a breach constitute waiver of any
                subsequent breach.
            </p>
            <p>
                No failure to exercise, and no delay in exercising, on the part of either party, any right or any power
                under this Agreement shall operate as a waiver of that right or power. Nor shall any single or partial
                exercise of any right or power under this Agreement preclude further exercise of that or any other right
                granted herein. In the event of a conflict between this Agreement and any applicable purchase or other
                terms, the terms of this Agreement shall govern.
            </p>

            <h3>Amendments to this Agreement</h3>
            <p>
                SmileCare reserves the right, at its sole discretion, to modify or replace this Agreement at any time.
                If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect.
                What constitutes a material change will be determined at our sole discretion.
            </p>
            <p>
                By continuing to access or use our website after any revisions become effective, you agree to be bound
                by the revised terms. If you do not agree to the new terms, you are no longer authorized to use
                SmileCare.
            </p>

            <h3>Entire Agreement</h3>
            <p>
                The Agreement constitutes the entire agreement between you and SmileCare regarding your use of the
                website and supersedes all prior and contemporaneous written or oral agreements between you and
                SmileCare.
            </p>
            <p>
                You may be subject to additional terms and conditions that apply when you use or purchase other
                SmileCare's services, which SmileCare will provide to you at the time of such use or purchase.
            </p>

            <h3>Updates to Our Terms</h3>
            <p>
                We may change our Service and policies, and we may need to make changes to these Terms so that they
                accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for
                example, through our Service) before we make changes to these Terms and give you an opportunity to
                review them before they go into effect. Then, if you continue to use the Service, you will be bound by
                the updated Terms. If you do not want to agree to these or any updated Terms, you can delete your
                account.
            </p>

            <h3>Intellectual Property</h3>
            <p>
                The website and its entire contents, features and functionality (including but not limited to all
                information, software, text, displays, images, video and audio, and the design, selection and
                arrangement thereof), are owned by SmileCare, its licensors or other providers of such material and are
                protected by Taiwan and international copyright, trademark, patent, trade secret and other intellectual
                property or proprietary rights laws. The material may not be copied, modified, reproduced, downloaded or
                distributed in any way, in whole or in part, without the express prior written permission of SmileCare,
                unless and except as is expressly provided in these Terms & Conditions. Any unauthorized use of the
                material is prohibited.
            </p>

            <h3>Agreement to Arbitrate</h3>
            <p>
                This section applies to any dispute EXCEPT IT DOESN'T INCLUDE A DISPUTE RELATING TO CLAIMS FOR
                INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR SmileCare’s INTELLECTUAL
                PROPERTY RIGHTS. The term “dispute” means any dispute, action, or other controversy between you and
                SmileCare concerning the Services or this agreement, whether in contract, warranty, tort, statute,
                regulation, ordinance, or any other legal or equitable basis. “Dispute” will be given the broadest
                possible meaning allowable under law.
            </p>

            <h3>Notice of Dispute</h3>
            <p>
                In the event of a dispute, you or SmileCare must give the other a Notice of Dispute, which is a written
                statement that sets forth the name, address, and contact information of the party giving it, the facts
                giving rise to the dispute, and the relief requested. You must send any Notice of Dispute via email to:
                info@dentscape.ai. SmileCare will send any Notice of Dispute to you by mail to your address if we have
                it, or otherwise to your email address. You and SmileCare will attempt to resolve any dispute through
                informal negotiation within sixty (60) days from the date the Notice of Dispute is sent. After sixty
                (60) days, you or SmileCare may commence arbitration.
            </p>

            <h3>Binding Arbitration</h3>
            <p>
                If you and SmileCare don't resolve any dispute by informal negotiation, any other effort to resolve the
                dispute will be conducted exclusively by binding arbitration as described in this section. You are
                giving up the right to litigate (or participate in as a party or class member) all disputes in court
                before a judge or jury. The dispute shall be settled by binding arbitration in accordance with the
                commercial arbitration rules of the American Arbitration Association. Either party may seek any interim
                or preliminary injunctive relief from any court of competent jurisdiction, as necessary to protect the
                party's rights or property pending the completion of arbitration. Any and all legal, accounting, and
                other costs, fees, and expenses incurred by the prevailing party shall be borne by the non-prevailing
                party.
            </p>

            <h3>Submissions and Privacy</h3>
            <p>
                In the event that you submit or post any ideas, creative suggestions, designs, photographs, information,
                advertisements, data or proposals, including ideas for new or improved products, services, features,
                technologies or promotions, you expressly agree that such submissions will automatically be treated as
                non-confidential and non-proprietary and will become the sole property of SmileCare without any
                compensation or credit to you whatsoever. SmileCare and its affiliates shall have no obligations with
                respect to such submissions or posts and may use the ideas contained in such submissions or posts for
                any purposes in any medium in perpetuity, including, but not limited to, developing, manufacturing, and
                marketing products and services using such ideas.
            </p>

            <h3>Promotions</h3>
            <p>
                SmileCare may, from time to time, include contests, promotions, sweepstakes, or other activities
                (“Promotions”) that require you to submit material or information concerning yourself. Please note that
                all Promotions may be governed by separate rules that may contain certain eligibility requirements, such
                as restrictions as to age and geographic location. You are responsible to read all Promotions rules to
                determine whether or not you are eligible to participate. If you enter any Promotion, you agree to abide
                by and to comply with all Promotions Rules. Additional terms and conditions may apply to purchases of
                goods or services on or through the Services, which terms and conditions are made a part of this
                Agreement by this reference.
            </p>

            <h3>Typographical Errors</h3>
            <p>
                In the event a product and/or service is listed at an incorrect price or with incorrect information due
                to typographical error, we shall have the right to refuse or cancel any orders placed for the product
                and/or service listed at the incorrect price. We shall have the right to refuse or cancel any such order
                whether or not the order has been confirmed and your credit card charged. If your credit card has
                already been charged for the purchase and your order is canceled, we shall immediately issue a credit to
                your credit card account or other payment account in the amount of the charge.
            </p>

            <h3>Miscellaneous</h3>
            <p>
                If for any reason a court of competent jurisdiction finds any provision or portion of these Terms &
                Conditions to be unenforceable, the remainder of these Terms & Conditions will continue in full force
                and effect. Any waiver of any provision of these Terms & Conditions will be effective only if in writing
                and signed by an authorized representative of SmileCare. SmileCare will be entitled to injunctive or
                other equitable relief (without the obligations of posting any bond or surety) in the event of any
                breach or anticipatory breach by you. SmileCare operates and controls the SmileCare Service from its
                offices in Taiwan. The Service is not intended for distribution to or use by any person or entity in any
                jurisdiction or country where such distribution or use would be contrary to law or regulation.
                Accordingly, those persons who choose to access the SmileCare Service from other locations do so on
                their own initiative and are solely responsible for compliance with local laws, if and to the extent
                local laws are applicable. These Terms & Conditions (which include and incorporate the SmileCare Privacy
                Policy) contains the entire understanding, and supersedes all prior understandings, between you and
                SmileCare concerning its subject matter, and cannot be changed or modified by you. The section headings
                used in this Agreement are for convenience only and will not be given any legal import.
            </p>

            <h3>Disclaimer</h3>
            <p>SmileCare is not responsible for any content, code or any other imprecision.</p>
            <p>SmileCare does not provide warranties or guarantees.</p>
            <p>
                In no event shall SmileCare be liable for any special, direct, indirect, consequential, or incidental
                damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising
                out of or in connection with the use of the Service or the contents of the Service. The Company reserves
                the right to make additions, deletions, or modifications to the contents on the Service at any time
                without prior notice.
            </p>
            <p>
                The SmileCare Service and its contents are provided "as is" and "as available" without any warranty or
                representations of any kind, whether express or implied. SmileCare is a distributor and not a publisher
                of the content supplied by third parties; as such, SmileCare exercises no editorial control over such
                content and makes no warranty or representation as to the accuracy, reliability or currency of any
                information, content, service or merchandise provided through or accessible via the SmileCare Service.
                Without limiting the foregoing, SmileCare specifically disclaims all warranties and representations in
                any content transmitted on or in connection with the SmileCare Service or on sites that may appear as
                links on the SmileCare Service, or in the products provided as a part of, or otherwise in connection
                with, the SmileCare Service, including without limitation any warranties of merchantability, fitness for
                a particular purpose or non-infringement of third party rights. No oral advice or written information
                given by SmileCare or any of its affiliates, employees, officers, directors, agents, or the like will
                create a warranty. Price and availability information is subject to change without notice. Without
                limiting the foregoing, SmileCare does not warrant that the SmileCare Service will be uninterrupted,
                uncorrupted, timely, or error-free.
            </p>

            <h3>Contact Us</h3>
            <p>Don't hesitate to contact us if you have any questions.</p>
            <ul>
                <li>
                    Via Email:{" "}
                    <a
                        href="mailto:info@dentscape.ai"
                        className="__cf_email__"
                        data-cfemail="47342235312e242207373522332222332f6924282a"
                    >
                        info@dentscape.ai
                    </a>
                </li>
            </ul>
        </>
    );

    return <div className="termsBg">{type !== "SmileCare" ? <PreteethAIPro /> : <SmileCare />}</div>;
}
