import "./Benefits.scss";

export default function Benefits() {
    return (
        <div className="benefits">
            <div className="img-container">
                <img
                    src={require("../../../../assets/img/dentist/home/benefit_img.webp")}
                    alt="Software Appearance"
                />
            </div>
            <div className="whole-benefits-content">
                <div
                    className="benefit-box"
                    data-aos="fade-down"
                    data-aos-duration="750"
                >
                    <img
                        src={require("../../../../assets/img/dentist/home/icon_benefit_easy.webp")}
                        alt="Icon 1"
                    />
                    <div className="benefit-text-container">
                        <p className="benefit-title">Easy to Use</p>
                        <p className="benefit-description">
                            Upload and design effortless with a few clicks.
                        </p>
                    </div>
                </div>
                <div
                    className="benefit-box"
                    data-aos="fade-down"
                    data-aos-duration="750"
                    data-aos-delay="100"
                >
                    <img
                        src={require("../../../../assets/img/dentist/home/icon_benefit_flex.webp")}
                        alt="Icon 2"
                    />

                    <div className="benefit-text-container">
                        <p className="benefit-title">Flexible Access</p>
                        <p className="benefit-description">
                            Use our cloud-based solution on any device.
                        </p>
                    </div>
                </div>
                <div
                    className="benefit-box"
                    data-aos="fade-down"
                    data-aos-duration="750"
                    data-aos-delay="200"
                >
                    <img
                        src={require("../../../../assets/img/dentist/home/icon_benefit_efficient.webp")}
                        alt="Icon 3"
                    />
                    <div className="benefit-text-container">
                        <p className="benefit-title">
                            Efficient and Cost-effective
                        </p>
                        <p className="benefit-description">
                            Affordable, quick designs at your finger tips.{" "}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
