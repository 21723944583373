import { useContext, useEffect, useState } from "react";
import "./PricePage.scss";
import { createStripeCustomer, fetchStripeCustomer, updateSubscriptionStatus } from "../../api/payment";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../api/firebase";
import { Link, useNavigate } from "react-router-dom";
import { MD5 } from "crypto-js";
import { storeContext } from "../../store";
import { getCookie, useQuery } from "../../actions";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { searchPeople, updatePerson } from "../../api/pipedrive";
import { signInWithCustomToken } from "firebase/auth";
import { PreteethAIProBox } from "../../components/Account/Payment/Price/PriceUI";
import { Divider, Result, Skeleton } from "antd";
import Navbar from "../../components/Dentist/Navbar/Navbar";

export default function PricePage({ product }) {
    const { preteethAIProPayment, setPreteethAIProPayment } = useContext(storeContext);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);
    const [canceled, setCanceled] = useState(false);
    const [existed, setExisted] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [errorOccurred, setErrorOccurred] = useState(false);
    const [activeProductCookie, setActiveProductCookie] = useState(null);
    const [pricePlan, setPricePlan] = useState(null);

    const query = new URLSearchParams(window.location.search);
    const isSuccess = query.get("success");
    const isCanceled = query.get("canceled");
    const isExisted = query.get("existed");
    const q = useQuery();
    const uid = q.get("id");
    const token = q.get("token");

    const encryptedPrefix = token?.slice(0, 32);
    const encryptedSuffix = token?.slice(-32);
    const id = token?.slice(32, -32);
    const idFirstFiveString = id?.slice(0, 5);
    const idSixToTenString = id?.slice(5, 10);
    const encryptedId = token && `${MD5(idFirstFiveString).toString()}${MD5(idSixToTenString).toString()}`;
    const finalToken = token && encryptedId === `${encryptedPrefix}${encryptedSuffix}` ? id : null;

    const fetchPaymentInfoAndDefineQueryType = async () => {
        try {
            if (uid || finalToken) {
                // remove query string
                q.delete("id");
                q.delete("token");

                const newParams = [];
                if (isSuccess !== null) newParams.push("success=true");
                if (isCanceled !== null) newParams.push("canceled=true");
                if (isExisted !== null) newParams.push("existed=true");
                const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${
                    newParams.length > 0 ? `?${newParams.join("&")}` : ""
                }`;
                window.history.replaceState({}, document.title, newUrl);
            }

            const newPaymentStatus = await fetchPaymentInfo();
            let subscriptionStatus = "";
            let subscribeDate = "";
            let expiresDate = "";
            if (newPaymentStatus.length !== 0) {
                subscriptionStatus = newPaymentStatus[0].subscriptionType;
                subscribeDate = newPaymentStatus[0].startDate;
                expiresDate = newPaymentStatus[0].currentPeriodEnd;
            }

            await fetchUserPricePlan();

            if (isSuccess) {
                await updateSubscriptionStatus(
                    uid ? uid : finalToken ? finalToken : user?.uid,
                    subscriptionStatus,
                    subscribeDate,
                    expiresDate,
                    null
                );
                setSuccess(true);
            }

            if (query.get("canceled")) {
                setCanceled(true);
            } else if (query.get("existed")) {
                setExisted(true);
            }
        } catch (err) {
            console.error(err);
            setErrorOccurred(true);
        } finally {
            setDataLoading(false);
        }
    };

    const handleSignIn = async () => {
        try {
            const getCustomToken = await fetch(
                "https://us-central1-backend-server-333005.cloudfunctions.net/createCustomToken",
                {
                    method: "POST",
                    headers: {
                        authorization: process.env.REACT_APP_CLOUD_FUNCTIONS_API_KEY,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        uid: uid ? uid : finalToken ? finalToken : user?.uid,
                    }),
                }
            );

            const customToken = await getCustomToken.json();
            await signInWithCustomToken(auth, customToken.token).then(() => {
                console.log("Sign in successfully");
            });
        } catch (err) {
            console.error(err);
        }
    };

    const fetchUserData = async () => {
        if (errorOccurred) return;

        try {
            const userRef = doc(db, "user_datas", user ? user.uid : uid ? uid : finalToken ? finalToken : "");
            const userDoc = await getDoc(userRef);
            const userData = userDoc.data();

            return {
                name: userData.name,
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
            };
        } catch (err) {
            console.error(err);
            setErrorOccurred(true);
            return { name: "Preteeth AI User", email: user?.email };
        }
    };

    const fetchUserPricePlan = async () => {
        try {
            const userRef = doc(db, "user_datas", user ? user.uid : uid ? uid : finalToken ? finalToken : "");
            const userDoc = await getDoc(userRef);
            const userData = userDoc.data();
            const email = userData.email;

            if (userData.pricePlan === undefined || userData.pricePlan === null) {
                await updateDoc(userRef, { pricePlan: "$19" });

                const { totalItems, id } = await searchPeople(email);
                if (totalItems !== 0) {
                    await updatePerson({ id, pricePlan: "$19" });
                }
            }

            setPricePlan("$19");
        } catch (err) {
            console.error(err);
        }
    };

    const fetchPaymentInfo = async () => {
        if (errorOccurred) return;

        try {
            const stripeCustomer = await fetchStripeCustomer(
                user ? user.uid : uid ? uid : finalToken ? finalToken : ""
            );
            if (
                stripeCustomer.message === "No such customer" ||
                (stripeCustomer.error && stripeCustomer.error.includes("No such customer"))
            ) {
                createCustomer();
            } else {
                const preteethAIProPayments = stripeCustomer.message.reduce((acc, item) => {
                    if (item.product === "Preteeth AI Pro") {
                        acc.push(item);
                    }
                    return acc;
                }, []);

                if (
                    user?.emailVerified &&
                    preteethAIProPayment !== undefined &&
                    preteethAIProPayments.length !== 0 &&
                    preteethAIProPayments[0].status === "active"
                ) {
                    const currentTime = new Date();
                    const expireTime = new Date(currentTime.getTime() + 10 * 60 * 1000).toUTCString();
                    document.cookie = `activeProduct=${preteethAIProPayments[0].subscriptionType}; expires=${expireTime}; path=/`;
                } else if (getCookie("activeProduct") !== null) {
                    setActiveProductCookie(getCookie("activeProduct"));
                }

                setPreteethAIProPayment(preteethAIProPayments);

                return preteethAIProPayments;
            }
        } catch (err) {
            console.error(err);
            setErrorOccurred(true);
        }
    };

    const createCustomer = async () => {
        if (errorOccurred) return;

        try {
            const { name, firstName, lastName, email } = await fetchUserData();
            await createStripeCustomer(
                email,
                user ? user.uid : uid ? uid : finalToken ? finalToken : "",
                name !== undefined ? name : `${firstName} ${lastName}`,
                async () => {
                    await fetchPaymentInfo();
                }
            );
        } catch (err) {
            console.error(err);
            setErrorOccurred(true);
        }
    };

    let dataInit = false;
    useEffect(() => {
        if (!dataInit) {
            dataInit = true;

            if (!uid && !token) {
                if (loading) setDataLoading(true);
                if (!user) navigate(`/login?redirectTo=${product}/Payment`);
                if (user && !user?.emailVerified) navigate("/emailVerification");
                if (error) navigate("/");
            }

            if (user || uid || finalToken) {
                const userInit = async () => {
                    setDataLoading(true);
                    if (!user && (uid || finalToken)) {
                        await handleSignIn();
                    }
                    await fetchPaymentInfoAndDefineQueryType();
                };
                userInit();
            }
        }
    }, []);

    const Product = () => (
        <div className="product">
            <div className="main-container">
                <div className="plan-content">
                    <img
                        className="img-dentist"
                        src={require("../../assets/img/dentist/img_payment_dentist.webp")}
                        alt="Preteeth AI pro"
                    />

                    {dataLoading ? (
                        <Skeleton active className="payment-skeleton" paragraph={{ rows: 12 }} />
                    ) : (
                        <PreteethAIProBox
                            uid={user ? user.uid : uid ? uid : finalToken ? finalToken : ""}
                            product={product}
                            activeProduct={
                                activeProductCookie !== null
                                    ? activeProductCookie
                                    : preteethAIProPayment.length !== 0 &&
                                      preteethAIProPayment[0].status === "active" &&
                                      preteethAIProPayment[0].subscriptionType
                            }
                            pricePlan={pricePlan}
                        />
                    )}
                </div>
            </div>
        </div>
    );

    const OrderSuccess = () => (
        <div className="success-container">
            <h3 className="success-title">Payment Success!</h3>

            {!dataLoading ? (
                <div className="success-box">
                    <div className="success-context">
                        <Result status="success" />
                        <p>You can now return to app and press the "Refresh" button on the screen to continue.</p>
                    </div>
                    <div className="priceDetail">
                        <p
                            style={{
                                marginBottom: "7.5px",
                                fontSize: "0.9rem",
                                color: "rgb(228, 228, 228)",
                            }}
                        >
                            Preteeth AI pro
                        </p>
                        <h3>
                            {preteethAIProPayment.length !== 0
                                ? preteethAIProPayment[0].status === "active"
                                    ? preteethAIProPayment[0].subscriptionType.charAt(0).toUpperCase() +
                                      preteethAIProPayment[0].subscriptionType.slice(1)
                                    : preteethAIProPayment[0].status.charAt(0).toUpperCase() +
                                      preteethAIProPayment[0].status.slice(1)
                                : "Not subscribed"}
                        </h3>
                        <Divider />
                        <div>
                            {preteethAIProPayment.length !== 0 && (
                                <>
                                    <p>
                                        <span>Start:</span>
                                        <br />
                                        {preteethAIProPayment[0].startDate}
                                    </p>
                                    <p>
                                        <span>End:</span>
                                        <br />
                                        {preteethAIProPayment[0].currentPeriodEnd}
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <Skeleton active className="payment-skeleton" />
            )}

            <Link className="btn-openApp" to="/openApp" target="_blank">
                Open App
            </Link>
        </div>
    );

    return success ? (
        <div id="payment">
            <Navbar />
            <OrderSuccess />
            <p className="payment-footer">
                If you have any questions about the plan, please contact{" "}
                <a href="mailto:hi@dentscape.ai">hi@dentscape.ai</a>
            </p>
        </div>
    ) : canceled ? (
        <div id="payment">
            <Navbar />
            <p className="warningMessage">Order canceled -- continue when you're ready.</p>
            <Product />
            <p className="payment-footer">
                If you have any questions about the plan, please contact{" "}
                <a href="mailto:hi@dentscape.ai">hi@dentscape.ai</a>
            </p>
        </div>
    ) : existed ? (
        <div id="payment">
            <Navbar />
            <p className="warningMessage">You are currently subscribed to this plan.</p>
            <Product />
            <p className="payment-footer">
                If you have any questions about the plan, please contact{" "}
                <a href="mailto:hi@dentscape.ai">hi@dentscape.ai</a>
            </p>
        </div>
    ) : (
        <div id="payment">
            <Navbar />
            <Product />
            <p className="payment-footer">
                If you have any questions about the plan, please contact{" "}
                <a href="mailto:hi@dentscape.ai">hi@dentscape.ai</a>
            </p>
        </div>
    );
}
