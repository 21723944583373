import { useEffect, useState } from "react";

export default function useGetMediumPosts() {
    const [isLoading, setIsLoading] = useState(true);
    const [blogPosts, setBlogPosts] = useState([]);
    const [tutorialPosts, setTutorialPosts] = useState([]);

    function extractImageUrlFromContent(content) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, "text/html");
        const figure = doc.querySelector("figure.paragraph-image");

        if (figure) {
            const source = figure.querySelector('source[type="image/webp"]');
            if (source) {
                const srcset = source.getAttribute("srcset");
                return srcset.split(" ")[0];
            }
        }

        return null;
    }

    function extractAuthorName(content) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, "text/html");
        const author = doc.querySelector('a[data-testid="authorName"]');

        if (author) {
            return author.textContent;
        }

        return null;
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                const mediumPostResponse = await fetch(
                    "https://us-central1-backend-server-333005.cloudfunctions.net/mediumPosts-getPosts"
                );
                const mediumPostDatas = await mediumPostResponse.json();

                const blogPosts = mediumPostDatas.filter(
                    (post) => !post.categories.includes("preteeth-ai-pro-tutorial")
                );
                blogPosts.forEach((post) => {
                    post.author = extractAuthorName(post.content);
                    post.thumbnailImg = extractImageUrlFromContent(post.content);
                });

                const tutorialPosts = mediumPostDatas.filter((post) =>
                    post.categories.includes("preteeth-ai-pro-tutorial")
                );
                tutorialPosts.forEach((post) => {
                    post.author = extractAuthorName(post.content);
                    post.thumbnailImg = extractImageUrlFromContent(post.content);
                });

                setBlogPosts(blogPosts);
                setTutorialPosts(tutorialPosts.reverse());
            } catch (error) {
                console.error("Error fetching data:", error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    return { isLoading, blogPosts, tutorialPosts };
}
