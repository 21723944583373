import { Link } from "react-router-dom";
import "./Instructions.scss";

export default function DeleteAccountInstructions() {
  return (
    <div className="delete-instructions">
      <h2>What happens if I permanently delete my Preteeth AI account?</h2>
      <ol>
        <li>You won't be able to reactivate your account</li>
        <li>
          Your profile, photos and everything else you've added will be
          permanently deleted. You won't be able to retrieve anything you've
          added.
        </li>
        <li>You'll no longer be able to use Preteeth AI Pro.</li>
        <li>
          If you have ongoing subscription items, they will be terminated, and
          refunds will not be provided
        </li>
      </ol>

      <h2>Learn how to permanently delete your Preteeth AI account.</h2>
      <h4>Computer Help:</h4>
      <p>To permanently delete your account:</p>
      <ol>
        <li>Log in to the official Preteeth AI website.</li>
        <li>
          Click on the "My Account" button in the top right corner of the
          Preteeth AI website.
        </li>
        <li>
          In the Account Settings, click on "I want to delete account" at the
          bottom.
        </li>
        <li>Check or fill in the reason for deletion.</li>
        <li>Press the "delete account" button.</li>
      </ol>

      <h4>Mobile Browser Help</h4>
      <ol>
        <li>Log in to the official Preteeth AI website.</li>
        <li>
          Click the menu in the top right corner of the Preteeth AI website.
        </li>
        <li>Click on the "My Account" button.</li>
        <li>
          In the Account Settings, click on "I want to delete account" at the
          bottom.
        </li>
        <li>Check or fill in the reason for deletion.</li>
        <li>Press the "delete account" button.</li>
      </ol>

      <p>
        Copies of your information may remain after the 90 days in backup
        storage that we use to recover in the event of a disaster, software
        error, or other data loss event. We may also keep your information for
        things like legal issues, terms violations, or harm prevention efforts.
        <br />
        Learn more about our <Link to="/privacy">Privacy Policy.</Link>
      </p>
    </div>
  );
}
