import "./Features.scss";

export default function Features() {
    return (
        <div className="features">
            <h1 className="features-title">
                Explore Our Range
                <br />
                Diverse Restoration Designs Now Available
            </h1>

            <div className="features-container">
                <div
                    className="features-card"
                    data-aos="fade-up"
                    data-aos-duration="750"
                >
                    <img
                        src={require("../../../../assets/img/dentist/home/img_features_single_crown.webp")}
                        alt="Single Crown"
                    />
                    <h3>Single Crown</h3>
                </div>

                <div
                    className="features-card"
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="150"
                >
                    <img
                        src={require("../../../../assets/img/dentist/home/img_features_3d_smile_design.webp")}
                        alt="3D Smile Design"
                    />
                    <h3>3D Smile Design</h3>
                </div>

                <div
                    className="features-card"
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-delay="300"
                >
                    <img
                        src={require("../../../../assets/img/dentist/home/img_features_night_guard.webp")}
                        alt="Night Guard"
                    />
                    <h3>Night Guard</h3>
                </div>
            </div>
        </div>
    );
}
