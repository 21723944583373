export const searchPeople = async (email) => {
    const requestOptions = {
        method: "GET",
        headers: {
            Accept: "application/json",
        },
        redirect: "follow",
    };

    const res = await fetch(
        `https://api.pipedrive.com/v1/persons/search?term=${email}&fields=name,email&api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
        requestOptions
    );

    const resBody = await res.json();

    if (res.status !== 200 || resBody.success === false) {
        throw Error(resBody.error);
    }

    return {
        totalItems: resBody.data.items.length,
        id: resBody.data.items[0]?.item.id || null,
        items: resBody.data.items[0]?.item || null,
    };
};

export const addPerson = async ({
    labelIds,
    uid,
    status,
    firstName,
    lastName,
    email,
    phoneNumber,
    companyName,
    companySize,
    jobTitle,
    registerDate,
    marketingMail,
    pricePlan,
    signUpType,
    contactMessage,
    newsletterSubscription,
    registrationSource,
}) => {
    const optedOut = marketingMail !== true ? "Marketing Information" : "";

    const requestData = {
        ...(labelIds && { label_ids: labelIds }),
        ...((firstName || lastName) && { name: `${firstName} ${lastName}` }),
        ...(firstName && { first_name: firstName }),
        ...(lastName && { last_name: lastName }),
        ...(email && {
            email: [
                {
                    value: email,
                    primary: "true",
                    label: "work",
                },
            ],
        }),
        ...(phoneNumber && {
            phone: [
                {
                    value: phoneNumber,
                    primary: "true",
                    label: "work",
                },
            ],
        }),
        ...(uid && { d808f0e9b1f39a02a34c2e1a1c5e2a4ba51d2ea9: uid }),
        ...(status && { deab87ddfdd021578222264ff97e202742ed64a0: status }),
        ...(companyName && { "88bf21f039031447cea963617d68f5eab7c0551f": companyName }),
        ...(companySize && {
            "7aa606b56d330db00d45e43da0a9e02cf254596d":
                companySize === "1 ~ 10 people"
                    ? 90
                    : companySize === "11 ~ 50 people"
                    ? 91
                    : companySize === "51 ~ 100 people"
                    ? 92
                    : companySize === "over 100 people"
                    ? 93
                    : null,
        }),
        ...(jobTitle && { b8297c272483598be8f8ad0528683a240d24a1cc: jobTitle }),
        ...(registerDate && { ae0519617e443c4a4639769f908987a35631509a: registerDate }),
        ...(optedOut && { ef68ba96b238e40bce1c5ba86a199d0e266655d8: optedOut }),
        ...(pricePlan && {
            a2e435c9ea401c47ed031f7f1c48654947e3578a:
                pricePlan === "$9" || pricePlan === "low"
                    ? 71
                    : pricePlan === "$19"
                    ? 72
                    : pricePlan === "$29" || pricePlan === "medium"
                    ? 73
                    : pricePlan === "$49" || pricePlan === "high"
                    ? 74
                    : null,
        }),
        ...(signUpType && {
            "42650813b2c10c4838099a9ed2777d34effb7492":
                signUpType === "email" ? 66 : signUpType === "google" ? 67 : null,
        }),
        ...(contactMessage && { c8d6ede5a58ffd2447c1ca4abee9da56f21e030a: contactMessage }),
        ...(newsletterSubscription && { "5da4a86de2f3db2c3e9be42cbb9d8c55725442db": "Subscribe" }),
        "031e306b820d46dcea155412765ed0ced52ca273": "Free Trial", //subscription
        marketing_status: "subscribed", // Needs to be true, so that the person can receive all emails
        ...(registrationSource && { "72c6bfb26045deebf334a14bf585cf27769e3f54": registrationSource }), // registration source
    };

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify(requestData),
        redirect: "follow",
    };

    const res = await fetch(
        `https://api.pipedrive.com/v1/persons?api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
        requestOptions
    );

    const resBody = await res.json();

    if (res.status !== 201 || resBody.success === false) {
        throw Error(resBody.error);
    }

    console.log({ success: resBody.success });
    return {
        success: resBody.success,
        id: resBody.data.id || null,
    };
};

export const updatePerson = async ({
    id,
    labelIds,
    uid,
    status,
    firstName,
    lastName,
    email,
    type,
    country,
    city,
    phoneNumber,
    companyName,
    companySize,
    jobTitle,
    hear,
    registerDate,
    verifyLink,
    clickAppSurvey,
    marketingMail,
    marketingStatus,
    pricePlan,
    signUpType,
    contactMessage,
    newsletterSubscription,
}) => {
    const optedOut = marketingMail !== true ? "Marketing Information" : "";

    const requestData = {
        ...(labelIds && { label_ids: labelIds }),
        ...((firstName || lastName) && { name: `${firstName} ${lastName}` }),
        ...(firstName && { first_name: firstName }),
        ...(lastName && { last_name: lastName }),
        ...(email && {
            email: [
                {
                    value: email,
                    primary: "true",
                    label: "work",
                },
            ],
        }),
        ...(phoneNumber && {
            phone: [
                {
                    value: phoneNumber,
                    primary: "true",
                    label: "work",
                },
            ],
        }),
        ...(uid && { d808f0e9b1f39a02a34c2e1a1c5e2a4ba51d2ea9: uid }),
        ...(status && { deab87ddfdd021578222264ff97e202742ed64a0: status }),
        ...(type && { "97f5ee86eed388e2b94bf24470969d29e9a4e3b6": type }),
        ...(country && { "5cf57ef2b155ecc956b90d86d1ee944024192b5d": country }),
        ...(city && { "1b8e54e3399d2dc23b13397f2feae196086e10d8": city }),
        ...(hear && { "28ed47190fc6f1f0237dcd00858fce0d594f69ea": hear }),
        ...(companyName && { "88bf21f039031447cea963617d68f5eab7c0551f": companyName }),
        ...(companySize && {
            "7aa606b56d330db00d45e43da0a9e02cf254596d":
                companySize === "1 ~ 10 people"
                    ? 90
                    : companySize === "11 ~ 50 people"
                    ? 91
                    : companySize === "51 ~ 100 people"
                    ? 92
                    : companySize === "over 100 people"
                    ? 93
                    : null,
        }),
        ...(jobTitle && { b8297c272483598be8f8ad0528683a240d24a1cc: jobTitle }),
        ...(registerDate && { ae0519617e443c4a4639769f908987a35631509a: registerDate }),
        ...(verifyLink && { "5d6be411111301684dec333eb199790c1d5fb8b6": verifyLink }),
        ...(clickAppSurvey && { "418598dba141f63bd977bec45dfcfb4314f01b51": clickAppSurvey }),
        ...((optedOut || optedOut === "") && { ef68ba96b238e40bce1c5ba86a199d0e266655d8: optedOut }),
        ...(marketingStatus && { marketing_status: marketingStatus }),
        ...(pricePlan && {
            a2e435c9ea401c47ed031f7f1c48654947e3578a:
                pricePlan === "$9" || pricePlan === "low"
                    ? 71
                    : pricePlan === "$19"
                    ? 72
                    : pricePlan === "$29" || pricePlan === "medium"
                    ? 73
                    : pricePlan === "$49" || pricePlan === "high"
                    ? 74
                    : null,
        }),
        ...(signUpType && {
            "42650813b2c10c4838099a9ed2777d34effb7492":
                signUpType === "email" ? 66 : signUpType === "google" ? 67 : null,
        }),
        ...(contactMessage && { c8d6ede5a58ffd2447c1ca4abee9da56f21e030a: contactMessage }),
        ...(newsletterSubscription && { "5da4a86de2f3db2c3e9be42cbb9d8c55725442db": "Subscribe" }),
    };

    const requestOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify(requestData),
        redirect: "follow",
    };

    const res = await fetch(
        `https://api.pipedrive.com/v1/persons/${id}?api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
        requestOptions
    );

    const resBody = await res.json();

    if (res.status !== 200 || resBody.success === false) {
        throw Error(resBody.error);
    }

    return resBody.success;
};

export const deletePerson = async (id) => {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        redirect: "follow",
    };

    const res = await fetch(
        `https://api.pipedrive.com/v1/persons/${id}?api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
        requestOptions
    );

    const resBody = await res.json();

    if (res.status !== 204 || resBody.success === false) {
        throw Error(resBody.error);
    }

    return resBody.success;
};

export const addLead = async ({ name, email, phone, message }) => {
    const newPerson = async () => {
        const raw = JSON.stringify({
            name: name,
            email: [
                {
                    value: email,
                    primary: "true",
                    label: "work",
                },
            ],
            phone: [
                {
                    value: phone,
                    primary: "true",
                    label: "work",
                },
            ],
            label: 55,
            marketing_status: "subscribed",
        });

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `https://api.pipedrive.com/v1/persons?api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
            requestOptions
        );

        const resBody = await res.json();

        if (res.status !== 201 || resBody.success === false) {
            throw Error(resBody.error);
        }

        return resBody.data.id;
    };

    const personId = await newPerson();
    if (personId) {
        const raw = JSON.stringify({
            title: `Website Lead - ${name}`,
            label_ids: ["65787bb4-6121-46c6-9846-6b72ff7f78a4"],
            person_id: personId,
        });

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: raw,
            redirect: "follow",
        };

        const leadResponse = await fetch(
            `https://api.pipedrive.com/v1/leads?api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
            requestOptions
        );
        const leadResBody = await leadResponse.json();

        if (leadResponse.status !== 201 || leadResBody.success === false) {
            throw Error(leadResBody.error);
        } else {
            const noteResponse = await fetch(
                `https://api.pipedrive.com/v1/notes?api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    body: JSON.stringify({
                        content: message,
                        person_id: personId,
                        lead_id: leadResBody.data.id,
                        pinned_to_lead_flag: 1,
                    }),
                }
            );
            const noteResBody = await noteResponse.json();

            if (noteResponse.status !== 201 || noteResBody.success === false) {
                throw Error(noteResBody.error);
            } else {
                console.log({ success: noteResBody.success });
                return noteResBody.success;
            }
        }
    }
};

export const addInterstedPerson = async (raw) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: raw,
        redirect: "follow",
    };

    await fetch(
        `https://api.pipedrive.com/v1/persons?api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
        requestOptions
    )
        .then((response) => response.text())
        .then((result) => console.log("success"))
        .catch((error) => console.log("error", error));
};

export const getPersonDetails = async (id) => {
    if (!id) {
        return null;
    }

    const requestOptions = {
        method: "GET",
        headers: {
            Accept: "application/json",
        },
        redirect: "follow",
    };

    const res = await fetch(
        `https://api.pipedrive.com/v1/persons/${id}?api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
        requestOptions
    );

    const resBody = await res.json();

    if (res.status !== 200 || resBody.success === false) {
        throw Error(resBody.error);
    }

    return resBody.data;
};

export const addPersonNote = async ({ personId, noteContent }) => {
    const raw = JSON.stringify({
        content: noteContent,
        person_id: personId,
    });

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: raw,
        redirect: "follow",
    };

    const res = await fetch(
        `https://api.pipedrive.com/v1/notes?api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
        requestOptions
    );

    const resBody = await res.json();

    if (res.status !== 201 || resBody.success === false) {
        throw Error(resBody.error);
    }

    return resBody.success;
};

export const addPersonDeal = async ({ personId, dealTitle, pipelineId, ownerId }) => {
    const raw = JSON.stringify({
        title: dealTitle,
        person_id: personId,
        pipeline_id: pipelineId,
        owner_id: ownerId,
    });

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: raw,
        redirect: "follow",
    };

    const res = await fetch(
        `https://api.pipedrive.com/api/v2/deals?api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
        requestOptions
    );

    const resBody = await res.json();

    if (res.status !== 200 || resBody.success === false) {
        throw Error(resBody.error);
    }

    return resBody.success;
};
