import { useState, useRef, useContext, useEffect } from "react";
import { storeContext } from "../../store";
import "./Hero.scss";
import { Link } from "react-router-dom";
import { Link as Linkto } from "react-scroll";
import { Col, Row } from "antd";
import { useSpring, animated, config } from "@react-spring/web";

const calc = (x, y, rect) => [
    -(y - rect.top - rect.height / 2) / 5,
    (x - rect.left - rect.width / 2) / 5,
    0.9,
];
const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const HomeBtns = () => {
    return (
        <div className="home-btnBox">
            <Linkto
                className="btn-primary"
                type="button"
                to="download"
                duration={1500}
            >
                Download Now
            </Linkto>
            <Link className="btn-secondary" to="contact">
                Book a demo
            </Link>
        </div>
    );
};

const HomeBtnsMobile = () => {
    return (
        <div className="home-btnBox-sm">
            <Linkto
                className="btn-primary"
                type="button"
                to="download"
                duration={1500}
            >
                Download Now
            </Linkto>
            <Link className="btn-secondary" to="contact">
                Book a demo
            </Link>
        </div>
    );
};

export default function Hero({ type, title, subTitle, bgPath }) {
    const { setPatientCtaButton } = useContext(storeContext);
    const [windowSize, setWindowSize] = useState("large");

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1024) {
                setWindowSize("large");
            } else if (window.innerWidth > 768) {
                setWindowSize("medium");
            } else {
                setWindowSize("small");
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    //Animation setting
    const imgRef = useRef(null);
    const [xys, set] = useState([0, 0, 1]);
    const prop = useSpring({ xys, config: config.wobbly });

    return (
        <>
            {type === "dentist" ? (
                <Row
                    id="home"
                    className={`home ${
                        type === "dentalCad" ? "dentalCad-home" : ""
                    }`}
                    style={{
                        backgroundImage: `url(${require(`../../assets/img/${
                            windowSize === "large" ? bgPath : `${bgPath}_mobile`
                        }.webp`)})`,
                    }}
                >
                    <Col
                        xl={type === "dentalCad" ? 12 : 13}
                        md={24}
                        className="home-leftSide"
                        style={{
                            paddingTop:
                                windowSize === "large"
                                    ? type === "dentalCad"
                                        ? "15vh"
                                        : "20vh"
                                    : "",
                        }}
                    >
                        <h1
                            className="home-title"
                            style={{
                                width:
                                    windowSize === "large"
                                        ? type === "dentalCad"
                                            ? "95%"
                                            : "90%"
                                        : "85%",
                            }}
                        >
                            {title}
                        </h1>
                        <h3 className="home-sub-title">{subTitle}</h3>
                        <HomeBtns />
                        <HomeBtnsMobile />
                    </Col>
                    <Col xl={11} style={{ padding: "3vh 0" }} ref={imgRef}>
                        <img
                            src={require("../../assets/img/dentist/img_home.webp")}
                            alt="preteeth AI to C image"
                            className="home-img"
                            data-aos="zoom-in-up"
                            data-aos-duration="500"
                        />
                    </Col>
                </Row>
            ) : type === "patient" ? (
                <Row id="home" className="patient-home">
                    <Col xl={13} md={24} className="patient-home-leftSide">
                        <h1 className="patient-home-title">SmileCare</h1>
                        <svg
                            height="6"
                            viewBox="0 0 444 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3 3H441"
                                stroke="url(#paint0_linear_3776_894)"
                                strokeWidth="5"
                                strokeLinecap="round"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear_3776_894"
                                    x1="3"
                                    y1="3"
                                    x2="441"
                                    y2="3"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop
                                        stopColor="#00C8F0"
                                        stopOpacity="0.9"
                                    />
                                    <stop
                                        offset="0.716098"
                                        stopColor="#00C8F0"
                                        stopOpacity="0.5"
                                    />
                                    <stop
                                        offset="1"
                                        stopColor="#00C8F0"
                                        stopOpacity="0"
                                    />
                                </linearGradient>
                            </defs>
                        </svg>
                        <h1 className="patient-home-title">
                            Personal Dental Assistant at Your Fingertips
                        </h1>

                        <h3 className="patient-home-subtitle">
                            Experience personalized dental care with our
                            AI-powered chat platform
                        </h3>
                        <img
                            src={require("../../assets/img/2C/home_img.webp")}
                            alt="preteeth AI to C image"
                            className="home-img-mobile"
                        />
                        <div className="home-btn-section">
                            <Linkto
                                className="patient-home-btn"
                                to="smile-care"
                                duration={1500}
                                onClick={() =>
                                    setPatientCtaButton("Start analysis")
                                }
                            >
                                Start for Free
                            </Linkto>
                            <div>
                                <p>
                                    ☑️ No need to provide any personal
                                    information
                                </p>
                                <p>☑️ No need to sign up</p>
                            </div>
                        </div>
                    </Col>
                    <Col xl={11} ref={imgRef}>
                        <animated.img
                            src={require("../../assets/img/2C/home_img.webp")}
                            alt="preteeth AI to C image"
                            className="home-img"
                            data-aos="zoom-in-up"
                            data-aos-duration="500"
                            style={{ transform: prop.xys.to(trans) }}
                            onMouseLeave={() => set([0, 0, 1])}
                            onMouseMove={(e) => {
                                const rect =
                                    imgRef.current.getBoundingClientRect();
                                set(calc(e.clientX, e.clientY, rect));
                            }}
                        />
                    </Col>
                </Row>
            ) : (
                <></>
            )}
        </>
    );
}
