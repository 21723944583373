import "./Navbar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { auth } from "../../../api/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { storeContext } from "../../../store";
import { Dropdown } from "antd";

export default function Navbar({ customNavBg }) {
    const [isMobile, setIsMobile] = useState(false);
    const [isScroll, setIsScroll] = useState(false);
    const [user] = useAuthState(auth);
    const [login, setLogin] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (user && user?.emailVerified) setLogin(true);
        else setLogin(false);
    }, [user]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        if (scrollTop >= 80) setIsScroll(true);
        else setIsScroll(false);
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const product = [
        {
            key: "1-1",
            label: (
                <Link
                    className="btn-nav-link btn-menu-link"
                    to="/"
                    onClick={() => {
                        setIsMobile(false);
                        scrollToTop();
                    }}
                >
                    Dentscape AI
                </Link>
            ),
        },
        {
            key: "1-2",
            label: (
                <Link
                    className="btn-nav-link btn-menu-link"
                    to="/PreteethAIpro"
                    onClick={() => {
                        setIsMobile(false);
                        scrollToTop();
                    }}
                >
                    Preteeth AI Pro
                </Link>
            ),
        },
        {
            key: "1-3",
            label: (
                <Link
                    className="btn-nav-link btn-menu-link"
                    to="/SmileCare"
                    onClick={() => {
                        setIsMobile(false);
                        scrollToTop();
                    }}
                >
                    SmileCare
                </Link>
            ),
        },
    ];

    const learn = [
        {
            key: "1",
            label: (
                <Link
                    className="btn-nav-link btn-menu-link"
                    to="/tutorial"
                    onClick={() => {
                        setIsMobile(false);
                        scrollToTop();
                    }}
                >
                    Tutorial
                </Link>
            ),
        },
        {
            key: "2",
            label: (
                <Link
                    className="btn-nav-link btn-menu-link"
                    to="/blog"
                    onClick={() => {
                        setIsMobile(false);
                        scrollToTop();
                    }}
                >
                    Blog
                </Link>
            ),
        },
    ];

    return (
        <header id={isScroll ? "header-fixed" : "header"} className="fixed-top" style={{ padding: isMobile && "0" }}>
            <nav className="navbar" style={customNavBg}>
                <Link to="/" onClick={scrollToTop} style={{ display: isMobile ? "none" : "block" }}>
                    <img src={require("../../../assets/img/logo/logo_dentscape.webp")} alt="logo" className="navlogo" />
                </Link>
                <ul className={isMobile ? "nav-links-mobile" : "nav-links"}>
                    <Link to="/" onClick={scrollToTop}>
                        <img
                            src={require("../../../assets/img/logo/logo_dentscape.webp")}
                            alt="logo"
                            className="navlogo-mobile"
                        />
                    </Link>
                    <li>
                        <Link
                            className={`btn-nav-link ${location.pathname === "/" && "active"}`}
                            to="/"
                            onClick={() => {
                                setIsMobile(false);
                                scrollToTop();
                            }}
                        >
                            Home
                        </Link>
                    </li>

                    {/* Product dropdown */}
                    <Dropdown menu={{ items: product }} overlayClassName="nav-dropdown" arrow={true} placement="bottom">
                        <a
                            className={`btn-nav-link ${
                                location.pathname === "/PreteethAIpro" || location.pathname === "/SmileGen"
                                    ? "active"
                                    : ""
                            }`}
                        >
                            Product
                        </a>
                    </Dropdown>

                    <li>
                        <Link
                            className={`btn-nav-link ${location.pathname === "/API" && "active"}`}
                            to="/API"
                            onClick={() => {
                                setIsMobile(false);
                                scrollToTop();
                            }}
                        >
                            API
                        </Link>
                    </li>

                    {/* Learn dropdown */}
                    <Dropdown menu={{ items: learn }} overlayClassName="nav-dropdown" arrow={true} placement="bottom">
                        <a
                            className={`btn-nav-link ${
                                location.pathname === "/blog" || location.pathname === "/tutorial" ? "active" : ""
                            }`}
                        >
                            Learn
                        </a>
                    </Dropdown>

                    <li>
                        <Link
                            className={`btn-nav-link ${location.pathname === "/contact" && "active"} btn-nav-contact`}
                            to="/contact"
                            onClick={() => {
                                setIsMobile(false);
                                scrollToTop();
                            }}
                        >
                            Contact
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="btn-sign-in btn-nav-try-for-free"
                            to={"/tryForFree"}
                            onClick={() => {
                                setIsMobile(false);
                                scrollToTop();
                            }}
                        >
                            Try for Free
                        </Link>
                    </li>

                    {isMobile && (
                        <button className="btn-close-mobile-navbar" onClick={() => setIsMobile(!isMobile)}>
                            <FontAwesomeIcon icon={faXmark} style={{ color: "#fff" }} />
                        </button>
                    )}
                </ul>

                {!isMobile && (
                    <button className="btn-mobile-navbar-toggler" onClick={() => setIsMobile(!isMobile)}>
                        <FontAwesomeIcon icon={faBars} style={{ color: "#fff" }} />
                    </button>
                )}
            </nav>
        </header>
    );
}
