import { AES } from "crypto-js";

export const fetchStripeCustomer = async (uid) => {
    const response = await fetch(
        "https://us-central1-backend-server-333005.cloudfunctions.net/payment-fetchStripeCustomer",
        {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                uid: uid,
            }),
        }
    );

    const data = await response.json();
    return data;
};

export const createStripeCustomer = async (email, uid, name, callback) => {
    const response = await fetch(
        "https://us-central1-backend-server-333005.cloudfunctions.net/payment-createStripeCustomer",
        {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email,
                uid,
                name,
            }),
        }
    );

    const data = await response.json();

    if (callback) {
        callback(data);
    }

    return data;
};

export const createFreeStripeSubscription = async ({ uid, priceId }) => {
    try {
        const response = await fetch(
            "https://us-central1-backend-server-333005.cloudfunctions.net/payment-createFreeTrialSubscription",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    uid: AES.encrypt(uid, "secret key").toString(),
                    priceId: AES.encrypt(priceId, "secret key").toString(),
                }),
            }
        );

        const data = await response.json();

        return data;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const CheckoutBtn = ({ uid, product, type, priceId, activeProduct, promocode }) => {
    return (
        <>
            <form
                action="https://us-central1-backend-server-333005.cloudfunctions.net/payment-createStripePayment"
                method="POST"
            >
                <input type="hidden" name="uid" value={AES.encrypt(uid, "secret key").toString()} />
                <input type="hidden" name="priceId" value={AES.encrypt(priceId, "secret key").toString()} />
                <input type="hidden" name="subscriptionType" value={type} />
                <input type="hidden" name="quantity" value="1" />
                {promocode !== null && (
                    <input type="hidden" name="coupon" value={AES.encrypt(promocode, "secret key").toString()} />
                )}
                <input type="hidden" name="mode" value="subscription" />
                <input type="hidden" name="product" value={product} />
                <button
                    type="submit"
                    className="btn-submit"
                    disabled={activeProduct && activeProduct !== type ? true : false}
                >
                    Check out
                </button>
            </form>
        </>
    );
};

export const cancelStripeSubscription = async (uid, subscriptionId, feedback, comment) => {
    const response = await fetch(
        "https://us-central1-backend-server-333005.cloudfunctions.net/payment-cancelStripeSubscription",
        {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                uid,
                subscriptionId,
                feedback,
                comment,
            }),
        }
    );

    const data = await response.json();

    return data;
};

export const updateSubscriptionStatus = async (
    uid,
    subscriptionStatus,
    subscribeDate,
    expiresDate,
    unsubscribeDate
) => {
    const response = await fetch(
        "https://us-central1-backend-server-333005.cloudfunctions.net/payment-updateSubscriptionStatus",
        {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                uid,
                subscriptionStatus,
                subscribeDate,
                expiresDate,
                unsubscribeDate,
            }),
        }
    );

    const data = await response.json();

    return data;
};

export const deleteStripeCustomer = async (uid) => {
    const response = await fetch(
        "https://us-central1-backend-server-333005.cloudfunctions.net/payment-deleteStripeCustomer",
        {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ uid }),
        }
    );

    const data = await response.json();

    console.log(data);
    if (data.deleted) {
        return true;
    } else {
        return false;
    }
};

export const findPromotionCode = async ({ promoCode }) => {
    const response = await fetch(
        "https://us-central1-backend-server-333005.cloudfunctions.net/payment-findPromotionCodes",
        {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                promoCode,
            }),
        }
    );

    const data = await response.json();

    if (data.id !== undefined) {
        return data.id;
    } else {
        return null;
    }
};
