import "./HomePage.scss";
import Navbar from "../../components/Dentist/Navbar/Navbar";
import WelcomeModal from "../../components/CustomModal/WelcomeModal";
import Footer from "../../components/Dentist/Footer/Footer";
import Hero from "../../components/Dentist/Home/Hero/Hero";
import Newsletter from "../../components/Dentist/Home/Newsletter/Newsletter";
import Team from "../../components/Dentist/Home/Team/Team";
import Steps from "../../components/Dentist/Home/Steps/Steps";
import Features from "../../components/Dentist/Home/Features/Features";
import Benefits from "../../components/Dentist/Home/Benefits/Benefits";
import CTA from "../../components/Dentist/Home/CTA/CTA";
import Testimonial from "../../components/Dentist/Home/Testimonial/Testimonial";
import Download from "../../components/Dentist/Home/Download/Download";

export default function HomePage() {
    return (
        <div className="home-page">
            {/* <WelcomeModal type="dentist" /> */}
            <Navbar />
            <Hero />
            <Team />
            <Steps />
            <Features />
            <Benefits />
            <CTA />
            <Testimonial />
            <Newsletter />
            {/* <Download /> */}
            <Footer />
        </div>
    );
}
