import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { db } from "../api/firebase";

export function useQuery() {
    const location = useLocation().search;
    return new URLSearchParams(location);
}

export function getCookie(name) {
    const cookieString = document.cookie;
    const cookies = cookieString.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        const [cookieName, cookieValue] = cookie.split("=");

        if (cookieName === name) {
            return decodeURIComponent(cookieValue);
        }
    }

    return null;
}

export const fetchUserInfo = async (user, region) => {
    try {
        const userRef = doc(db, "user_datas", user?.uid);
        const userDoc = await getDoc(userRef);
        const userData = userDoc.data();

        if (userData === undefined) {
            const dentalCadUserRef = doc(
                db,
                "dental_cad_user_datas",
                user?.uid
            );
            const dentalCadUserDoc = await getDoc(dentalCadUserRef);
            const dentalCadUserData = dentalCadUserDoc.data();

            if (dentalCadUserData === undefined) {
                await setDoc(userRef, {
                    email: user?.email,
                    uid: user?.uid,
                    region,
                });
            } else {
                await setDoc(userRef, {
                    email: user?.email,
                    uid: user?.uid,
                    firstName: dentalCadUserData.firstName,
                    lastName: dentalCadUserData.lastName,
                    phoneNumber: dentalCadUserData.phoneNumber,
                    signUpType: dentalCadUserData.signUpType,
                    registerDate: dentalCadUserData.registerDate,
                    region,
                });
            }
        }

        if (userData.region === null) {
            await updateDoc(userRef, {
                region,
            });
        }

        const userProfileRef = doc(db, "user_profiles_v2", user?.uid);
        const userProfileDoc = await getDoc(userProfileRef);
        const userProfileData = userProfileDoc.data();

        return {
            name: userData.name,
            firstName: userData.firstName,
            lastName: userData.lastName,
            type: userData.type,
            country: userData.country,
            city: userData.city,
            phone: userData.phoneNumber,
            hearFrom: userData.hear,
            registerDate: userData.registerDate,
            uid: userData.uid,
            pricePlan: userData.pricePlan,
            freeTrialQuota: userProfileData.free_trial_quota || 0,
            freeTrialUsage: userProfileData.free_trial_usage || 0,
            dataLoading: false,
        };
    } catch (err) {
        console.error(err);
        return {
            name: "Preteeth AI User",
            realCaseUsage: 0,
            dataLoading: false,
        };
    }
};
