import { Divider, Skeleton } from "antd";
import { lazy, useMemo } from "react";
import { Suspense } from "react";
import Navbar from "../../Dentist/Navbar/Navbar";
import "./BlogPost.scss";
import useGetMediumPosts from "../../../hooks/useGetMediumPosts";

const Footer = lazy(() => import(/* webpackChunkName: "footer" */ "../../../components/Dentist/Footer/Footer"));

export default function BlogPost({ type }) {
    const { isLoading, blogPosts, tutorialPosts } = useGetMediumPosts();

    const postData = useMemo(() => {
        if (!isLoading) {
            if (isLoading) return null;

            const id = window.location.pathname.split("/").pop();
            const posts = type === "blog" ? blogPosts : type === "tutorial" && tutorialPosts;
            return posts.find((post) => post.id === id);
        }
    }, [isLoading]);

    return (
        <div id="blog-post">
            <Navbar />
            <div className="post-header">
                {isLoading ? (
                    <>
                        <Skeleton.Input style={{ width: "50vw", height: "1.5rem" }} active />
                        <Divider />
                        <Skeleton active paragraph={{ rows: 1 }} />
                    </>
                ) : (
                    <>
                        <h2>{postData?.title.replace(/&amp;/g, "&")}</h2>
                        <Divider />
                        <h4>{postData?.author}</h4>
                        <p>{new Date(postData?.pubDate).toLocaleDateString("en-US")}</p>
                    </>
                )}
            </div>

            <div className="content">
                {isLoading ? (
                    <>
                        <Skeleton active paragraph={{ rows: 20 }} />
                    </>
                ) : (
                    <>
                        <div className="allText" dangerouslySetInnerHTML={{ __html: postData?.content }}></div>
                        <div className="toMedium">
                            <a href={postData?.link} target="_blank" className="btn-toMedium">
                                Read at Medium
                            </a>
                        </div>
                    </>
                )}
            </div>
            <Suspense
                fallback={
                    <h3
                        style={{
                            color: "#00C8F0",
                            margin: "50px 0",
                            textAlign: "center",
                        }}
                    >
                        Loading...
                    </h3>
                }
            >
                <Footer colorScheme="light" />
            </Suspense>
        </div>
    );
}
