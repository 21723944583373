import "./Team.scss";

export default function Team() {
    return (
        <div className="team">
            <h4>Backgrounds of Our Team</h4>

            <div className="team-background">
                <img
                    src={require("../../../../assets/img/dentist/home/logo_500_global.webp")}
                    alt="500 Global"
                    data-aos="fade-right"
                    data-aos-duration="750"
                />
                <img
                    src={require("../../../../assets/img/dentist/home/logo_berkeley.webp")}
                    alt="Berkeley"
                    data-aos="fade-right"
                    data-aos-duration="750"
                    data-aos-delay="100"
                />
                <img
                    src={require("../../../../assets/img/dentist/home/logo_amazon.webp")}
                    alt="Amazon"
                    data-aos="fade-right"
                    data-aos-duration="750"
                    data-aos-delay="200"
                />
                <img
                    src={require("../../../../assets/img/dentist/home/logo_facebook.webp")}
                    alt="Facebook"
                    data-aos="fade-right"
                    data-aos-duration="750"
                    data-aos-delay="300"
                />
            </div>
        </div>
    );
}
