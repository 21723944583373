import "./Navbar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link as Linkto } from "react-scroll";
import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { storeContext } from "../../../store";

export default function Navbar() {
    const [isMobile, setIsMobile] = useState(false);
    const [isScroll, setIsScroll] = useState(false);
    const { setUserType } = useContext(storeContext);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        if (scrollTop >= 80) setIsScroll(true);
        else setIsScroll(false);
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <header
            id={isScroll ? "header-fixed-patient" : "header-patient"}
            className="fixed-top"
        >
            <nav className="navbar-patient">
                <button
                    to="/SmileCare"
                    onClick={scrollToTop}
                    style={{ borderStyle: "none", background: "none" }}
                >
                    <img
                        src={require("../../../assets/img/logo/橫黑.png")}
                        alt="logo"
                        className="navlogo"
                    />
                </button>
                <ul className={isMobile ? "nav-links-mobile" : "nav-links"}>
                    <li>
                        <Link
                            className="btn-nav-link"
                            to="/SmileCare"
                            onClick={() => {
                                setIsMobile(false);
                                scrollToTop();
                            }}
                        >
                            Home
                        </Link>
                    </li>
                    <li>
                        <Linkto
                            className="btn-nav-link"
                            to="smile-care"
                            duration={450}
                            smooth={true}
                            onClick={() => setIsMobile(false)}
                        >
                            AI Assistant
                        </Linkto>
                    </li>
                    <li>
                        <Linkto
                            className="btn-nav-link"
                            to="smileCare-features"
                            duration={450}
                            smooth={true}
                            onClick={() => setIsMobile(false)}
                        >
                            Features
                        </Linkto>
                    </li>
                    <li>
                        <Linkto
                            className="btn-nav-link"
                            to="faq"
                            duration={450}
                            smooth={true}
                            onClick={() => setIsMobile(false)}
                        >
                            FAQ
                        </Linkto>
                    </li>
                    <li>
                        <Link
                            className="btn-dentist"
                            to="/"
                            onClick={() => {
                                setUserType("Dentist");
                                scrollToTop();
                            }}
                        >
                            <span>I'm a dentist</span>
                            <svg width="13px" height="10px" viewBox="0 0 13 10">
                                <path d="M1,5 L11,5"></path>
                                <polyline points="8 1 12 5 8 9"></polyline>
                            </svg>
                        </Link>
                    </li>
                </ul>

                <button
                    className="mobile-navbar-toggler"
                    onClick={() => setIsMobile(!isMobile)}
                >
                    {isMobile ? (
                        <FontAwesomeIcon
                            icon={faXmark}
                            style={{ color: "#333333" }}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faBars} />
                    )}
                </button>
            </nav>
        </header>
    );
}
