import { Col, Divider, Row } from "antd";
import { Link } from "react-router-dom";
import { Link as Linkto } from "react-scroll";
import "./Footer.scss";

export default function Footer({ colorScheme }) {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div
            className="footer"
            style={{
                backgroundColor: colorScheme === "light" ? "#fff" : "transparent",
            }}
        >
            <Row className="footer-content" justify="space-evenly">
                <Col xs={24} md={18} xl={18}>
                    <Row>
                        <Col xs={12} md={5} xl={5} className="footernav">
                            <h3 className={`footernav-title ${colorScheme === "light" && "footernav-title-light"}`}>
                                General
                            </h3>
                            <Divider
                                style={{
                                    borderColor: colorScheme === "light" ? "#333" : "#fff",
                                }}
                            />
                            <Linkto
                                to="home"
                                duration={1000}
                                className={`footernav-text ${colorScheme === "light" && "footernav-text-light"}`}
                            >
                                Home
                            </Linkto>
                            <Link
                                className={`footernav-text ${colorScheme === "light" && "footernav-text-light"}`}
                                to="/tryforfree"
                            >
                                Try for Free
                            </Link>
                            <Link
                                to="/contact"
                                className={`footernav-text ${colorScheme === "light" && "footernav-text-light"}`}
                                onClick={scrollToTop}
                            >
                                Contact Us
                            </Link>
                        </Col>

                        <Col xs={12} md={5} xl={5} className="footernav">
                            <h3 className={`footernav-title ${colorScheme === "light" && "footernav-title-light"}`}>
                                Product
                            </h3>
                            <Divider
                                style={{
                                    borderColor: colorScheme === "light" ? "#333" : "#fff",
                                }}
                            />
                            <Link
                                to="/"
                                className={`footernav-text ${colorScheme === "light" && "footernav-text-light"}`}
                                onClick={scrollToTop}
                            >
                                Dentscape AI
                            </Link>
                            <Link
                                to="/PreteethAIpro"
                                className={`footernav-text ${colorScheme === "light" && "footernav-text-light"}`}
                                onClick={scrollToTop}
                            >
                                Preteeth AI Pro
                            </Link>
                            <Link
                                to="/API"
                                className={`footernav-text ${colorScheme === "light" && "footernav-text-light"}`}
                                onClick={scrollToTop}
                            >
                                API
                            </Link>
                        </Col>

                        <Col xs={12} md={5} xl={5} className="footernav">
                            <h3 className={`footernav-title ${colorScheme === "light" && "footernav-title-light"}`}>
                                Learn
                            </h3>
                            <Divider
                                style={{
                                    borderColor: colorScheme === "light" ? "#333" : "#fff",
                                }}
                            />
                            <Link
                                to="/blog"
                                className={`footernav-text ${colorScheme === "light" && "footernav-text-light"}`}
                                onClick={scrollToTop}
                            >
                                Blog
                            </Link>
                            <Link
                                to="/tutorial"
                                className={`footernav-text ${colorScheme === "light" && "footernav-text-light"}`}
                                onClick={scrollToTop}
                            >
                                Tutorial
                            </Link>
                            <a
                                href="https://preteeth-ai.readme.io/reference/quick-start"
                                className={`footernav-text ${colorScheme === "light" && "footernav-text-light"}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Documentation
                            </a>
                        </Col>

                        <Col xs={12} md={5} xl={5} className="footernav">
                            <h3 className={`footernav-title ${colorScheme === "light" && "footernav-title-light"}`}>
                                Patient
                            </h3>
                            <Divider
                                style={{
                                    borderColor: colorScheme === "light" ? "#333" : "#fff",
                                }}
                            />

                            <Link
                                to="/SmileCare"
                                className={`footernav-text ${colorScheme === "light" && "footernav-text-light"}`}
                                onClick={scrollToTop}
                            >
                                SmileCare
                            </Link>
                        </Col>
                    </Row>
                </Col>
                <Col
                    xs={24}
                    md={6}
                    xl={6}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "start",
                    }}
                >
                    <div className="footernav footer-contact">
                        <h3 className={`footernav-title ${colorScheme === "light" && "footernav-title-light"}`}>
                            Contact Information
                        </h3>
                        <Divider
                            style={{
                                borderColor: colorScheme === "light" ? "#333" : "#fff",
                            }}
                        />
                        <a
                            href="mailto:hi@dentscape.ai"
                            className={`footernav-text ${colorScheme === "light" && "footernav-text-light"}`}
                        >
                            hi@dentscape.ai
                        </a>
                        <div className="contact-content">
                            <a href="mailto:hi@dentscape.ai" target="_blank">
                                <img
                                    src={
                                        colorScheme === "light"
                                            ? require("../../../assets/img/contact/btn_email.webp")
                                            : require("../../../assets/img/contact/btn_email(white).webp")
                                    }
                                    alt="Email"
                                    className="btn-socialMedia"
                                    loading="lazy"
                                />
                            </a>
                            <a href="https://www.facebook.com/DentscapeAI" target="_blank">
                                <img
                                    src={
                                        colorScheme === "light"
                                            ? require("../../../assets/img/contact/btn_fb.webp")
                                            : require("../../../assets/img/contact/btn_fb(white).webp")
                                    }
                                    alt="Facebook"
                                    className="btn-socialMedia"
                                    loading="lazy"
                                />
                            </a>
                            <a href="https://www.instagram.com/dentscape_ai" target="_blank">
                                <img
                                    src={
                                        colorScheme === "light"
                                            ? require("../../../assets/img/contact/btn_ig.webp")
                                            : require("../../../assets/img/contact/btn_ig(white).webp")
                                    }
                                    alt="Instagram"
                                    className="btn-socialMedia"
                                    loading="lazy"
                                />
                            </a>
                            <a href="https://www.linkedin.com/company/dentscape/posts/?feedView=all" target="_blank">
                                <img
                                    src={
                                        colorScheme === "light"
                                            ? require("../../../assets/img/contact/btn_linkedin.webp")
                                            : require("../../../assets/img/contact/btn_linkedin(white).webp")
                                    }
                                    alt="LinkedIn"
                                    className="btn-socialMedia"
                                    loading="lazy"
                                />
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className={`copyright ${colorScheme === "light" && "color-light"}`}>
                <p>© 2021 Preteeth AI Co., Ltd. All Rights Reserved.</p>
            </div>
        </div>
    );
}
