import React, { createContext, useState } from "react";

export const smileCareStore = createContext();

const initialMessages = [
  {
    role: "user",
    content: "",
  },
];

const SmileCareContext = ({ children }) => {
  const [userId, setUserId] = useState("");
  const [userIdTime, setUserIdTime] = useState("");
  const [allMessages, setAllMessages] = useState(initialMessages);
  const [openCamera, setOpenCamera] = useState(false);
  const [openUploadPhoto, setOpenUploadPhoto] = useState(false);
  const [date, setDate] = useState("");
  const [smilePic, setSmilePic] = useState(null);
  const [smilePicAsFile, setSmilePicAsFile] = useState(null);
  const [smilePicUrl, setSmilePicUrl] = useState(null);
  const [analyzing, setAnalyzing] = useState(false);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [simulationResult, setSimulationResult] = useState(null);

  return (
    <smileCareStore.Provider
      value={{
        userId,
        setUserId,
        userIdTime,
        setUserIdTime,
        allMessages,
        setAllMessages,
        openCamera,
        setOpenCamera,
        openUploadPhoto,
        setOpenUploadPhoto,
        date,
        setDate,
        smilePic,
        setSmilePic,
        smilePicAsFile,
        setSmilePicAsFile,
        smilePicUrl,
        setSmilePicUrl,
        analyzing,
        setAnalyzing,
        analysisResult,
        setAnalysisResult,
        simulationResult,
        setSimulationResult,
      }}
    >
      {children}
    </smileCareStore.Provider>
  );
};

export default SmileCareContext;
