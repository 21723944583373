import { useState, useEffect } from "react";

export default function Typewriter({ text, delay, onUpdate }) {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);

        onUpdate();
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text, onUpdate]);

  return <p>{currentText}</p>;
}
