import { Link, useNavigate } from "react-router-dom";
import "./PaymentPage.scss";
import SubscriptionPlans from "../../components/Account/Payment/SubscriptionPlans/SubscriptionPlans";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../api/firebase";
import { useQuery } from "../../actions";
import Navbar from "../../components/Dentist/Navbar/Navbar";

export default function PaymentPage() {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const q = useQuery();
    const uid = q.get("id");
    const token = q.get("token");

    const onClickGetStarted = () => {
        window.location.href = `/PreteethAIPro/Payment/Price${window.location.search}`;
    };

    let dataInit = false;
    useEffect(() => {
        if (!dataInit) {
            dataInit = true;

            if (!uid && !token) {
                if (!user) navigate(`/login?redirectTo=preteethAIPro/Payment${window.location.search}`);
                if (user && !user?.emailVerified) navigate("/emailVerification");
                if (error) navigate("/");
            }
        }
    }, []);

    return (
        <div className="payment-page">
            <Navbar />

            <h3 className="payment-title" data-aos="fade-in" data-aos-duration="700">
                Revolutionize Patient Engagement with Preteeth AI Pro
            </h3>

            <SubscriptionPlans onClickGetStarted={onClickGetStarted} />

            <p className="payment-footer">
                If you have any questions about the plan, please contact{" "}
                <a href="mailto:hi@dentscape.ai">hi@dentscape.ai</a>
            </p>
        </div>
    );
}
