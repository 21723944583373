import Login from "../../components/Account/Login";
import Register from "../../components/Account/Register";
import SendEmail from "../../components/Account/Password/SendEmail";
import Dashboard from "../../components/Account/Dashboard/Dashboard";
import EmailVerification from "../../components/Account/EmailVerification/EmailVerification";
import Delete from "../../components/Account/Delete/Delete";
import AccountDeleted from "../../components/Account/Delete/AccountDeleted";
import ChangePassword from "../../components/Account/Password/ChangePassword";

export default function AccountPage(props) {
    let account_state;
    if (props.state === "login") {
        account_state = <Login />;
    } else if (props.state === "register") {
        account_state = <Register />;
    } else if (props.state === "sendReset") {
        account_state = <SendEmail />;
    } else if (props.state === "dashboard") {
        account_state = <Dashboard />;
    } else if (props.state === "emailVerification") {
        account_state = <EmailVerification />;
    } else if (props.state === "delete") {
        account_state = <Delete />;
    } else if (props.state === "accountDeleted") {
        account_state = <AccountDeleted />;
    } else if (props.state === "changePassword") {
        account_state = <ChangePassword />;
    }

    return <div>{account_state}</div>;
}
